// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* styles.module.css */
.styles_video-player-overlay__TvPha {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Dark overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other content */
}

.styles_video-player__N9SOe {
  position: relative;

  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  /* padding: 20px; */
}
.styles_video-player__N9SOe video {
  max-height: 80vh;
}
.styles_close-button__gDIa9 {
  position: absolute;
  top: -5px;
  right: -5px;
  background: white;
  width: 30px;
  height: 30px;
  border: none;
  cursor: pointer !important;
  font-size: 20px;
  border-radius: 50%;
  z-index: 1200;
}
.styles_close-button__gDIa9 img {
  vertical-align: initial;
  z-index: 1300;
}
`, "",{"version":3,"sources":["webpack://./src/components/dashboard/video-player/styles.module.css"],"names":[],"mappings":"AAAA,sBAAsB;AACtB;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,oCAAoC,EAAE,iBAAiB;EACvD,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa,EAAE,oCAAoC;AACrD;;AAEA;EACE,kBAAkB;;EAElB,2CAA2C;EAC3C,mBAAmB;AACrB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,iBAAiB;EACjB,WAAW;EACX,YAAY;EACZ,YAAY;EACZ,0BAA0B;EAC1B,eAAe;EACf,kBAAkB;EAClB,aAAa;AACf;AACA;EACE,uBAAuB;EACvB,aAAa;AACf","sourcesContent":["/* styles.module.css */\n.video-player-overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(0, 0, 0, 0.7); /* Dark overlay */\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 1000; /* Ensure it's above other content */\n}\n\n.video-player {\n  position: relative;\n\n  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);\n  /* padding: 20px; */\n}\n.video-player video {\n  max-height: 80vh;\n}\n.close-button {\n  position: absolute;\n  top: -5px;\n  right: -5px;\n  background: white;\n  width: 30px;\n  height: 30px;\n  border: none;\n  cursor: pointer !important;\n  font-size: 20px;\n  border-radius: 50%;\n  z-index: 1200;\n}\n.close-button img {\n  vertical-align: initial;\n  z-index: 1300;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"video-player-overlay": `styles_video-player-overlay__TvPha`,
	"video-player": `styles_video-player__N9SOe`,
	"close-button": `styles_close-button__gDIa9`
};
export default ___CSS_LOADER_EXPORT___;
