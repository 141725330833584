import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SideBar } from "../../../../components/common/SideBar";
import { Header } from "../../../../components/common/Header";

import {
  BlockBlipModalIcon,
  BlockUserIcon,
  CloseModalIcon,
  IgnoreModalIcon,
  PlaceImg01,
  PreviousIcon,
  UnblockUserIcon,
  UserProfile,
} from "../../../../assets/image-path";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import Pagination from "../../../../components/dashboard/pagination/Pagination";
import {
  blockReportedBlip,
  getBlipReportsById,
} from "../../../../store/dashboard/DashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import { generateNewToken, setLoading } from "../../../../store/auth/AuthSlice";
import { formatDateTime } from "../../../../utils/formatDate";
import VideoPlayer from "../../../../components/dashboard/video-player/VideoPlayer";
import { useTranslation } from "react-i18next";
import addCloudFrontBaseUrl from "../../../../utils/addCloudFrontBaseUrl";

export const ContentBlipDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [showVideo, setShowVideo] = useState(false);
  const [reasonModal, setReasonModal] = useState(false);
  const [blockModal, setBlockModal] = useState(false);
  const [selectedData, setSelectedData] = useState();
  //Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit] = useState(+process.env.REACT_APP_PER_PAGE_LIMIT);
  const blipReportsList = useSelector(
    (state) => state?.dashboard?.blipReportsById?.data
  );
  const count = useSelector(
    (state) => state?.dashboard?.blipReportsById?.count
  );

  useEffect(() => {
    getBlipReportsByIdAPI();
    // eslint-disable-next-line
  }, [currentPage]);
  //  Get Bliper Reports By Id API
  const getBlipReportsByIdAPI = () => {
    dispatch(setLoading(true));
    dispatch(
      getBlipReportsById({
        pageLimit,
        currentPage,
        blipId: blipReportsList[0]?.blipId?._id,
      })
    ).then((response) => {
      dispatch(setLoading(false));
      if (response.payload === "token_expired") {
        dispatch(generateNewToken()).then((res) => {
          if (res.payload.success) {
            getBlipReportsByIdAPI();
          }
        });
      }
    });
  };

  //   Block Reported Blip
  const blockReportedBlipAPI = () => {
    const params = {
      blipId: blipReportsList[0]?.blipId?._id,
      status: blipReportsList[0]?.status === "blocked" ? "ignore" : "blocked",
    };
    dispatch(setLoading(true));
    dispatch(blockReportedBlip(params)).then((response) => {
      dispatch(setLoading(false));
      if (response.payload === "token_expired") {
        dispatch(generateNewToken()).then((res) => {
          if (res.payload.success) {
            blockReportedBlipAPI();
          }
        });
      } else if (response.payload.success) {
        getBlipReportsByIdAPI();
        setBlockModal(false);
      }
    });
  };

  return (
    <>
      <div id="body_wapper" className="mainBody">
        <div className="wapper">
          <SideBar />
          <div className="main-container">
            <Header headingName={""} />
            <div className="containerBody">
              <div className="mainSelectBox">
                <div className="headingPart">
                  <h2>
                    <ul>
                      <li>{t("BlipsModeration.pageTitle")}</li>
                      <li> {">"} </li>
                      <li>{t("BlipsModerationDetail.pageTitle")}</li>
                    </ul>
                  </h2>
                  <div className="rightMarginPT">
                    <button className="backbTun" onClick={(e) => navigate(-1)}>
                      <img src={PreviousIcon} alt="Back" />
                      {t("buttons.back")}
                    </button>
                  </div>
                </div>
                <div className="basicContainer">
                  <div className="col-xl-7 col-lg-11">
                    <div className="tableViewDetail">
                      <div className="placeviewbox">
                        <img
                          src={
                            blipReportsList[0]?.blipId?.thumbnailUrl
                              ? addCloudFrontBaseUrl(
                                  blipReportsList[0]?.blipId?.thumbnailUrl
                                )
                              : PlaceImg01
                          }
                          alt="Place"
                          className="img-fluid"
                        />
                        <h6>
                          <button
                            onClick={(e) => {
                              setShowVideo(true);
                            }}
                          >
                            {t("BlipsModerationDetail.viewBlip")}
                          </button>
                        </h6>
                      </div>
                      <div className="tableRound table-responsive">
                        <table className="table">
                          <tr>
                            <td>{t("BlipsModerationDetail.bliperName")}</td>
                            <td className="text-capitalize">
                              {!blipReportsList[0]?.spamUserId?.fullName ||
                              blipReportsList[0]?.spamUserId?.fullName ===
                                null ||
                              blipReportsList[0]?.spamUserId?.fullName === ""
                                ? "N/A"
                                : blipReportsList[0]?.spamUserId?.fullName}
                            </td>
                          </tr>
                          <tr>
                            <td>{t("BlipsModerationDetail.phone")}</td>
                            <td>
                              {!blipReportsList[0]?.spamUserId?.phoneNumber ||
                              blipReportsList[0]?.spamUserId?.phoneNumber ===
                                null ||
                              blipReportsList[0]?.spamUserId?.phoneNumber === ""
                                ? "N/A"
                                : blipReportsList[0]?.spamUserId?.countryCode +
                                  " " +
                                  blipReportsList[0]?.spamUserId?.phoneNumber}
                            </td>
                          </tr>
                          <tr>
                            <td>{t("BlipsModerationDetail.email")}</td>
                            <td>
                              {!blipReportsList[0]?.spamUserId?.email ||
                              blipReportsList[0]?.spamUserId?.email === null ||
                              blipReportsList[0]?.spamUserId?.email === ""
                                ? "N/A"
                                : blipReportsList[0]?.spamUserId?.email}
                            </td>
                          </tr>

                          <tr>
                            <td>
                              {t("BlipsModerationDetail.verificationStatus")}
                            </td>
                            <td>
                              <span
                                className={
                                  blipReportsList[0]?.spamUserId
                                    ?.verificationRequestStatus === "verified"
                                    ? "textGreen text-capitalize"
                                    : "textRed text-capitalize"
                                }
                              >
                                {
                                  blipReportsList[0]?.spamUserId
                                    ?.verificationRequestStatus
                                }
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {t("BlipsModerationDetail.currentBlockStatus")}
                            </td>
                            <td>
                              <span
                                className={
                                  blipReportsList[0]?.status === "blocked"
                                    ? "textRed"
                                    : "textGreen"
                                }
                              >
                                {blipReportsList[0]?.status === "blocked"
                                  ? t("buttons.blocked")
                                  : t("buttons.ignore")}
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="idProofBox">
                        <div className="footBtnPt d-flex">
                          <button
                            className="col-xl-4 col-lg-6 col-md-8"
                            onClick={() => setBlockModal(true)}
                          >
                            <img
                              src={
                                blipReportsList[0]?.status === "blocked"
                                  ? UnblockUserIcon
                                  : BlockUserIcon
                              }
                              alt="Icon"
                            />
                            {blipReportsList[0]?.status === "blocked"
                              ? t("buttons.ignore")
                              : t("buttons.block")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tableCusMain mt-5">
                    <div className="table-responsive" id="style-5">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>{t("BlipsModerationDetail.srNo")}</th>
                            <th>{t("BlipsModerationDetail.reportedBy")}</th>
                            <th>{t("BlipsModerationDetail.dateTime")} </th>
                            <th>
                              {t("BlipsModerationDetail.requestForBlock")}
                            </th>
                            <th>{t("BlipsModerationDetail.reportTitle")}</th>
                            <th>{t("BlipsModerationDetail.reason")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {blipReportsList?.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  #
                                  {parseInt(pageLimit) *
                                    parseInt(currentPage - 1) +
                                    parseInt(index + 1)}
                                </td>
                                <td className="text-capitalize proTableUser">
                                  <img
                                    src={
                                      item?.userId?.profileImage
                                        ? item?.userId?.profileImage
                                        : UserProfile
                                    }
                                    alt=""
                                    className="profimePicBox"
                                  />
                                  {item?.userId?.fullName
                                    ? item?.userId?.fullName
                                    : "N/A"}
                                </td>
                                <td>{formatDateTime(item.createdDate)}</td>
                                <td
                                  className={
                                    item?.spamUserId?.isBlocked
                                      ? "textRed"
                                      : "textGreen"
                                  }
                                >
                                  {item?.spamUserId?.isBlocked ? "Yes" : "No"}
                                </td>
                                <td className="text-capitalize">
                                  {item.title}
                                </td>
                                <td>
                                  <button
                                    className="viewBtton"
                                    onClick={() => {
                                      setSelectedData(item.discription);
                                      setReasonModal(true);
                                    }}
                                  >
                                    {t("buttons.view")}
                                  </button>
                                </td>
                              </tr>
                              // <tr>
                              //   <td>#1</td>
                              //   <td>Monique Quigley - Monique_Quigley256</td>
                              //   <td>Sat Jun 17 2023 05:30:46</td>
                              //   <td>
                              //     <button
                              //       className="viewBtton"
                              //       onClick={(e) => setReasonModal(true)}
                              //     >
                              //       View
                              //     </button>
                              //   </td>
                              // </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    <Pagination
                      currentPage={currentPage}
                      totalCount={count}
                      currentPageCount={blipReportsList?.length}
                      pageSize={pageLimit}
                      onPageChange={(page) => setCurrentPage(page)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {blockModal && (
        <Modal className="custumCssMCebter" show={blockModal}>
          <ModalHeader>
            <h6>&nbsp;</h6>
            <button
              type="button"
              className=""
              aria-label="Close"
              onClick={() => {
                setBlockModal(false);
              }}
            >
              <img src={CloseModalIcon} alt="Close Button" />
            </button>
          </ModalHeader>
          <ModalBody>
            <div className="centerModalContent">
              <div className="proConBox">
                <img
                  src={
                    blipReportsList[0]?.status === "blocked"
                      ? IgnoreModalIcon
                      : BlockBlipModalIcon
                  }
                  alt="Delete"
                  className="img-fluid"
                />
              </div>
              <p>
                {t("BlipsModerationDetail.blockConfirmationPre")}{" "}
                {blipReportsList[0]?.status === "blocked"
                  ? t("buttons.ignore")
                  : t("buttons.block")}{" "}
                {t("BlipsModerationDetail.blockConfirmationAfter")}
              </p>
              <div className="textConBoxBtn">
                <button
                  onClick={() => {
                    blockReportedBlipAPI();
                  }}
                >
                  <img
                    src={
                      blipReportsList[0]?.status === "blocked"
                        ? UnblockUserIcon
                        : BlockUserIcon
                    }
                    alt="Icon"
                  />
                  {blipReportsList[0]?.status === "blocked"
                    ? t("buttons.ignore")
                    : t("buttons.block")}
                </button>
                <button
                  className="cancelBtun"
                  onClick={() => setBlockModal(false)}
                >
                  {t("buttons.cancel")}
                </button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      )}

      {reasonModal && (
        <Modal className="custumCssMCebter" show={reasonModal}>
          <ModalHeader>
            <h6>Reason</h6>
            <button
              type="button"
              className=""
              aria-label="Close"
              onClick={() => {
                setReasonModal(false);
              }}
            >
              <img src={CloseModalIcon} alt="Close Button" />
            </button>
          </ModalHeader>
          <ModalBody>
            <div className="centerModalContent reasonmodalView" id="style-5">
              <p>{selectedData}</p>
            </div>
          </ModalBody>
        </Modal>
      )}
      {showVideo && (
        <VideoPlayer
          videoSrc={addCloudFrontBaseUrl(
            blipReportsList[0]?.blipId?.originalLink
          )}
          onClose={(e) => setShowVideo(false)}
        />
      )}
    </>
  );
};
