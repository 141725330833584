import axios from "axios";
import storage from "redux-persist/lib/storage";

// import storage from "redux-persist/lib/storage";
import {
  FORGOT_PASSWORD_API,
  GENERATE_NEW_TOKEN_API,
  LOGIN_API,
  RESET_PASSWORD_WITH_OTP_API,
  LOGOUT_TO_SERVER_API,
  CHANGE_PASSWORD_API,
} from "./../ApiUrls";


//  Login
const login = async (data) => {
  const response = await axios.post(LOGIN_API, data);

  if (response.data.success) {
    localStorage.setItem("accessToken", response.data.accessToken);
    localStorage.setItem("refreshToken", response.data.refreshToken);
  }
  return response.data;
};

//  Forgot Password
const forgotPassword = async (data) => {
  const response = await axios.post(FORGOT_PASSWORD_API, data);
  return response.data;
};

//  Reset Password With Otp
const resetPasswordWithOtp = async (data) => {
  const response = await axios.post(RESET_PASSWORD_WITH_OTP_API, data);
  return response.data;
};

// Change Password
const changePassword = async (data) => {
  const accessToken = localStorage.getItem("accessToken");
  const config = {
    headers: {
      Authorization: "Bearer " + accessToken,
    },
  };
  const response = await axios.post(CHANGE_PASSWORD_API, data, config);
  return response.data;
};

// Generate New Token
const generateNewToken = async () => {
  const accessToken = localStorage.getItem("accessToken");
  const refreshToken = localStorage.getItem("refreshToken");
  const data = {
    refreshToken: refreshToken,
  };
  const config = {
    headers: {
      Authorization: "Bearer " + accessToken,
      "Content-Type": "application/json",
    },
  };
  const response = await axios.post(GENERATE_NEW_TOKEN_API, data, config);
  if (response.data.success) {
    localStorage.setItem("accessToken", response.data.accessToken);
    localStorage.setItem("refreshToken", response.data.refreshToken);
  }

 
  if (response.data.message === "refresh_token_expired") {
    await storage.removeItem("persist:root");
    await localStorage.clear();
  }
  return response.data;
};

//  Logout To Server
const logoutToServer = async () => {
  const accessToken = localStorage.getItem("accessToken");
  const refreshToken = localStorage.getItem("refreshToken");
  const data = {
    accessToken: accessToken,
    refreshToken: refreshToken,
  };
  const config = {
    headers: {
      Authorization: "Bearer " + accessToken,
    },
  };
  const response = await axios.post(LOGOUT_TO_SERVER_API, data, config);
  return response.data;
};
const authService = {
  login,
  forgotPassword,
  resetPasswordWithOtp,
  changePassword,
  generateNewToken,
  logoutToServer,
};

export default authService;
