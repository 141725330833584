export { default as Logo } from "./images/logo.svg";
export { default as LoginAuth } from "./images/loginAuth.svg";
export { default as ForgotAuth } from "./images/forgotAuth.svg";
export { default as ResetAuth } from "./images/resetAuth.svg";
export { default as KeyIcon } from "./images/key_icon.svg";
export { default as EmailIcon } from "./images/email_icon.svg";
export { default as EyelIcon } from "./images/eye.svg";
export { default as OffEyelIcon } from "./images/off-eye.svg";
export { default as EditBackIcon } from "./images/editBack.svg";

export { default as AdminLogo } from "./images/admin_Logo.svg";
export { default as AdminLogoIcon } from "./images/admin_LogoIcon.svg";
export { default as DashboardIcon } from "./images/dashboard_icon.svg";
export { default as SubAdminManagementIcon } from "./images/sub-admin-management_icon.svg";
export { default as BlipersManagementIcon } from "./images/blipers-management_icon.svg";
export { default as VerificationRequestIcon } from "./images/verification-request_icon.svg";
export { default as ContentModerationIcon } from "./images/content-moderation_icon.svg";
export { default as BlipCoinsIcon } from "./images/blip-coins_icon.svg";
export { default as BlipIcon } from "./images/blips_icon.svg";
export { default as ContentManagementIcon } from "./images/content-management_icon.svg";
export { default as DropLeftIcon } from "./images/drop-left_icon.svg";

export { default as ToggleMenuIcon } from "./images/toggleMenu_icon.svg";
export { default as ProPicture } from "./images/pro_picture.png";
export { default as DropDownicon } from "./images/dropDown_icon.svg";

export { default as ViewIcon } from "./images/view_icon.svg";
export { default as EditIcon } from "./images/edit_icon.svg";
export { default as DeleteIcon } from "./images/dalete_icon.svg";
export { default as BlipsIconTb } from "./images/blips_iconTb.svg";
export { default as SearchIcon } from "./images/search_icon.svg";
export { default as ReloadIcon } from "./images/reload_icon.svg";
export { default as AddPlusIcon } from "./images/add_plus_icon.svg";
export { default as PreviousIcon } from "./images/previous_icon.svg";
export { default as NextIcon } from "./images/next_icon.svg";
export { default as UserProfile } from "./images/user_profile.png";
export { default as CloseModalIcon } from "./images/closeModal_icon.svg";
export { default as DeleteModalImg } from "./images/deleteModalimg.svg";
export { default as ActiveModalImg } from "./images/active_modalimg.svg";
export { default as InactiveModalImg } from "./images/inactive_modalimg.svg";
export { default as VerifiedIcon } from "./images/verified.svg";

export { default as DropDownTBIcon } from "./images/dropDownTB_icon.svg";
export { default as IdProof } from "./images/id-proof.png";
export { default as UnVerify } from "./images/un-verify.svg";
export { default as AcceptIcon } from "./images/accept_icon.svg";
export { default as DeclineIcon } from "./images/decline_icon.svg";
export { default as CalendarIcon } from "./images/calendar_icon.svg";
export { default as PlaceImg01 } from "./images/placeImg01.png";

export { default as LikeIcon } from "./images/like_icon.svg";
export { default as UnlikeIcon } from "./images/unlike_icon.svg";
export { default as CommentIcon } from "./images/comment_icon.svg";
export { default as ReportIcon } from "./images/report_icon.svg";
export { default as verifiedActionModalIcon } from "./images/verifiedActionModal.svg";
export { default as MapImg } from "./images/mapImg.png";
export { default as FileUpload } from "./images/file-upload.svg";
export { default as PDFIcon } from "./images/pdf_icon.svg";
export { default as LogoutIcon } from "./images/logout.svg";
export { default as PlayButton } from "./images/play-button.png";

export { default as PlaceImgLarge01 } from "./images/placeImg-large01.png";

export { default as PlayIcon } from "./images/playIcon.svg";

export { default as PageNotFoundImg } from "./images/pageNotFound.svg";
export { default as BlockUserIcon } from "./images/blockUserIcon.svg";
export { default as UnblockUserIcon } from "./images/unblockUserIcon.svg";
export { default as DashActiveblips } from "./images/activeUser.svg";
export { default as DashInactiveblips } from "./images/inactiveUser.svg";
export { default as TotalActive } from "./images/totalActive.svg";
export { default as HistoryIcon } from "./images/historyIcon.svg";

export { default as VerificationReqIcon } from "./images/verificationrequests.svg";
export { default as VerifiedBlipersIcon } from "./images/verifiedBlipers.svg";
export { default as UnverifiedBlipersIcon } from "./images/unverifiedBlipers.svg";
export { default as DeclineBlipersIcon } from "./images/declineBlipers.svg";

export { default as AllBlipsIcon } from "./images/allblip.svg";
export { default as ActivceBlipIcon } from "./images/activceblip.svg";
export { default as InactiveBlipIcon } from "./images/inactiveblip.svg";

export { default as AllSystemBlipIcon } from "./images/allSystemBlip.svg";
export { default as ActivceSystemBlipIcon } from "./images/activeSystemBlip.svg";
export { default as inactiveSystemBlipIcon } from "./images/inactiveSystemBlip.svg";

export { default as SettingIcon } from "./images/setting.svg";

export { default as AppMail } from "./images/app_mail.svg";
export { default as AppWebsite } from "./images/app_website.svg";
export { default as AccordionDownIcon } from "./images/accordion-DownIcon.svg";
export { default as AccordionUpIcon } from "./images/accordion-UpIcon.svg";
export { default as GooglePlayIcon } from "./images/googlePlayIcon.png";
export { default as AppStoreIcon } from "./images/appStoreIcon.png";
export { default as GoldCoin } from "./images/GoldCoin.svg";
export { default as PlatinumCoin } from "./images/PlatinumCoin.svg";
export { default as SilverCoin } from "./images/SilverCoin.svg";
export { default as AnchorCoin } from "./images/AnchorCoin.svg";

// new Modal conformation Icons
export { default as ActiveBlipModalIcon } from "./images/active_blip.svg";
export { default as InactiveBlipModalIcon } from "./images/inactive_blip.svg";

export { default as BlockBlipModalIcon } from "./images/block_blip.svg";
export { default as BlockBliperModalIcon } from "./images/block_bliper.svg";
export { default as IgnoreModalIcon } from "./images/ignore_bliper.svg";

export { default as ActiveCoinModalIcon } from "./images/active_coin.svg";
export { default as InactiveCoinModalIcon } from "./images/inactive_coin.svg";

export { default as AnchorBlipsSidebarIcon } from "./images/anchorBlipsSidebar.svg";
export { default as RemoveFromAnchorIcon } from "./images/removeFronAnchor.svg";
export { default as MarkAsAnchorIcon } from "./images/markAsAnchor.svg";

export { default as LocationSVGIcon } from "./images/locationSVG_Icon.svg";