import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Header } from "../../../components/common/Header";
import { SideBar } from "../../../components/common/SideBar";
import { Link, useNavigate } from "react-router-dom";
import {
  DeleteIcon,
  PreviousIcon,
  VerifiedIcon,
  UserProfile,
  CloseModalIcon,
  DeleteModalImg,
  PDFIcon,
  FileUpload,
  BlipsIconTb,
} from "../../../assets/image-path";
import { useDispatch, useSelector } from "react-redux";
import { formatDate } from "../../../utils/formatDate";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import { generateNewToken, setLoading } from "../../../store/auth/AuthSlice";
import {
  deleteBliperById,
  getBliperById,
  updateBliperProfileImageById,
} from "../../../store/dashboard/DashboardSlice";
import addCloudFrontBaseUrl from "../../../utils/addCloudFrontBaseUrl";

export const BlipersDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteProfileModal, setDeleteProfileModal] = useState(false);
  const bliperDetails = useSelector(
    (state) => state?.dashboard?.bliperDetailById
  );

  //   Delete Bliper by id
  const deleteBliperByIdAPI = (id) => {
    dispatch(setLoading(true));
    dispatch(deleteBliperById(id)).then((response) => {
      dispatch(setLoading(false));
      if (response.payload === "token_expired") {
        dispatch(generateNewToken()).then((res) => {
          if (res.payload.success) {
            deleteBliperByIdAPI(id);
          }
        });
      } else if (response.payload.success) {
        setDeleteModal(false);
        navigate(-1);
      }
    });
  };

  //   Update Bliper Profile Image By Id
  const updateBliperProfileImageAPI = (id) => {
    const params = {
      id: id,
      avatar: null,
    };
    dispatch(setLoading(true));
    dispatch(updateBliperProfileImageById(params)).then((response) => {
      dispatch(setLoading(false));
      if (response.payload === "token_expired") {
        dispatch(generateNewToken()).then((res) => {
          if (res.payload.success) {
            updateBliperProfileImageAPI(id);
          }
        });
      } else if (response.payload.success) {
        setDeleteProfileModal(false);
        getBliperByIdAPI();
      }
    });
  };

  //   Get Bliper By Id API
  const getBliperByIdAPI = () => {
    dispatch(setLoading(true));
    dispatch(getBliperById(bliperDetails?._id)).then((response) => {
      dispatch(setLoading(false));
      if (response.payload === "token_expired") {
        dispatch(generateNewToken()).then((res) => {
          if (res.payload.success) {
            getBliperByIdAPI();
          }
        });
      }
    });
  };
  // Function to extract file extension from URL
  const get_url_extension = (url) => {
    return url?.split(/[#?]/)[0]?.split(".")?.pop()?.trim();
  };

  function flowersCount(count) {
    if (count < 1000) {
      return count?.toString();
    } else if (count < 1000000) {
      return (count / 1000).toFixed(2) + "K";
    } else {
      return (count / 1000000).toFixed(2) + "M";
    }
  }

  return (
    <>
      <div id="body_wapper" className="mainBody">
        <div className="wapper">
          <SideBar />
          <div className="main-container">
            <Header headingName={""} />
            <div className="containerBody">
              <div className="mainSelectBox">
                <div className="headingPart">
                  <h2>
                    <ul>
                      <li>{t("blipersManagement.title")}</li>
                      <li> {">"}</li>
                      <li>{t("bliperDetail.title")}</li>
                    </ul>
                  </h2>
                  <div className="rightMarginPT">
                    <button className="backbTun" onClick={() => navigate(-1)}>
                      <img src={PreviousIcon} alt="" /> Back
                    </button>
                  </div>
                </div>
                <div className="basicContainer">
                  <div className="col-xl-7 col-lg-11">
                    <div className="tableViewDetail">
                      <img
                        src={
                          bliperDetails?.profileImage
                            ? addCloudFrontBaseUrl(bliperDetails?.profileImage)
                            : UserProfile
                        }
                        alt=""
                        className="userProImg"
                      />
                      <h1 className="text-capitalize">
                        {bliperDetails.fullName
                          ? bliperDetails.fullName
                          : "N/A"}
                        {bliperDetails?.profileImage && (
                          <button
                            className="profileCudDeleteBtn"
                            onClick={(e) => setDeleteProfileModal(true)}
                          >
                            {t("bliperDetail.deleteProfileBtn")}
                          </button>
                        )}
                      </h1>
                      <h2>{t("bliperDetail.detailsHeading")}</h2>
                      <div className="tableRound table-responsive">
                        <table className="table">
                          <tr>
                            <td>{t("bliperDetail.fullName")}</td>
                            <td className="text-capitalize">
                              {bliperDetails?.fullName}{" "}
                              <img
                                src={
                                  bliperDetails?.verificationRequestStatus ===
                                  "verified"
                                    ? VerifiedIcon
                                    : ""
                                }
                                alt=""
                              />
                            </td>
                          </tr>
                          <tr>
                            <td> {t("bliperDetail.email")}</td>
                            <td>
                              {!bliperDetails?.email ||
                              bliperDetails.email === null ||
                              bliperDetails.email === ""
                                ? "N/A"
                                : bliperDetails.email}
                            </td>
                          </tr>
                          <tr>
                            <td> {t("bliperDetail.phone")}</td>
                            <td>
                              {!bliperDetails?.phoneNumber ||
                              bliperDetails.phoneNumber === null ||
                              bliperDetails.phoneNumber === ""
                                ? "N/A"
                                : bliperDetails.countryCode +
                                  " " +
                                  bliperDetails.phoneNumber}
                            </td>
                          </tr>
                          <tr>
                            <td> {t("bliperDetail.userName")}</td>
                            <td>{bliperDetails?.userName}</td>
                          </tr>
                          <tr>
                            <td>{t("bliperDetail.emailVerification")}</td>
                            <td className="textGreen">
                              <span
                                className={
                                  bliperDetails?.isVerifyEmailOtp
                                    ? "textGreen"
                                    : "textRed"
                                }
                              >
                                {bliperDetails?.isVerifyEmailOtp
                                  ? "Verified"
                                  : "Unverified"}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>{t("bliperDetail.phoneVerification")}</td>
                            <td className="textGreen">
                              <span
                                className={
                                  bliperDetails?.isVerifyPhoneOtp
                                    ? "textGreen"
                                    : "textRed"
                                }
                              >
                                {bliperDetails?.isVerifyPhoneOtp
                                  ? "Verified"
                                  : "Unverified"}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>{t("bliperDetail.blips")}</td>
                            <td>
                              {bliperDetails?.totalBlips ? (
                                <>
                                  {flowersCount(bliperDetails?.totalBlips)}
                                  <img src={BlipsIconTb} alt="" title="Blips" />
                                </>
                              ) : (
                                "N/A"
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>{t("bliperDetail.friends")}</td>
                            <td>
                              {bliperDetails?.totalFriends
                                ? flowersCount(bliperDetails?.totalFriends)
                                : "N/A"}
                            </td>
                          </tr>
                          <tr>
                            <td>{t("bliperDetail.Followers")}</td>
                            <td>
                              {bliperDetails?.followers?.length
                                ? flowersCount(bliperDetails?.followers?.length)
                                : "N/A"}
                            </td>
                          </tr>
                          <tr>
                            <td>{t("bliperDetail.joinedAt")}</td>
                            <td>{formatDate(bliperDetails?.createdDate)}</td>
                          </tr>
                          <tr>
                            <td>{t("bliperDetail.status")}</td>
                            <td className="textGreen">
                              <span
                                className={
                                  bliperDetails?.status === "active"
                                    ? "textGreen"
                                    : "textRed"
                                }
                              >
                                {bliperDetails?.status === "active"
                                  ? "Active"
                                  : "Inactive"}
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <h2>{t("bliperDetail.socialLinks")}</h2>
                      <div className="tableRound table-responsive">
                        <table className="table">
                          <tr>
                            <td>{t("bliperDetail.facebookLink")}</td>
                            <td className="tdLargeContent">
                              {bliperDetails?.facebookUrl ? (
                                <a
                                  href={bliperDetails?.facebookUrl}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {bliperDetails?.facebookUrl}
                                </a>
                              ) : (
                                "N/A"
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>{t("bliperDetail.instagramLink")}</td>
                            <td className="tdLargeContent">
                              {bliperDetails?.instagramUrl ? (
                                <a
                                  href={bliperDetails?.instagramUrl}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {bliperDetails?.instagramUrl}
                                </a>
                              ) : (
                                "N/A"
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>{t("bliperDetail.linkdenLink")}</td>
                            <td className="tdLargeContent">
                              {bliperDetails?.linkedInUrl ? (
                                <a
                                  href={bliperDetails?.linkedInUrl}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {bliperDetails?.linkedInUrl}
                                </a>
                              ) : (
                                "N/A"
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>{t("bliperDetail.twitterLink")}</td>
                            <td className="tdLargeContent">
                              {bliperDetails?.twitterUrl ? (
                                <a
                                  href={bliperDetails?.twitterUrl}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {bliperDetails?.twitterUrl}
                                </a>
                              ) : (
                                "N/A"
                              )}
                            </td>
                          </tr>
                        </table>
                      </div>
                      <h2>{t("bliperDetail.articles")}</h2>
                      <div className="tableRound table-responsive">
                        <table className="table">
                          <tr>
                            <td>{t("bliperDetail.article1")}</td>
                            <td className="tdLargeContent">
                              {bliperDetails?.articleFirstUrl ? (
                                <a
                                  href={bliperDetails?.articleFirstUrl}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {bliperDetails?.articleFirstUrl}
                                </a>
                              ) : (
                                "N/A"
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>{t("bliperDetail.article2")}</td>
                            <td className="tdLargeContent">
                              {bliperDetails?.articleSecondUrl ? (
                                <a
                                  href={bliperDetails?.articleSecondUrl}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {bliperDetails?.articleSecondUrl}
                                </a>
                              ) : (
                                "N/A"
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>{t("bliperDetail.article3")}</td>
                            <td className="tdLargeContent">
                              {bliperDetails?.articleThirdUrl ? (
                                <a
                                  href={bliperDetails?.articleThirdUrl}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {bliperDetails?.articleThirdUrl}
                                </a>
                              ) : (
                                "N/A"
                              )}
                            </td>
                          </tr>
                        </table>
                      </div>

                      {/* <h2>Verification</h2>
                      <div className="tableRound table-responsive">
                        <table className="table">
                          <tr>
                            <td>Message</td>
                            <td>
                              {bliperDetails?.verificationRequestMessage
                                ? bliperDetails?.verificationRequestMessage
                                : "N/A"}
                            </td>
                          </tr>
                        </table>
                      </div> */}
                      <h2>{t("bliperDetail.idProof")}</h2>
                      <div className="idProofBox">
                        {bliperDetails?.idProof ? (
                          get_url_extension(bliperDetails?.idProof) ===
                          "pdf" ? (
                            <div className="pdfmainBox">
                              <Link
                                to={addCloudFrontBaseUrl(
                                  bliperDetails?.idProof
                                )}
                                target="_black"
                              >
                                <img src={PDFIcon} alt="IdProof" />
                              </Link>
                            </div>
                          ) : (
                            <Link
                              to={addCloudFrontBaseUrl(bliperDetails?.idProof)}
                              target="_black"
                            >
                              <img
                                src={addCloudFrontBaseUrl(
                                  bliperDetails?.idProof
                                )}
                                width={250}
                                alt="Id Proof"
                                title="View"
                              />
                            </Link>
                          )
                        ) : (
                          "N/A"
                        )}
                        <div className="footBtnPt">
                          <button
                            onClick={(e) => setDeleteModal(true)}
                            className="col-xl-4 col-lg-6 col-md-8"
                          >
                            <img src={DeleteIcon} alt="Delete" />
                            {t("buttons.delete")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {deleteModal && (
        <Modal className="custumCssMCebter" show={deleteModal}>
          <ModalHeader>
            <h6>&nbsp;</h6>
            <button
              type="button"
              className=""
              aria-label="Close"
              onClick={() => {
                setDeleteModal(false);
              }}
            >
              <img src={CloseModalIcon} alt="Close Button" />
            </button>
          </ModalHeader>
          <ModalBody>
            <div className="centerModalContent">
              <div className="proConBox">
                <img src={DeleteModalImg} alt="Delete" className="img-fluid" />
              </div>
              <p>Are you sure you want to delete this bliper?</p>
              <div className="textConBoxBtn">
                <button
                  onClick={(e) => deleteBliperByIdAPI(bliperDetails?._id)}
                >
                  <img src={DeleteIcon} alt="Delete" />
                  Delete{" "}
                </button>
                <button
                  className="cancelBtun"
                  onClick={() => setDeleteModal(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      )}
      {deleteProfileModal && (
        <Modal className="custumCssMCebter" show={deleteProfileModal}>
          <ModalHeader>
            <h6>&nbsp;</h6>
            <button
              type="button"
              className=""
              aria-label="Close"
              onClick={() => {
                setDeleteProfileModal(false);
              }}
            >
              <img src={CloseModalIcon} alt="Close Button" />
            </button>
          </ModalHeader>
          <ModalBody>
            <div className="centerModalContent">
              <div className="proConBox">
                <img src={DeleteModalImg} alt="Delete" className="img-fluid" />
              </div>
              <p>
                Are you sure you want to delete profile image of this bliper?
              </p>
              <div className="textConBoxBtn">
                <button
                  onClick={(e) =>
                    updateBliperProfileImageAPI(bliperDetails?._id)
                  }
                >
                  <img src={DeleteIcon} alt="Delete" />
                  Delete{" "}
                </button>
                <button
                  className="cancelBtun"
                  onClick={() => setDeleteProfileModal(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};
