import React, { useState } from "react";
import { AccordionDownIcon, AccordionUpIcon } from "../../assets/image-path";

export const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <>
      <div className="appMobile">
        <div className="faq-accordion">
          <div className="container-fluid">
            <div className="accordioan-container">
              <AccordionContentView
                title={"How to subscribe?"}
                index={0}
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </AccordionContentView>

              <AccordionContentView
                title={"How do I edit my profile?"}
                index={1}
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </AccordionContentView>

              <AccordionContentView
                title={"How to get more likes?"}
                index={2}
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
              >
                CLorem ipsum dolor sit amet.
              </AccordionContentView>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const AccordionContentView = ({
  title,
  children,
  index,
  activeIndex,
  setActiveIndex,
}) => {
  const handleSetIndex = (index) =>
    activeIndex !== index ? setActiveIndex(index) : setActiveIndex(0);
  return (
    <>
      <div className="accordion_bx" onClick={() => handleSetIndex(index)}>
        {activeIndex === index ? (
          <>
            <div className="accordion-heading active cursor-pointer">
              {title}
              <span>
                <img
                  src={AccordionDownIcon}
                  alt="Plus"
                  className="PlusRemove"
                />
                <img src={AccordionUpIcon} alt="Minus" />
              </span>
            </div>
            <div className="accordion-content" style={{ display: "block" }}>
              {children}
            </div>
          </>
        ) : (
          <div className="accordion-heading cursor-pointer">
            {title}
            <span>
              <img src={AccordionDownIcon} alt="Plus" />
            </span>
          </div>
        )}
      </div>
    </>
  );
};
