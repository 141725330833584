import React from "react";
import Cookies from "js-cookie";
import { Formik } from "formik";
import * as Yup from "yup";

import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
//  images
import { EmailIcon } from "../../assets/image-path";
import { forgotPassword, setLoading } from "../../store/auth/AuthSlice";
import { useNavigate } from "react-router-dom";

export const ForgotPasswordForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(); // Use the translation hook

  const forgotPasswordAPI = (values) => {
    const parms = {
      email: values.email,
    };
    dispatch(setLoading(true));
    dispatch(forgotPassword(parms)).then((response) => {
      dispatch(setLoading(false));
      if (response.payload.success) {
        Cookies.set("forgotPassEmail", values.email);
        navigate("/reset-password");
      }
    });
  };
  return (
    <>
      <div className="AuthHeaDM">
        <h2>{t("forgotPage.forgotHeader")}</h2>
      </div>
      <Formik
        initialValues={{
          email: Cookies.get("forgotPassEmail")
            ? Cookies.get("forgotPassEmail")
            : "",
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email(t("formErrorMsg.emailInvalid"))
            .required(t("formErrorMsg.emailRequired")),
        })}
        onSubmit={(values) => {
          // same shape as initial values
          forgotPasswordAPI(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>{t("formLabel.emailLabel")}</label>
              <div className="inputPossion">
                <input
                  type="text"
                  placeholder={t("formPlaceholder.emailPlaceholder")}
                  className="form-control"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />
                <img src={EmailIcon} alt="Email" />
              </div>
              {errors.email && touched.email && (
                <div className="error-msg">{errors.email}</div>
              )}
            </div>

            <div className="formButtonBx">
              <button className="gradientBG submitBtN" type="submit">
                {t("forgotPage.sendOTPButton")}
              </button>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};
