import React, { useEffect, useRef, useState } from "react";
import { SideBar } from "../../../components/common/SideBar";
import { Header } from "../../../components/common/Header";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Pagination from "../../../components/dashboard/pagination/Pagination";

import {
  SearchIcon,
  ReloadIcon,
  PreviousIcon,
  CalendarIcon,
  DropDownTBIcon,
} from "../../../assets/image-path";
import BlipCard from "./BlipCard";
import { formatDate } from "../../../utils/formatDate";
import { generateNewToken, setLoading } from "../../../store/auth/AuthSlice";
import { getBlipsByBliperId } from "../../../store/dashboard/DashboardSlice";
import Calendar from "../../../components/dashboard/calender/Calender";
import { Dropdown } from "react-bootstrap";
const BlipsByBliperId = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(); // Use the translation hook
  const isInitialRender = useRef(true);
  const [dateRange, setDateRange] = useState({ startDate: "", endDate: "" });
  const [anchorFilter, setAnchorFilter] = useState("");
  const [showCalendar, setShowCalendar] = useState(false);
  //Pagination
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit] = useState(+process.env.REACT_APP_PER_PAGE_LIMIT);

  const count = useSelector(
    (state) => state?.dashboard?.blipsByBliperId?.count
  );
  const blipsArray = useSelector(
    (state) => state?.dashboard?.blipsByBliperId?.data
  );

  const bliperIdForViewBlip = useSelector(
    (state) => state?.dashboard?.bliperIdForViewBlip
  );

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    if (search === "" || search?.length > 2) {
      getBlipsByBliperIdAPI();
    }
    // eslint-disable-next-line
  }, [currentPage, search, dateRange, anchorFilter]);

  // get Blips By Bliper Id
  const getBlipsByBliperIdAPI = () => {
    dispatch(setLoading(true));
    dispatch(
      getBlipsByBliperId({
        id: bliperIdForViewBlip,
        pageLimit,
        currentPage,
        search,
        dateRange,
        filter_by_anchor:
          anchorFilter === "Non-Anchor"
            ? "nonanchor"
            : anchorFilter === "Anchor"
            ? "anchor"
            : "",
      })
    ).then((response) => {
      dispatch(setLoading(false));
      if (response.payload === "token_expired") {
        dispatch(generateNewToken()).then((res) => {
          if (res.payload.success) {
            getBlipsByBliperIdAPI();
          }
        });
      }
    });
  };
  return (
    <>
      <div id="body_wapper" className="mainBody">
        <div className="wapper">
          <SideBar />
          <div className="main-container">
            <Header headingName={""} />
            <div className="containerBody">
              <div className="mainSelectBox">
                <div className="headingPart">
                  <h2>
                    <ul>
                      <li>{t("blipersManagement.title")}</li>
                      <li> {">"}</li>
                      <li>{t("blipsByBliperIdPage.pageTitle")}</li>
                    </ul>
                  </h2>
                  <div className="rightMarginPT">
                    <button className="backbTun" onClick={() => navigate(-1)}>
                      <img src={PreviousIcon} alt="Back" />
                      {t("buttons.back")}
                    </button>
                  </div>
                </div>
                <div className="headingPart cusdatepic">
                  <h2>&nbsp;</h2>

                  <div className="rightMarginPT">
                    <span className="cusdatepicResponsive">
                      <div className="reloadBTun">
                        <button
                          onClick={(e) => {
                            setSearch("");
                            setDateRange({ startDate: "", endDate: "" });
                            setAnchorFilter("");
                            setCurrentPage(1);
                          }}
                        >
                          <img src={ReloadIcon} alt="reload" />
                        </button>
                      </div>
                      <Dropdown
                        drop
                        className="dropdown-filter dropdown"
                        onSelect={(e) => {
                          setAnchorFilter(e);
                          setCurrentPage(1);
                        }}
                      >
                        <Dropdown.Toggle>
                          <span>
                            {anchorFilter
                              ? anchorFilter + " Blips"
                              : "Select Filter"}
                          </span>
                          <img src={DropDownTBIcon} alt="icon" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          variant=""
                          id="style-5"
                          className="cusDrupdown"
                        >
                          <Dropdown.Item eventKey="" key={"clear filter"}>
                            Clear Filter
                          </Dropdown.Item>
                          <Dropdown.Item eventKey="Anchor" key={"Anchor"}>
                            Anchor Blips
                          </Dropdown.Item>
                          <Dropdown.Item
                            eventKey="Non-Anchor"
                            key={"Non-Anchor"}
                          >
                            Non-Anchor Blips
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      <span className="searchBgMain">
                        <input
                          type="text"
                          className=""
                          placeholder={t(
                            "blipsByBliperIdPage.searchPlaceholder"
                          )}
                          value={search}
                          onChange={(e) => {
                            setCurrentPage(1);
                            const s = e.currentTarget?.value
                              ?.replace(/[^\w\s]|_/g, "")
                              .replace(/\s{2,}/g, " ");
                            setSearch(s);
                          }}
                        />
                        <img src={SearchIcon} alt="" />
                      </span>
                    </span>
                    <span className="searchBgMain mx-0">
                      <img src={CalendarIcon} alt="Calendar" />

                      <input
                        type="text"
                        className="form-control"
                        onClick={(e) => setShowCalendar(true)}
                        value={
                          dateRange.startDate
                            ? dateRange.startDate + " To " + dateRange.endDate
                            : "Start Date - End Date"
                        }
                        readOnly
                      />
                    </span>
                  </div>
                </div>
                <div className="blipsboxContainer">
                  {blipsArray && (
                    <BlipCard
                      blips={blipsArray}
                      reCallGetBlipsAPI={getBlipsByBliperIdAPI}
                    />
                  )}
                </div>

                <div className="mt-4">
                  <Pagination
                    currentPage={currentPage}
                    totalCount={count}
                    currentPageCount={blipsArray?.length}
                    pageSize={pageLimit}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showCalendar && (
        <>
          <Calendar
            onChange={setDateRange}
            onClose={(e) => setShowCalendar(false)}
          />
        </>
      )}
    </>
  );
};

export default BlipsByBliperId;
