const API_URL = process.env.REACT_APP_BACKEND_URL + "/";

export const LOGIN_API = API_URL + "admin/login";
export const FORGOT_PASSWORD_API = API_URL + "admin/forgetPassword";
export const RESET_PASSWORD_WITH_OTP_API =
  API_URL + "admin/resetForgetPasswordByOtp";
export const CHANGE_PASSWORD_API = API_URL + "admin/changePassword";

export const GENERATE_NEW_TOKEN_API =
  API_URL + "admin/generateTokenByRefreshToken";
export const LOGOUT_TO_SERVER_API = API_URL + "admin/logout";

// Dashboard API's
export const GET_DASHBOARD_DATA_API = API_URL + "admin/adminDashboard";
export const ADD_SUB_ADMIN_API = API_URL + "admin/createSubAdmin";
export const GET_SUB_ADMINS_API = API_URL + "admin/getSubAdminUsers";
export const GET_SUB_ADMIN_BY_ID_API = API_URL + "admin/getSubAdminDetails/";
export const UPDATE_SUB_ADMIN_BY_ID_API = API_URL + "admin/updateSubAdmin/";
export const UPDATE_SUB_ADMIN_STATUS_BY_ID_API =
  API_URL + "admin/updateSubAdminStatus/";

export const DELETE_SUB_ADMIN_BY_ID_API = API_URL + "admin/deleteSubAdmin/";

// content management api
export const CREATE_CONTENT_MANAGEMENT_API =
  API_URL + "admin/createContentManagement";

export const UPDATE_CONTENT_MANAGEMENT_API =
  API_URL + "admin/updateContentManagement/";

export const GET_CONTENT_MANAGEMENT_API =
  API_URL + "admin/getContentManagement";

// Blipers Management api's
export const GET_BLIPERS_API = API_URL + "admin/getBlipsUsers";
export const GET_BLIPER_BY_ID_API = API_URL + "admin/getBlipsUserDetails/";
export const UPDATE_BLIPER_API = API_URL + "admin/updateBlippersProfile/";
export const UPDATE_BLIPER_STATUS_BY_ID_API =
  API_URL + "admin/updateBlipsUserStatus/";

export const DELETE_BLIPER_BY_ID_API = API_URL + "admin/deleteBlipsUser/";
export const UPDATE_BLIPER_PROFILE_IMAGE_BY_ID_API =
  API_URL + "admin/updateProfileImage/";

export const GET_BLIPS_BY_BLIPER_ID_API = API_URL + "admin/blipsUserId/";
export const UPDATE_BLIP_STATUS_BY_ID_API = API_URL + "admin/updateBlipStatus/";

// Blipers Verification Request api's
export const GET_BLIPERS_VERIFICATION_REQUEST_API =
  API_URL + "admin/getVerificationRequestUsers";
export const UPDATE_BLIPER_VERIFICATION_STATUS_BY_ID_API =
  API_URL + "admin/updateBlipsUserVerificationStatus/";

export const GET_BLIPERS_VERIFICATION_HISTORY_API =
  API_URL + "admin/getUserVerificationHistory/";

// System Blip Coin API's
export const ADD_SYSTEM_BLIP_COIN_API = API_URL + "admin/createMonetisation";
export const GET_SYSTEM_BLIP_COIN_API = API_URL + "admin/getMonetisations";
export const GET_SYSTEM_BLIP_COIN_BY_ID_API =
  API_URL + "admin/getMonetisation/";
export const UPDATE_SYSTEM_BLIP_COIN_BY_ID_API =
  API_URL + "admin/updateMonetisation/";
export const UPDATE_SYSTEM_BLIP_COIN_STATUS_BY_ID_API =
  API_URL + "admin/updateMonetisationStatus/";
export const DELETE_SYSTEM_BLIP_COIN_BY_ID_API =
  API_URL + "admin/deleteMonetisation/";

// Blipers Reports

export const GET_REPORTED_BLIPERS_API =
  API_URL + "admin/getBlockBlippersListing";
export const BLOCK_REPORTED_BLIPER_API = API_URL + "admin/blockBlipper";
export const GET_REPORTED_BLIPER_REPORTS_API =
  API_URL + "admin/getBlockBlippersListingDetails";

// Blips Reports

export const GET_REPORTED_BLIPS_API = API_URL + "admin/getBlockBlipsListing";
export const BLOCK_REPORTED_BLIP_API = API_URL + "admin/blockBlip";
export const GET_REPORTED_BLIP_REPORTS_API =
  API_URL + "admin/getBlockBlipsListingDetails";

// Settings Apis
export const GET_PEAK_BLIP_SETTING_API =
  API_URL + "admin/getTotalViewCountSetting";
export const UPDATE_PEAK_BLIP_SETTING_API =
  API_URL + "admin/createOrUpdateViewTotalBlipSetting";

// Anchor Flag API's
export const GET_ANCHOR_BLIPS_API = API_URL + "admin/getAnchorBlipListing";
export const UPDATE_AS_ANCHOR_FLAG_API =
  API_URL + "admin/makeBlipAsAnchorBlip/";
