import React from "react";
import { Logo, PageNotFoundImg } from "../../assets/image-path";
import { useNavigate } from "react-router-dom";

export const PageNotFound = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="authoMain">
        <div className="container-fluid">
          <div className="authContainer">
          <div className="logoPath">
              <img src={Logo} alt="Logo" className="mt-4" />
            </div>
          <div className="notFound">
              <img src={PageNotFoundImg} alt="" />
              <h2>Page Not Found</h2>
              <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old</p>
              <button onClick={()=> navigate("/dashboard")} className="gradientBG">Back To Dashboard</button>              
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
