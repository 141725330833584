import React from "react";

export const TermsConditions = () => {
  return (
    <>
      <div className="appMobile">
        <div className="aPPcontainerBody">
          <div className="container-fluid">
            <div className="contentP">
              <h2>Why do we use it?</h2>
              <p>
                The standard chunk of Lorem Ipsum used since the 1500s is
                reproduced below for those interested. Sections 1.10.32 and
                1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also
                reproduced in their exact original form, accompanied by English
                versions from the 1914 translation Lorem ipsum dolor sit amet,
                consectetur adipiscing elit. Sed pellentesque ultricies eros, et
                dictum velit bibendum ac. Orci varius natoque penatibus et
                magnis dis parturient montes, nascetur ridiculus mus.
                Suspendisse tempus tellus arcu, vitae posuere leo accumsan
                pellentesque. Interdum et malesuada fames ac ante ipsum primis
                in faucibus.
              </p>
              <h3>Our Services</h3>
              <p>
                Contrary to popular belief, Lorem Ipsum is not simply random
                text. It has roots in a piece of classical Latin literature from
                45 BC, making it over 2000 years old. Richard McClintock, a
                Latin professor at Hampden-Sydney College in Virginia, looked up
                one of the more obscure Latin words, consectetur, from a Lorem
                Ipsum passage, and going through the cites of the word in
                classical literature, discovered the undoubtable source. Lorem
                Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus
                Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero,
                written in 45 BC. This book is a treatise on the theory of
                ethics, very popular during the Renaissance. The first line of
                Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line
                in section.
              </p>
              <ul>
                <li>
                  If you are going to use a passage of Lorem Ipsum, you need to
                  be sure there isn't anything embarrassing hidden in the middle
                  of text.
                </li>
                <li>
                  If you are going to use a passage of Lorem Ipsum, you need to
                  be sure there isn't anything embarrassing hidden in the middle
                  of text.
                </li>
                <li>
                  If you are going to use a passage of Lorem Ipsum, you need to
                  be sure there isn't anything embarrassing hidden in the middle
                  of text.
                </li>
                <li>
                  If you are going to use a passage of Lorem Ipsum, you need to
                  be sure there isn't anything embarrassing hidden in the middle
                  of text.
                </li>
                <li>
                  If you are going to use a passage of Lorem Ipsum, you need to
                  be sure there isn't anything embarrassing hidden in the middle
                  of text.
                </li>
              </ul>

              <h3>The standard Lorem Ipsum passage, used since the 1500s</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.:
              </p>
              <ul>
                <li>
                  <span>Ut enim</span> Ut enim ad minim veniam, quis nostrud
                  exercitation ullamco laboris nisi ut aliquip ex ea commodo
                  consequat.
                </li>
                <li>
                  <span>Duis aute </span> Duis aute irure dolor in reprehenderit
                  in voluptate velit esse cillum dolore eu fugiat nulla
                  pariatur.
                </li>
                <li>
                  <span>information</span> You may decide to share information
                  with us through your social media account, for instance if you
                  decide to create and log into your Bond Ever account via your
                  social media or other account (e.g., Facebook, Google or
                  Apple) or to upload onto our services information such as
                  photos from one of your social media accounts (e.g., Instagram
                  or Spotify).
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
