import React, { useEffect, useState } from "react";
import { Header } from "../../../../components/common/Header";
import { SideBar } from "../../../../components/common/SideBar";
import { useNavigate } from "react-router-dom";
import { Dropdown, Modal, ModalBody, ModalHeader } from "react-bootstrap";
import {
  ViewIcon,
  SearchIcon,
  ReloadIcon,
  CloseModalIcon,
  DropDownTBIcon,
  UserProfile,
  BlockBliperModalIcon,
  IgnoreModalIcon,
} from "../../../../assets/image-path";
import { useDispatch, useSelector } from "react-redux";
import { generateNewToken, setLoading } from "../../../../store/auth/AuthSlice";
import {
  blockReportedBliper,
  getBliperReportsById,
  getReportedBlipers,
} from "../../../../store/dashboard/DashboardSlice";
import { formatDateTime } from "../../../../utils/formatDate";
import Pagination from "../../../../components/dashboard/pagination/Pagination";
import { useTranslation } from "react-i18next";
import addCloudFrontBaseUrl from "../../../../utils/addCloudFrontBaseUrl";

export const Blipers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [blockModal, setBlockModal] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedData, setSelectedData] = useState();
  const [statusFilter, setStatusFilter] = useState("");
  //Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit] = useState(+process.env.REACT_APP_PER_PAGE_LIMIT);
  const blipersBlockRequestList = useSelector(
    (state) => state?.dashboard?.reportedBlipers?.data
  );
  const count = useSelector(
    (state) => state?.dashboard?.reportedBlipers?.count
  );

  useEffect(() => {
    if (search === "" || search?.length > 2) {
      getReportedBlipersAPI();
    }
    // eslint-disable-next-line
  }, [currentPage, search, statusFilter]);

  const getReportedBlipersAPI = () => {
    const params = { pageLimit, currentPage, search, statusFilter };
    dispatch(setLoading(true));
    dispatch(getReportedBlipers(params)).then((response) => {
      dispatch(setLoading(false));
      if (response.payload === "token_expired") {
        dispatch(generateNewToken()).then((res) => {
          if (res.payload.success) {
            getReportedBlipersAPI();
          }
        });
      }
    });
  };

  //  Get Bliper Reports By Id API
  const getBliperReportsByIdAPI = (id) => {
    dispatch(setLoading(true));
    dispatch(
      getBliperReportsById({ pageLimit, currentPage: 1, spamUserId: id })
    ).then((response) => {
      dispatch(setLoading(false));
      if (response.payload === "token_expired") {
        dispatch(generateNewToken()).then((res) => {
          if (res.payload.success) {
            getBliperReportsByIdAPI(id);
          }
        });
      } else if (response.payload.success) {
        navigate("reports");
      }
    });
  };

  //   Block Reported Bliper
  const blockReportedBliperAPI = (values) => {
    const params = {
      userId: values?.spamUserId?._id,
      status: values.status === "blocked" ? "ignore" : "blocked",
    };
    dispatch(setLoading(true));
    dispatch(blockReportedBliper(params)).then((response) => {
      dispatch(setLoading(false));
      if (response.payload === "token_expired") {
        dispatch(generateNewToken()).then((res) => {
          if (res.payload.success) {
            blockReportedBliperAPI(values);
          }
        });
      } else if (response.payload.success) {
        getReportedBlipersAPI();
        setBlockModal(false);
      }
    });
  };

  return (
    <>
      <div id="body_wapper" className="mainBody">
        <div className="wapper">
          <SideBar />
          <div className="main-container">
            <Header headingName={""} />
            <div className="containerBody">
              <div className="mainSelectBox">
                <div className="headingPart cusHeadingLarge">
                  <h2>{t("BlipersModeration.pageTitle")}</h2>
                  <div className="rightMarginPT">
                    <div className="reloadBTun">
                      <button
                        onClick={(e) => {
                          setSearch("");
                          setStatusFilter("");
                          setCurrentPage(1);
                        }}
                      >
                        <img src={ReloadIcon} alt="reload" />
                      </button>
                    </div>
                    <Dropdown
                      drop
                      className="dropdown-filter dropdown"
                      onSelect={(e) => {
                        setStatusFilter(e);
                        setCurrentPage(1);
                      }}
                    >
                      <Dropdown.Toggle>
                        <span>{statusFilter || "Filter By Status"}</span>
                        <img src={DropDownTBIcon} alt="icon" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        variant=""
                        id="style-5"
                        className="cusDrupdown"
                      >
                        <Dropdown.Item eventKey="" key={""}>
                          Clear Filter
                        </Dropdown.Item>
                        <Dropdown.Item eventKey="blocked" key={"blocked"}>
                          blocked
                        </Dropdown.Item>
                        <Dropdown.Item eventKey="ignore" key={"ignore"}>
                          ignore
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <span className="searchBgMain mx-0">
                      <input
                        type="text"
                        className=""
                        placeholder="Search"
                        value={search}
                        onChange={(e) => {
                          const s = e.currentTarget?.value
                            ?.replace(/[^\w\s]|_/g, "")
                            .replace(/\s{2,}/g, " ");
                          setSearch(s);
                          s?.length > 2 && setCurrentPage(1);
                        }}
                      />
                      <img src={SearchIcon} alt="Search" />
                    </span>
                  </div>
                </div>
                <div className="tableCusMain">
                  <div className="table-responsive" id="style-5">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>{t("BlipersModeration.srNo")}</th>
                          <th>{t("BlipersModeration.bliperName")}</th>
                          <th>{t("BlipersModeration.dateTime")}</th>
                          <th>{t("BlipersModeration.reportedBy")}</th>
                          <th>{t("BlipersModeration.status")}</th>
                          <th>{t("BlipersModeration.action")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {blipersBlockRequestList?.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                #
                                {parseInt(pageLimit) *
                                  parseInt(currentPage - 1) +
                                  parseInt(index + 1)}
                              </td>
                              <td className="text-capitalize proTableUser">
                                <img
                                  src={
                                    item?.spamUserId?.profileImage
                                      ? addCloudFrontBaseUrl(
                                          item?.spamUserId?.profileImage
                                        )
                                      : UserProfile
                                  }
                                  alt=""
                                  className="profimePicBox"
                                />
                                {item?.spamUserId?.fullName
                                  ? item?.spamUserId?.fullName
                                  : "N/A"}
                              </td>

                              <td>{formatDateTime(item.createdDate)}</td>

                              <td>
                                <span className="">
                                  {item?.totalCount < 10
                                    ? "0" + item?.totalCount
                                    : item?.totalCount}
                                </span>
                              </td>
                              <td>
                                <Dropdown
                                  drop
                                  className="selectCus dropdown"
                                  onSelect={(e) => {
                                    if (e.toLowerCase() === item?.status) {
                                    } else {
                                      setBlockModal(true);
                                      setSelectedData(item);
                                    }
                                  }}
                                >
                                  <Dropdown.Toggle>
                                    <span className="text-capitalize">
                                      {item.status}
                                    </span>
                                    <img src={DropDownTBIcon} alt="icon" />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu
                                    variant=""
                                    id="style-5"
                                    className="cusDrupdown"
                                  >
                                    <Dropdown.Item
                                      eventKey="blocked"
                                      key={"blocked"}
                                    >
                                      {t("buttons.block")}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="ignore"
                                      key={"ignore"}
                                    >
                                      {t("buttons.ignore")}
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                              <td className="">
                                <span className="activeAllBtn">
                                  <button>
                                    <img
                                      src={ViewIcon}
                                      alt="View"
                                      title="View"
                                      onClick={(e) => {
                                        getBliperReportsByIdAPI(
                                          item?.spamUserId?._id
                                        );
                                      }}
                                    />
                                  </button>
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <Pagination
                    currentPage={currentPage}
                    totalCount={count}
                    currentPageCount={blipersBlockRequestList?.length}
                    pageSize={pageLimit}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {blockModal && (
        <Modal className="custumCssMCebter" show={blockModal}>
          <ModalHeader>
            <h6>&nbsp;</h6>
            <button
              type="button"
              className=""
              aria-label="Close"
              onClick={() => {
                setBlockModal(false);
              }}
            >
              <img src={CloseModalIcon} alt="Close Button" />
            </button>
          </ModalHeader>
          <ModalBody>
            <div className="centerModalContent">
              <div className="proConBox">
                <img
                  src={
                    selectedData?.status === "blocked"
                      ? IgnoreModalIcon
                      : BlockBliperModalIcon
                  }
                  alt=""
                  className="img-fluid"
                />
              </div>
              <p>
                {t("BlipersModeration.blockConfirmationPre")}{" "}
                {selectedData?.status === "blocked"
                  ? t("buttons.ignore")
                  : t("buttons.block")}{" "}
                {t("BlipersModeration.blockConfirmationAfter")}
              </p>
              <div className="textConBoxBtn">
                <button
                  onClick={(e) => blockReportedBliperAPI(selectedData)}
                  className="gradientBG"
                >
                  {selectedData?.status === "blocked"
                    ? t("buttons.ignore")
                    : t("buttons.block")}
                </button>
                <button
                  className="cancelBtun"
                  onClick={() => setBlockModal(false)}
                >
                  {t("buttons.cancel")}
                </button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};
