import AWS from "aws-sdk";
import { Buffer } from "buffer";

Buffer.from("anything", "base64");
window.Buffer = window.Buffer || require("buffer").Buffer;

const s3Upload = async (file, key, contentType) => {
  const S3_BUCKET = process.env.REACT_APP_AWS_BUCKET;
  // S3 Credentials
  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  });

  const s3 = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: process.env.REACT_APP_AWS_REGION,
  });

  // Set the S3 object parameters
  const params = {
    Bucket: S3_BUCKET,
    Key: key.toString().trim(),
    Body: file,
    ContentDisposition: "inline",
    ContentType: contentType,
  };
  var options = { partSize: 50 * 1024 * 1024, queueSize: 1 };

  return new Promise((resolve, reject) => {
    // Upload the file to S3
    s3.upload(params, options, (err, data) => {
      if (err) {
        console.error("Error uploading file:", err);

        reject(err); // Reject the promise with an error
      } else if (data?.Location) {
        // console.log("File uploaded successfully:", data?.Location);
        resolve(data?.Location); // Resolve the promise with the file URL
      }
    });
  });
};
export default s3Upload;
