import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const OtpTimer = (props) => {
  const { initialMinute, initialSeconds, setDisableResendOTP, showTimer } =
    props;
  const { t } = useTranslation(); // Use the translation hook
  const [minutes, setMinutes] = useState(initialMinute);
  const [seconds, setSeconds] = useState(initialSeconds);
  useEffect(() => {
    setSeconds(initialSeconds);
  }, [initialSeconds, showTimer]);
  useEffect(() => {
    if (seconds === 0) {
      setDisableResendOTP(false);
    }
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
        setDisableResendOTP(true);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  return (
    <>
      {minutes === 0 && seconds === 0 ? (
        ""
      ) : (
        <>
          <div className="timeLeftBx">
            <span>
              {minutes}:{seconds < 10 ? `0${seconds}` : seconds}{" "}
            </span>
            {t("resetPasswordPage.otpTimeLeft")}
          </div>
        </>
      )}
    </>
  );
};

export default OtpTimer;
