import React from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
const libraries = ["places"];
const mapContainerStyle = {
  width: "100%",
  height: "350px",
  margin: "15px 0 0",
  borderRadius: "15px",
};
const Map = (props) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API,
    libraries: libraries,
  });
  if (!isLoaded) return <div className="App">Loading...</div>;

  return (
    <>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={props.center}
        zoom={12}
      >
        <Marker position={props.center} />
      </GoogleMap>
    </>
  );
};

export default Map;
