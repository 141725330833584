import "./App.css";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";

import { useSelector } from "react-redux";

import { Router } from "./routes/Routes";
import ReduxToastr from "react-redux-toastr";
import AuthToastrError from "./components/auth/AuthToastrErrors";
import DashboardToastrError from "./components/dashboard/DashboardToastrErrors";
import Loader from "./components/common/loader/loader";



function App() {
  
  const { apiLoading } = useSelector((state) => state.auth);
 
  return (
    <>
      <Loader loading={apiLoading} />
      <Router />
      <ReduxToastr
        timeOut={2500}
        newestOnTop={false}
        preventDuplicates
        position="top-right"
        getState={(state) => state.toastr} // This is the default
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar
        closeOnToastrClick
      />
      <AuthToastrError />
      <DashboardToastrError />
    </>
  );
}

export default App;
