import React, { useState } from "react";
// import { CloseModalIcon } from "../../../assets/image-path";
import styles from "./styles.module.css";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker, DateRange } from "react-date-range";
import { addDays, subDays } from "date-fns";

const Calendar = ({ onChange, onClose }) => {
  const [range, setRange] = useState();
  const [state, setState] = useState([
    {
      startDate: subDays(new Date(), 0),
      endDate: addDays(new Date(), 0),
      key: "selection",
    },
  ]);
  // console.log("range" + range?.startDate);
  const convertDateFormat = (originalDate) => {
    const date = new Date(originalDate);
    const formattedDate = `${date.getFullYear()}-${String(
      date.getMonth() + 1
    ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;
    return formattedDate;
  };
  const handleOnChange = (ranges) => {
    const { selection } = ranges;
    // onChange(selection);
    setRange(selection);
    setState([selection]);
  };

  const applybtn = () => {
    const startDate = convertDateFormat(range?.startDate);
    const endDate = convertDateFormat(range?.endDate);
    onChange({ startDate, endDate });
  };

  return (
    <div className={styles["video-player-overlay"]}>
      <div className={styles["video-player"]}>
        {/* <button className={styles["close-button"]} onClick={(e) => onClose()}>
          <img
            src={CloseModalIcon}
            alt="Close Button"
            onClick={(e) => onClose()}
          />
        </button> */}
        <DateRange
          onChange={handleOnChange}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={2}
          ranges={state}
          direction="horizontal"
          showPreview={true}
        />
        <div className="d-flex justify-content-end">
          <button
            className={styles["cancel-btn"]}
            onClick={(e) => {
              onClose();
            }}
          >
            Cancel
          </button>
          <button
            className={styles["apply-btn"] + " gradientBG"}
            onClick={(e) => {
              applybtn();
              onClose();
            }}
            disabled={!range?.startDate}
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  );
};

export default Calendar;
