import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";

//  images

import { generateNewToken, setLoading } from "../../store/auth/AuthSlice";
import {
  createContentManagement,
  getContentManagement,
  updateContentManagement,
} from "../../store/dashboard/DashboardSlice";
import { useTranslation } from "react-i18next";

export const ContentManagementForm = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const contentManagementLinks = useSelector(
    (state) => state?.dashboard?.contentManagementLinks[0]
  );

  useEffect(() => {
    getContentManagementAPI();
    // eslint-disable-next-line
  }, []);

  const getContentManagementAPI = () => {
    dispatch(setLoading(true));
    dispatch(getContentManagement()).then((response) => {
      dispatch(setLoading(false));
      if (response.payload === "token_expired") {
        dispatch(generateNewToken()).then((res) => {
          if (res.payload.success) {
            getContentManagementAPI();
          }
        });
      } else if (response.payload.success) {
      }
    });
  };

  //   Create Content Management
  const createContentManagementAPI = (values) => {
    const params = {
      about_us: values.about_us,
      privacy_policy: values.privacy_policy,
      terms_conditions: values.terms_conditions,
      faq: values.faq,
      help_center: values.help_center,
    };
    dispatch(setLoading(true));
    dispatch(createContentManagement(params)).then((response) => {
      dispatch(setLoading(false));
      if (response.payload === "token_expired") {
        dispatch(generateNewToken()).then((res) => {
          if (res.payload.success) {
            createContentManagementAPI(values);
          }
        });
      } else if (response.payload.success) {
        getContentManagementAPI();
      }
    });
  };

  //   Update Content Management
  const updateContentManagementAPI = (values) => {
    const params = {
      id: contentManagementLinks?._id,
      about_us: values.about_us,
      privacy_policy: values.privacy_policy,
      terms_conditions: values.terms_conditions,
      faq: values.faq,
      help_center: values.help_center,
    };
    dispatch(setLoading(true));
    dispatch(updateContentManagement(params)).then((response) => {
      dispatch(setLoading(false));
      if (response.payload === "token_expired") {
        dispatch(generateNewToken()).then((res) => {
          if (res.payload.success) {
            updateContentManagementAPI(values);
          }
        });
      } else if (response.payload.success) {
        getContentManagementAPI();
      }
    });
  };

  return (
    <>
      {contentManagementLinks && (
        <Formik
          initialValues={{
            about_us: contentManagementLinks
              ? contentManagementLinks?.about_us
              : "",
            privacy_policy: contentManagementLinks
              ? contentManagementLinks?.privacy_policy
              : "",
            terms_conditions: contentManagementLinks
              ? contentManagementLinks?.terms_conditions
              : "",
            faq: contentManagementLinks?.faq ? contentManagementLinks?.faq : "",
            help_center: contentManagementLinks?.help_center
              ? contentManagementLinks?.help_center
              : "",
          }}
          validationSchema={Yup.object().shape({
            about_us: Yup.string().required(
              t("formErrorMsg.aboutLinkRequired")
            ),
            privacy_policy: Yup.string().required(
              t("formErrorMsg.privacyLinkRequired")
            ),
            terms_conditions: Yup.string().required(
              t("formErrorMsg.tcLinkRequired")
            ),
            faq: Yup.string().required(t("formErrorMsg.faqLinkRequired")),
            help_center: Yup.string().required(
              t("formErrorMsg.helpLinkRequired")
            ),
          })}
          onSubmit={(values) => {
            if (contentManagementLinks?._id) {
              updateContentManagementAPI(values);
            } else {
              createContentManagementAPI(values);
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form className="basicInformation" onSubmit={handleSubmit}>
              <div className="row col-xl-5 col-lg-7">
                <div className="col-xl-12">
                  <div className="form-group">
                    <label>{t("formLabel.aboutUs")}</label>
                    <input
                      placeholder="Enter About Us Url"
                      type="text"
                      className="form-control"
                      name="about_us"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values?.about_us}
                    />

                    {errors.about_us && touched.about_us && (
                      <div className="error-msg">{errors.about_us}</div>
                    )}
                  </div>
                </div>
                <div className="col-xl-12">
                  <div className="form-group">
                    <label>{t("formLabel.privacyPolicy")}</label>
                    <input
                      placeholder="Enter Privacy-Policy Url"
                      type="text"
                      className="form-control"
                      name="privacy_policy"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.privacy_policy}
                    />

                    {errors.privacy_policy && touched.privacy_policy && (
                      <div className="error-msg">{errors.privacy_policy}</div>
                    )}
                  </div>
                </div>
                <div className="col-xl-12">
                  <div className="form-group">
                    <label>{t("formLabel.termsConditions")}</label>
                    <input
                      placeholder="Enter Terms & Conditions Url"
                      type="text"
                      className="form-control"
                      name="terms_conditions"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.terms_conditions}
                    />

                    {errors.terms_conditions && touched.terms_conditions && (
                      <div className="error-msg">{errors.terms_conditions}</div>
                    )}
                  </div>
                </div>
                <div className="col-xl-12">
                  <div className="form-group">
                    <label>{t("formLabel.faq")}</label>
                    <input
                      placeholder="Enter faq Url"
                      type="text"
                      className="form-control"
                      name="faq"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.faq}
                    />

                    {errors.faq && touched.faq && (
                      <div className="error-msg">{errors.faq}</div>
                    )}
                  </div>
                </div>
                <div className="col-xl-12">
                  <div className="form-group">
                    <label>{t("formLabel.helpCenter")}</label>
                    <input
                      placeholder="Enter Help Center Url"
                      type="text"
                      className="form-control"
                      name="help_center"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.help_center}
                    />

                    {errors.help_center && touched.help_center && (
                      <div className="error-msg">{errors.help_center}</div>
                    )}
                  </div>
                </div>

                <div className="col-xl-7">
                  <div className="form-groupAdd">
                    <button className="gradientBG" type="submit">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      )}
      {!contentManagementLinks && (
        <Formik
          initialValues={{
            about_us: "",
            privacy_policy: "",
            terms_conditions: "",
            faq: "",
            help_center: "",
          }}
          validationSchema={Yup.object().shape({
            about_us: Yup.string().required("About Us Link Is Required"),
            privacy_policy: Yup.string().required(
              "Privacy Policy Link Is Required"
            ),
            terms_conditions: Yup.string().required(
              "Terms & Conditions Us Link Is Required"
            ),
            faq: Yup.string().required("FAQ Link Is Required"),
            help_center: Yup.string().required("Help Center Link Is Required"),
          })}
          onSubmit={(values) => {
            if (contentManagementLinks?._id) {
              updateContentManagementAPI(values);
            } else {
              createContentManagementAPI(values);
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form className="basicInformation" onSubmit={handleSubmit}>
              <div className="row col-xl-5 col-lg-7">
                <div className="col-xl-12">
                  <div className="form-group">
                    <label> {t("formLabel.aboutUs")}</label>
                    <input
                      placeholder={t("formPlaceholder.aboutUs")}
                      type="text"
                      className="form-control"
                      name="about_us"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values?.about_us}
                    />

                    {errors.about_us && touched.about_us && (
                      <div className="error-msg">{errors.about_us}</div>
                    )}
                  </div>
                </div>
                <div className="col-xl-12">
                  <div className="form-group">
                    <label>{t("formLabel.privacyPolicy")}</label>
                    <input
                      placeholder={t("formPlaceholder.privacyPolicy")}
                      type="text"
                      className="form-control"
                      name="privacy_policy"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.privacy_policy}
                    />

                    {errors.privacy_policy && touched.privacy_policy && (
                      <div className="error-msg">{errors.privacy_policy}</div>
                    )}
                  </div>
                </div>
                <div className="col-xl-12">
                  <div className="form-group">
                    <label>{t("formLabel.termsConditions")}</label>
                    <input
                      placeholder={t("formPlaceholder.termsConditions")}
                      type="text"
                      className="form-control"
                      name="terms_conditions"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.terms_conditions}
                    />

                    {errors.terms_conditions && touched.terms_conditions && (
                      <div className="error-msg">{errors.terms_conditions}</div>
                    )}
                  </div>
                </div>
                <div className="col-xl-12">
                  <div className="form-group">
                    <label>{t("formLabel.faq")}</label>
                    <input
                      placeholder={t("formPlaceholder.faq")}
                      type="text"
                      className="form-control"
                      name="faq"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.faq}
                    />

                    {errors.faq && touched.faq && (
                      <div className="error-msg">{errors.faq}</div>
                    )}
                  </div>
                </div>
                <div className="col-xl-12">
                  <div className="form-group">
                    <label>{t("formLabel.helpCenter")}</label>
                    <input
                      placeholder={t("formPlaceholder.helpCenter")}
                      type="text"
                      className="form-control"
                      name="help_center"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.help_center}
                    />

                    {errors.help_center && touched.help_center && (
                      <div className="error-msg">{errors.help_center}</div>
                    )}
                  </div>
                </div>

                <div className="col-xl-7">
                  <div className="form-groupAdd">
                    <button className="gradientBG" type="submit">
                      {t("buttons.save")}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      )}
    </>
  );
};
