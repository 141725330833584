import React from "react";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import * as Yup from "yup";

import {
  DropDownTBIcon,
  EyelIcon,
  KeyIcon,
  OffEyelIcon,
  PreviousIcon,
} from "../../../assets/image-path";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateBliper } from "../../../store/dashboard/DashboardSlice";
import { generateNewToken, setLoading } from "../../../store/auth/AuthSlice";
import { SideBar } from "../../../components/common/SideBar";
import { Header } from "../../../components/common/Header";
import { Dropdown } from "react-bootstrap";
import s3Upload from "../../../utils/upload-in-s3-bucket";

export const EditBliper = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(); // Use the translation hook

  const bliperDetails = useSelector(
    (state) => state?.dashboard?.bliperDetailById
  );

  //   Update sub Admin By Id
  const updateBliperAPI = (values) => {
    dispatch(setLoading(true));
    dispatch(updateBliper(values)).then((response) => {
      dispatch(setLoading(false));
      if (response.payload === "token_expired") {
        dispatch(generateNewToken()).then((res) => {
          if (res.payload.success) {
            updateBliperAPI(values);
          }
        });
      } else if (response.payload.success) {
        navigate(-1);
      }
    });
  };

  function formatDateToYYYYMMDD(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  function getImageNameFromURL(url) {
    const pathArray = url?.split("/");
    return pathArray[pathArray?.length - 1];
  }

  return (
    <>
      <div id="body_wapper" className="mainBody">
        <div className="wapper">
          <SideBar />
          <div className="main-container">
            <Header headingName={""} />
            <div className="containerBody">
              <div className="mainSelectBox">
                <div className="headingPart">
                  <h2 className="editHeadinCus">
                    {t("editSubAdminPage.formTitle")}
                  </h2>
                  <div className="rightMarginPT">
                    <button className="backbTun" onClick={() => navigate(-1)}>
                      <img src={PreviousIcon} alt="Back" />
                      {t("buttons.back")}
                    </button>
                  </div>
                </div>

                <div className="basicContainer">
                  <Formik
                    initialValues={{
                      id: bliperDetails?._id,
                      profileImage: "",
                      idProof: "",
                      fullName: bliperDetails?.fullName,
                      email: bliperDetails?.email,
                      phoneNumber: bliperDetails?.phoneNumber,
                      userName: bliperDetails.userName,
                      gender: bliperDetails.gender,
                      dob: bliperDetails.dob
                        ? formatDateToYYYYMMDD(new Date(bliperDetails.dob))
                        : "",
                      facebookUrl: bliperDetails.facebookUrl,
                      instagramUrl: bliperDetails.instagramUrl,
                      linkedInUrl: bliperDetails.linkedInUrl,
                      twitterUrl: bliperDetails.twitterUrl,
                      articleFirstUrl: bliperDetails.articleFirstUrl,
                      articleSecondUrl: bliperDetails.articleSecondUrl,
                      articleThirdUrl: bliperDetails.articleThirdUrl,
                      password: "",
                      confirm_password: "",
                      confirm_passwordShow: false,
                      passwordShow: false,
                      verificationRequestStatus:
                        bliperDetails.verificationRequestStatus || "unverified",
                    }}
                    validationSchema={Yup.object().shape({
                      fullName: Yup.string().required(
                        t("formErrorMsg.fullNameRequired")
                      ),
                      email: Yup.string()
                        .email(t("formErrorMsg.emailInvalid"))
                        .required(t("formErrorMsg.emailRequired")),
                      password: Yup.string()
                        .nullable()
                        .matches(
                          /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                          t("formErrorMsg.passwordMatchesValidation")
                        ),

                      confirm_password: Yup.string().when(
                        "password",
                        (value, schema) => {
                          if (value[0]?.length > 0) {
                            return schema
                              .oneOf(
                                [Yup.ref("password"), null],
                                t("formErrorMsg.confirmPasswordMatchValidation")
                              )
                              .nullable()
                              .required(
                                t("formErrorMsg.confirmPasswordRequired")
                              );
                          }
                          return schema;
                        }
                      ),
                      idProof: Yup.mixed().test(
                        "fileType",
                        "Unsupported File Format",
                        (value) => {
                          if (value) {
                            return (
                              value.type === "image/jpeg" ||
                              value.type === "image/jpg" ||
                              value.type === "image/png" ||
                              value.type === "application/pdf"
                            );
                          } else {
                            return true;
                          }
                        }
                      ),
                      profileImage: Yup.mixed().test(
                        "fileType",
                        "Unsupported File Format",
                        (value) => {
                          if (value) {
                            return (
                              value.type === "image/jpeg" ||
                              value.type === "image/jpg" ||
                              value.type === "image/png"
                            );
                          } else {
                            return true;
                          }
                        }
                      ),
                      // articleThirdUrl: Yup.string().url("Invalid URL format"),
                    })}
                    onSubmit={async (values) => {
                      dispatch(setLoading(true));
                      var ts = Math.floor(Date.now() / 1000);
                      const profileKey = "profile/" + ts + "profile.png";
                      const docExtension = values?.idProof?.name
                        ?.split(".")
                        .pop()
                        .toLowerCase();
                      const docKey = "doc/" + ts + "document." + docExtension;

                      const profileUrl = values?.profileImage
                        ? await s3Upload(
                            values?.profileImage,
                            profileKey,
                            "image/png"
                          )
                        : undefined;
                      const idProofUrl = values?.idProof
                        ? await s3Upload(
                            values?.idProof,
                            docKey,
                            values?.idProof?.type
                          )
                        : undefined;
                      const data = {
                        ...values,
                        profileImage: profileUrl ? profileKey : undefined,
                        idProof: idProofUrl ? docKey : undefined,
                      };
                      updateBliperAPI(data);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      setFieldValue,
                    }) => (
                      <form
                        className="basicInformation"
                        onSubmit={handleSubmit}
                      >
                        <div className="row">
                          <div className="col-xl-4 col-lg-4">
                            <div className="form-group">
                              <label>{t("formLabel.profileImage")}</label>
                              <div className="proofbox">
                                <input
                                  type="text"
                                  placeholder="Select Image"
                                  className="form-control"
                                  // value={values?.profileImage?.name}
                                  value={
                                    values.profileImage
                                      ? values?.profileImage?.name
                                      : bliperDetails?.profileImage
                                      ? getImageNameFromURL(
                                          bliperDetails?.profileImage
                                        )
                                      : ""
                                  }
                                  readOnly
                                />
                                <label>
                                  Select File
                                  <input
                                    accept="image/jpeg,image/png,image/jpg"
                                    name="profileImage"
                                    type="file"
                                    className="browseinput"
                                    placeholder="Select document"
                                    onChange={(event) => {
                                      if (
                                        event.currentTarget.files.length > 0
                                      ) {
                                        setFieldValue(
                                          "profileImage",
                                          event.currentTarget.files[0]
                                        );
                                      }
                                    }}
                                    onBlur={handleBlur}
                                  />
                                </label>
                                {errors.profileImage &&
                                  touched.profileImage && (
                                    <div className="error-msg">
                                      {errors.profileImage}
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-4 col-lg-4">
                            <div className="form-group">
                              <label>{t("formLabel.fullNameLabel")}</label>
                              <input
                                type="text"
                                placeholder={t(
                                  "formPlaceholder.fullNamePlaceholder"
                                )}
                                className="form-control"
                                name="fullName"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.fullName}
                              />
                              {errors.fullName && touched.fullName && (
                                <div className="error-msg">
                                  {errors.fullName}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-xl-4 col-lg-4 ">
                            <div className="form-group">
                              <label>{t("formLabel.emailLabel")}</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder={t(
                                  "formPlaceholder.emailPlaceholder"
                                )}
                                name="email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                              />
                              {errors.email && touched.email && (
                                <div className="error-msg">{errors.email}</div>
                              )}
                            </div>
                          </div>
                          <div className="col-xl-4 col-lg-4 ">
                            <div className="form-group">
                              <label>{t("formLabel.userNameLabel")}</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder={t(
                                  "formPlaceholder.userNamePlaceholder"
                                )}
                                name="userName"
                                onChange={(e) =>
                                  handleChange({
                                    target: {
                                      name: e.target.name,
                                      value: e.target.value.replace(/\s/g, ""),
                                    },
                                  })
                                }
                                onBlur={handleBlur}
                                value={values.userName}
                              />
                              {errors.userName && touched.userName && (
                                <div className="error-msg">
                                  {errors.userName}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-xl-4 col-lg-4 ">
                            <div className="form-group">
                              <label>{t("formLabel.phoneLabel")}</label>
                              <div className="input-group">
                                <span
                                  className="input-group-text"
                                  id="basic-addon1"
                                >
                                  {bliperDetails.countryCode}
                                </span>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder={t(
                                    "formPlaceholder.phonePlaceholder"
                                  )}
                                  name="phoneNumber"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.phoneNumber}
                                />
                                {errors.phoneNumber && touched.phoneNumber && (
                                  <div className="error-msg">
                                    {errors.phoneNumber}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-4 col-lg-4 ">
                            <div className="form-group">
                              <label>{t("formLabel.genderLabel")}</label>
                              <div className="form-control-radio">
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="gender"
                                    id="inlineRadio1"
                                    value="male"
                                    onChange={handleChange}
                                    defaultChecked={values.gender === "male"}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="inlineRadio1"
                                  >
                                    Male
                                  </label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="gender"
                                    id="inlineRadio2"
                                    value="female"
                                    onChange={handleChange}
                                    defaultChecked={values.gender === "female"}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="inlineRadio2"
                                  >
                                    Female
                                  </label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="gender"
                                    id="inlineRadio3"
                                    value="other"
                                    onChange={handleChange}
                                    defaultChecked={values.gender === "other"}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="inlineRadio3"
                                  >
                                    Other
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-4 col-lg-4 ">
                            <div className="form-group">
                              <label>{t("formLabel.dobLabel")}</label>

                              <input
                                type="date"
                                className="form-control"
                                placeholder={t(
                                  "formPlaceholder.emailPlaceholder"
                                )}
                                name="dob"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.dob}
                              />
                              {errors.dob && touched.dob && (
                                <div className="error-msg">{errors.dob}</div>
                              )}
                            </div>
                          </div>
                          <div className="col-xl-4 col-lg-4 ">
                            <div className="form-group">
                              <label>{t("formLabel.passwordLabel")}</label>
                              <div className="inputPossion">
                                <img
                                  onClick={(e) =>
                                    setFieldValue(
                                      "passwordShow",
                                      !values.passwordShow
                                    )
                                  }
                                  src={
                                    values.passwordShow ? OffEyelIcon : EyelIcon
                                  }
                                  alt="Eye"
                                  className="eyeHide"
                                />
                                <input
                                  type={
                                    values.passwordShow ? "text" : "password"
                                  }
                                  placeholder={t(
                                    "formPlaceholder.passwordPlaceholder"
                                  )}
                                  className="form-control passwordfield"
                                  name="password"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.password}
                                />
                                <img src={KeyIcon} alt="Key" />
                              </div>

                              {errors.password && touched.password && (
                                <div className="error-msg">
                                  {errors.password}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-xl-4 col-lg-4 ">
                            <div className="form-group">
                              <label>
                                {t("formLabel.confirmPasswordLabel")}
                              </label>
                              <div className="inputPossion">
                                <img
                                  onClick={(e) =>
                                    setFieldValue(
                                      "confirm_passwordShow",
                                      !values.confirm_passwordShow
                                    )
                                  }
                                  src={
                                    values.confirm_passwordShow
                                      ? OffEyelIcon
                                      : EyelIcon
                                  }
                                  alt="Eye"
                                  className="eyeHide"
                                />
                                <input
                                  type={
                                    values.confirm_passwordShow
                                      ? "text"
                                      : "password"
                                  }
                                  placeholder={t(
                                    "formPlaceholder.confirmPasswordPlaceholder"
                                  )}
                                  className="form-control passwordfield"
                                  name="confirm_password"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.confirm_password}
                                />
                                <img src={KeyIcon} alt="Key" />
                              </div>

                              {errors.confirm_password &&
                                touched.confirm_password && (
                                  <div className="error-msg">
                                    {errors.confirm_password}
                                  </div>
                                )}
                            </div>
                          </div>

                          <div className="col-xl-4 col-lg-4 ">
                            <div className="form-group">
                              <label>{t("formLabel.verificationStatus")}</label>
                              <Dropdown
                                drop
                                className="select100 dropdown"
                                onSelect={(e) => {
                                  setFieldValue("verificationRequestStatus", e);
                                }}
                              >
                                <Dropdown.Toggle>
                                  <span className="text-capitalize">
                                    {values?.verificationRequestStatus}
                                  </span>
                                  <img src={DropDownTBIcon} alt="icon" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu
                                  variant=""
                                  id="style-5"
                                  className="cusDrupdown"
                                >
                                  {values?.verificationRequestStatus ===
                                    "requested" && (
                                    <Dropdown.Item
                                      eventKey="declined"
                                      key={"declined"}
                                    >
                                      declined
                                    </Dropdown.Item>
                                  )}

                                  <Dropdown.Item
                                    eventKey="verified"
                                    key={"verified"}
                                  >
                                    verified
                                  </Dropdown.Item>

                                  {values?.verificationRequestStatus !==
                                    "requested" && (
                                    <Dropdown.Item
                                      eventKey="unverified"
                                      key={"unverified"}
                                    >
                                      unverified
                                    </Dropdown.Item>
                                  )}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                          <div className="col-xl-4 col-lg-4 ">
                            <div className="form-group">
                              <label>{t("formLabel.facebookLabel")}</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder={t(
                                  "formPlaceholder.facebookPlaceholder"
                                )}
                                name="facebookUrl"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.facebookUrl}
                              />
                              {errors.facebookUrl && touched.facebookUrl && (
                                <div className="error-msg">
                                  {errors.facebookUrl}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-xl-4 col-lg-4 ">
                            <div className="form-group">
                              <label>{t("formLabel.instagramLabel")}</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder={t(
                                  "formPlaceholder.instagramPlaceholder"
                                )}
                                name="instagramUrl"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.instagramUrl}
                              />
                              {errors.instagramUrl && touched.instagramUrl && (
                                <div className="error-msg">
                                  {errors.instagramUrl}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-xl-4 col-lg-4 ">
                            <div className="form-group">
                              <label>{t("formLabel.linkedinLabel")}</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder={t(
                                  "formPlaceholder.linkedinPlaceholder"
                                )}
                                name="linkedInUrl"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.linkedInUrl}
                              />
                              {errors.linkedInUrl && touched.linkedInUrl && (
                                <div className="error-msg">
                                  {errors.linkedInUrl}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-xl-4 col-lg-4 ">
                            <div className="form-group">
                              <label>{t("formLabel.twitterLabel")}</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder={t(
                                  "formPlaceholder.twitterPlaceholder"
                                )}
                                name="twitterUrl"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.twitterUrl}
                              />
                              {errors.twitterUrl && touched.twitterUrl && (
                                <div className="error-msg">
                                  {errors.twitterUrl}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-xl-4 col-lg-4 ">
                            <div className="form-group">
                              <label>{t("formLabel.article1Label")}</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder={t(
                                  "formPlaceholder.article1Placeholder"
                                )}
                                name="articleFirstUrl"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.articleFirstUrl}
                              />
                              {errors.articleFirstUrl &&
                                touched.articleFirstUrl && (
                                  <div className="error-msg">
                                    {errors.articleFirstUrl}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="col-xl-4 col-lg-4 ">
                            <div className="form-group">
                              <label>{t("formLabel.article2Label")}</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder={t(
                                  "formPlaceholder.article2Placeholder"
                                )}
                                name="articleSecondUrl"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.articleSecondUrl}
                              />
                              {errors.articleSecondUrl &&
                                touched.articleSecondUrl && (
                                  <div className="error-msg">
                                    {errors.articleSecondUrl}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="col-xl-4 col-lg-4 ">
                            <div className="form-group">
                              <label>{t("formLabel.article3Label")}</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder={t(
                                  "formPlaceholder.article3Placeholder"
                                )}
                                name="articleThirdUrl"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.articleThirdUrl}
                              />
                              {errors.articleThirdUrl &&
                                touched.articleThirdUrl && (
                                  <div className="error-msg">
                                    {errors.articleThirdUrl}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="col-xl-4 col-lg-4">
                            <div className="form-group">
                              <label>{t("formLabel.idProof")}</label>
                              <div className="proofbox">
                                <input
                                  type="text"
                                  placeholder="Select Document"
                                  className="form-control"
                                  // value={values?.idProof?.name}
                                  value={
                                    values.idProof
                                      ? values?.idProof?.name
                                      : bliperDetails?.idProof
                                      ? getImageNameFromURL(
                                          bliperDetails?.idProof
                                        )
                                      : ""
                                  }
                                  readOnly
                                />
                                <label>
                                  Select File
                                  <input
                                    accept="image/jpeg,image/png,image/jpg,application/pdf"
                                    name="idProof"
                                    type="file"
                                    className="browseinput"
                                    onChange={(event) => {
                                      if (
                                        event.currentTarget.files.length > 0
                                      ) {
                                        setFieldValue(
                                          "idProof",
                                          event.currentTarget.files[0]
                                        );
                                      }
                                    }}
                                    onBlur={handleBlur}
                                  />
                                </label>
                                {errors.idProof && touched.idProof && (
                                  <div className="error-msg">
                                    {errors.idProof}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-4 col-lg-5">
                            <div className="form-groupAdd">
                              <button className="gradientBG" type="submit">
                                {t("buttons.update")}
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
