import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

//  images
import {
  EmailIcon,
  KeyIcon,
  EyelIcon,
  OffEyelIcon,
} from "../../assets/image-path";
import { login, setLoading } from "../../store/auth/AuthSlice";

export const LoginComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(); // Use the translation hook

  const loginAPI = (values) => {
    const parms = {
      email: values.email,
      password: values.password,
    };
    dispatch(setLoading(true));
    dispatch(login(parms)).then((response) => {
      dispatch(setLoading(false));
      if (response.payload.success) {
        if (values.rememberMe) {
          Cookies.set("email", values.email);
          Cookies.set("password", values.password);
        } else {
          Cookies.remove("email");
          Cookies.remove("password");
        }
      }
    });
  };
  return (
    <>
      <div className="AuthHeaDM">
        <h2>{t("loginPage.loginHeader")}</h2>
        <p>{t("loginPage.loginIntro")}</p>
      </div>
      <Formik
        initialValues={{
          email: Cookies.get("email") ? Cookies.get("email") : "",
          password: Cookies.get("password") ? Cookies.get("password") : "",
          passwordShow: false,
          rememberMe: Cookies.get("password") ? true : false,
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email(t("formErrorMsg.emailInvalid"))
            .required(t("formErrorMsg.emailRequired")),
          password: Yup.string().required(t("formErrorMsg.passwordRequired")),
        })}
        onSubmit={(values) => {
          // same shape as initial values

          loginAPI(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>{t("formLabel.emailLabel")}</label>
              <div className="inputPossion">
                <input
                  type="text"
                  placeholder={t("formPlaceholder.emailPlaceholder")}
                  className="form-control"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />
                <img src={EmailIcon} alt="Email" />
              </div>
              {errors.email && touched.email && (
                <div className="error-msg">{errors.email}</div>
              )}
            </div>
            <div className="form-group">
              <label>{t("formLabel.passwordLabel")}</label>
              <div className="inputPossion">
                <img
                  onClick={(e) =>
                    setFieldValue("passwordShow", !values.passwordShow)
                  }
                  src={values.passwordShow ? OffEyelIcon : EyelIcon}
                  alt="Eye"
                  className="eyeHide"
                />
                <input
                  type={values.passwordShow ? "text" : "password"}
                  placeholder={t("formPlaceholder.passwordPlaceholder")}
                  className="form-control form-controlPass"
                  name="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                />
                <img src={KeyIcon} alt="Key" />
              </div>
              {errors.password && touched.password && (
                <div className="error-msg">{errors.password}</div>
              )}
            </div>
            <div className="form-group">
              <h6>
                <input
                  type="checkbox"
                  id="rememberCheck"
                  name="rememberMe"
                  checked={values.rememberMe}
                  onChange={handleChange}
                />

                <label htmlFor="rememberCheck">
                  {t("formLabel.rememberMeLabel")}
                </label>

                <button
                  onClick={(e) => navigate("/forgot-password")}
                  type="button"
                >
                  {t("loginPage.forgotPasswordButton")}
                </button>
              </h6>
            </div>
            <div className="formButtonBx">
              <button className="gradientBG submitBtN" type="submit">
                {t("loginPage.signInButton")}
              </button>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};
