import React from "react";

export const FooterCoppyRight = () => {
  return (
    <>
      <div className="footerCoppyRight">
        <p>Copyright © 2023 All Rights Reserved.</p>
      </div>
    </>
  );
};
