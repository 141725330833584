import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import { reset } from "../../store/dashboard/DashboardSlice.js";

const DashboardToastrError = () => {
  const dispatch = useDispatch();
  const { message, isError, isSuccess, isLoading } = useSelector(
    (state) => state?.dashboard
  );

  const textCapitalize = (str) => {
    const arr = str?.split(" ");
    for (var i = 0; i < arr?.length; i++) {
      arr[i] = arr[i]?.charAt(0)?.toUpperCase() + arr[i]?.slice(1);
    }
    const str2 = arr?.join(" ");
    return str2;
  };

  useEffect(() => {
    if (isError && !isSuccess) {
      if (message === "token_expired") {
        // toastr.error("Your Token Has Expired, Please Wait.");
      } else toastr.error("Error", textCapitalize(message));
    }
    if (!isError && isSuccess) {
      toastr.success("Success", textCapitalize(message));
    }

    dispatch(reset());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message, isError, isSuccess, isLoading]);
};

export default DashboardToastrError;
