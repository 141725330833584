import React from "react";
import classnames from "classnames";
import { usePagination, DOTS } from "./../../../customHooks/usePagination";
import "./Pagination.css";
import { NextIcon, PreviousIcon } from "../../../assets/image-path";
import { useTranslation } from "react-i18next";
// import TBLProview from "./../../static/images/TBL-proview_icon.svg";
// import TBLNext from "./../../static/images/TBL-next_icon.svg";

const Pagination = (props) => {
  const { t } = useTranslation(); // Use the translation hook
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className,
    currentPageCount,
  } = props;
  // console.log(totalCount);
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });
  // console.log(paginationRange);
  if (currentPage === 0 || paginationRange?.length < 1 || !totalCount) {
    return (
      <h6 className="text-center py-3">{t("pagination.noRecordFound")}</h6>
    );
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage =
    paginationRange && paginationRange[paginationRange?.length - 1];
  // console.log(lastPage);
  return (
    <>
      <div className="footsorting">
        <div className="col-md-4 show_entrieNum">
          <p>
            {t("pagination.showing")} {currentPage * pageSize - pageSize + 1}{" "}
            {t("pagination.to")}{" "}
            {currentPage === lastPage
              ? (currentPage - 1) * pageSize + currentPageCount
              : currentPage * pageSize}{" "}
            {t("pagination.of")} {totalCount} {t("pagination.entries")}
          </p>
        </div>
        <div className="col-md-8 paginavition">
          <ul
            className={classnames("pagination-container", {
              [className]: className,
            })}
          >
            <li
              key="arrow-left"
              className={classnames("pagination-item", {
                disabled: currentPage === 1,
              })}
              onClick={onPrevious}
            >
              {/* <div className="arrow left" /> */}
              <button className="previousBTon">
                <img src={PreviousIcon} alt="Previous" />
                {/* <span className="d-none d-xl-block">
                  {t("pagination.previous")}
                </span> */}
              </button>
            </li>
            {paginationRange?.map((pageNumber, index) => {
              if (pageNumber === DOTS) {
                return (
                  <li key={index} className="pagination-item dots">
                    &#8230;
                  </li>
                );
              }

              return (
                <li
                  key={`paginationKey-${pageNumber}`}
                  className={classnames("pagination-item", {
                    selected: pageNumber === currentPage,
                  })}
                  onClick={() => onPageChange(pageNumber)}
                >
                  <button>{pageNumber}</button>
                </li>
              );
            })}
            <li
              key="arrow-right"
              className={classnames("pagination-item", {
                disabled: currentPage === lastPage,
              })}
              onClick={onNext}
            >
              {/* <div className="arrow right" /> */}
              <button className="nextBTon">
                {/* <span className="d-none d-xl-block">
                  {t("pagination.next")}
                </span>{" "} */}
                <img src={NextIcon} alt="Next" />
              </button>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Pagination;
