import React from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import { Login } from "./../pages/auth/login";
import { ForgotPassword } from "./../pages/auth/forgotPassword";
import { ResetPassword } from "./../pages/auth/ResetPassword";
import { SubAdminManagement } from "./../pages/dashboard/sub-admin-management/SubAdminManagement";
import { Dashboard } from "./../pages/dashboard/Dashboard";
import { ChangePassword } from "./../pages/dashboard/change-password/ChanegePassword";

import { AddSubAdmin } from "./../pages/dashboard/sub-admin-management/AddSubAdmin";
import { EditSubAdmin } from "./../pages/dashboard/sub-admin-management/EditSubAdmin";

import { BlipersManagement } from "./../pages/dashboard/blipers-management/BlipersManagement";
import { BlipersDetails } from "../pages/dashboard/blipers-management/BlipersDetails";
import { BliperActiveDetails } from "../pages/dashboard/blipers-management/BliperActiveDetails";

import { VerificationRequest } from "../pages/dashboard/verification-request/VerificationRequest";
import { VerificationRequestDetails } from "../pages/dashboard/verification-request/VerificationRequestDetails";

import { ContentManagement } from "../pages/dashboard/content-management/ContentManagement";

import { Blipers } from "../pages/dashboard/content-moderation/blipers/Blipers";
import { ContentBliperDetails } from "../pages/dashboard/content-moderation/blipers/ContentBliperDetails";
import { Blips } from "../pages/dashboard/content-moderation/blips/Blips";
import { ContentBlipDetails } from "../pages/dashboard/content-moderation/blips/ContentBlipDetails";
import { BlipCoins } from "../pages/dashboard/blip-coins/BlipCoins";
import { CreateMonetisation } from "../pages/dashboard/blip-coins/CreateMonetisation";
import { UpdateMonetisation } from "../pages/dashboard/blip-coins/UpdateMonetisation";
import { BlipCoinsDetail } from "../pages/dashboard/blip-coins/BlipCoinsDetail";
import { BliperDetailsViewBlips } from "../pages/dashboard/blipers-management/BliperDetailsViewBlips";
import { PageNotFound } from "../components/common/PageNotFound";
import { Setting } from "../pages/dashboard/setting/Setting";
import { PrivacyPolicy } from "../pages/public/privacy-policy";
import { TermsConditions } from "../pages/public/terms-conditions";
import { Faq } from "../pages/public/faq";
import { Help } from "../pages/public/help";
import Aboutus from "../pages/public/about-us";
import AppDownload from "../pages/public/app-download";
import { EditBliper } from "../pages/dashboard/blipers-management/EditBliper";
import { AnchorBlips } from "../pages/dashboard/anchor-blips/AnchorBlips";
import BlipsByBliperId from "../pages/dashboard/blipers-management/BlipsByBliperId";

export const Router = () => {
 
  const { userType } = useSelector((state) => state?.auth);

  return (
    <React.Fragment>
      <BrowserRouter>
        <Routes>
          <Route exact path="/aboutus" element={<Aboutus />} />
          <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route exact path="/terms-conditions" element={<TermsConditions />} />
          <Route exact path="/help" element={<Help />} />
          <Route exact path="/faq" element={<Faq />} />
          <Route exact path="/download-app" element={<AppDownload />} />

          {userType === "" ? (
            <>
              <Route exact path="/" element={<Login />} />
              <Route
                exact
                path="/forgot-password"
                element={<ForgotPassword />}
              />
              <Route exact path="/reset-password" element={<ResetPassword />} />
            </>
          ) : (
            <Route path="*" element={<Navigate to="/dashboard" replace />} />
          )}
          {userType !== "" ? (
            <>
              <Route exact path="/dashboard" element={<Dashboard />} />
              <Route
                exact
                path="/change-password"
                element={<ChangePassword />}
              />
              <Route
                exact
                path="/sub-admin-management"
                element={<SubAdminManagement />}
              />
              <Route
                exact
                path="/sub-admin-management/add"
                element={<AddSubAdmin />}
              />
              <Route
                exact
                path="/sub-admin-management/edit"
                element={<EditSubAdmin />}
              />
              <Route
                exact
                path="/blipers-management"
                element={<BlipersManagement />}
              />
              <Route
                exact
                path="/blipers-management/edit"
                element={<EditBliper />}
              />
              <Route
                exact
                path="/blipers-management/details"
                element={<BlipersDetails />}
              />
              <Route
                exact
                path="/blipers-management/blips"
                element={<BlipsByBliperId />}
              />
              {/* <Route
                exact
                path="/bliper-active-details"
                element={<BliperActiveDetails />}
              /> */}

              <Route
                exact
                path="/bliper-details-view-blips"
                element={<BliperDetailsViewBlips />}
              />

              <Route
                exact
                path="/content-management"
                element={<ContentManagement />}
              />

              <Route
                exact
                path="/verification-request"
                element={<VerificationRequest />}
              />
              <Route
                exact
                path="/verification-request/details"
                element={<VerificationRequestDetails />}
              />
              <Route exact path="/reported-blipers" element={<Blipers />} />
              <Route
                exact
                path="/reported-blipers/reports"
                element={<ContentBliperDetails />}
              />

              <Route exact path="/reported-blips" element={<Blips />} />
              <Route
                exact
                path="/reported-blips/reports"
                element={<ContentBlipDetails />}
              />

              <Route exact path="/blip-coins" element={<BlipCoins />} />
              <Route
                exact
                path="/blip-coins/create-monetisation"
                element={<CreateMonetisation />}
              />
              <Route
                exact
                path="/blip-coins/update-monetisation"
                element={<UpdateMonetisation />}
              />
              <Route
                exact
                path="/blip-coins/details"
                element={<BlipCoinsDetail />}
              />
              <Route exact path="/anchor-blips" element={<AnchorBlips />} />
              <Route exact path="/setting" element={<Setting />} />

              <Route exact path="/page-not-found" element={<PageNotFound />} />
            </>
          ) : (
            <Route path="*" element={<Navigate to="/" replace />} />
          )}
        </Routes>
      </BrowserRouter>
    </React.Fragment>
  );
};
