import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import { reducer as toastrReducer } from "react-redux-toastr";

import thunk from "redux-thunk";

import AuthReducer from "./auth/AuthSlice";
import DashboardReducer from "./dashboard/DashboardSlice";

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    auth: AuthReducer,
    dashboard: DashboardReducer,
    toastr: toastrReducer, // <- Mounted at toastr.
  })
);

const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware({ serializableCheck: false }),
});

export default store;
export const persistor = persistStore(store);
