import React, { useEffect, useRef, useState } from "react";
import { Header } from "../../../components/common/Header";
import { SideBar } from "../../../components/common/SideBar";
import { useNavigate } from "react-router-dom";
import {
  DeleteIcon,
  SearchIcon,
  ReloadIcon,
  PreviousIcon,
  CalendarIcon,
  LikeIcon,
  UnlikeIcon,
  CommentIcon,
  ReportIcon,
  CloseModalIcon,
  DeleteModalImg,
} from "../../../assets/image-path";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { formatDate } from "../../../utils/formatDate";
import {
  getBlipsByBliperId,
  setPlaceBlips,
} from "../../../store/dashboard/DashboardSlice";
import Pagination from "../../../components/dashboard/pagination/Pagination";
import { generateNewToken, setLoading } from "../../../store/auth/AuthSlice";
import Calendar from "../../../components/dashboard/calender/Calender";
import addCloudFrontBaseUrl from "../../../utils/addCloudFrontBaseUrl";

export const BliperActiveDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(); // Use the translation hook
  const isInitialRender = useRef(true);
  const [dateRange, setDateRange] = useState({ startDate: "", endDate: "" });
  const [deleteModal, setDeleteModal] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  //Pagination
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit] = useState(+process.env.REACT_APP_PER_PAGE_LIMIT);

  const count = useSelector(
    (state) => state?.dashboard?.blipsByBliperId?.count
  );
  const blipsByDate = useSelector(
    (state) => state?.dashboard?.blipsByBliperId?.data
  );
  const bliperIdForViewBlip = useSelector(
    (state) => state?.dashboard?.bliperIdForViewBlip
  );

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    if (search === "" || search?.length > 2) {
      getBlipsByBliperIdAPI();
    }
    // eslint-disable-next-line
  }, [currentPage, search, dateRange]);

  // get Blips By Bliper Id
  const getBlipsByBliperIdAPI = () => {
    dispatch(setLoading(true));
    dispatch(
      getBlipsByBliperId({
        id: bliperIdForViewBlip,
        pageLimit,
        currentPage,
        search,
        dateRange,
      })
    ).then((response) => {
      dispatch(setLoading(false));
      if (response.payload === "token_expired") {
        dispatch(generateNewToken()).then((res) => {
          if (res.payload.success) {
            getBlipsByBliperIdAPI();
          }
        });
      }
    });
  };

  function flowersCount(count) {
    if (count < 1000) {
      return count?.toString();
    } else if (count < 1000000) {
      return (count / 1000).toFixed(2) + "K";
    } else {
      return (count / 1000000).toFixed(2) + "M";
    }
  }

  return (
    <>
      <div id="body_wapper" className="mainBody">
        <div className="wapper">
          <SideBar />
          <div className="main-container">
            <Header headingName={""} />
            <div className="containerBody">
              <div className="mainSelectBox">
                <div className="headingPart">
                  <h2>
                    <ul>
                      <li>{t("blipersManagement.title")}</li>
                      <li> {">"} </li>
                      <li>{t("blipsByLocation.pageTitle")}</li>
                    </ul>
                  </h2>
                  <div className="rightMarginPT">
                    <button className="backbTun" onClick={() => navigate(-1)}>
                      <img src={PreviousIcon} alt="Back" />
                      {t("buttons.back")}
                    </button>
                  </div>
                </div>
                <div className="headingPart cusdatepic">
                  <h2>&nbsp;</h2>
                  {blipsByDate?.length > 0 && (
                    <div className="rightMarginPT">
                      <span className="cusdatepicResponsive">
                        <div className="reloadBTun">
                          <button
                            onClick={(e) => {
                              setSearch("");
                              setDateRange({ startDate: "", endDate: "" });
                              setCurrentPage(1);
                            }}
                          >
                            <img src={ReloadIcon} alt="reload" />
                          </button>
                        </div>
                        <span className="searchBgMain">
                          <input
                            type="text"
                            className=""
                            placeholder={t("blipsByLocation.searchPlaceholder")}
                            value={search}
                            onChange={(e) => {
                              setCurrentPage(1);
                              const s = e.currentTarget?.value
                                ?.replace(/[^\w\s]|_/g, "")
                                .replace(/\s{2,}/g, " ");
                              setSearch(s);
                            }}
                          />
                          <img src={SearchIcon} alt="" />
                        </span>
                      </span>
                      <span className="searchBgMain mx-0">
                        <img src={CalendarIcon} alt="Calendar" />

                        <input
                          type="text"
                          className="form-control"
                          onClick={(e) => setShowCalendar(true)}
                          value={
                            dateRange.startDate
                              ? dateRange.startDate + " To " + dateRange.endDate
                              : "Start Date - End Date"
                          }
                        />
                      </span>
                    </div>
                  )}
                </div>
                <div className="blipsboxContainer cusMacBookRes">
                  {blipsByDate?.map((item, index) => {
                    return (
                      <div key={index}>
                        <h2>{formatDate(item.filterDate)}</h2>

                        {item?.blips?.map((item2, idx) => {
                          return (
                            <div
                              key={idx}
                              className="col-xl-4 col-lg-6 col-md-12"
                            >
                              <div className="boxCusblips">
                                <div className="imgboxblip">
                                  <img
                                    src={addCloudFrontBaseUrl(
                                      item2.blip[0]?.thumbnailUrl
                                    )}
                                    alt="Place"
                                    className="img-fluid"
                                  />
                                </div>
                                <div className="textboxblip">
                                  <h3 className="text-capitalize">
                                    {item2.location?.length > 15
                                      ? item2?.location?.slice(0, 12) + " ..."
                                      : item2?.location}
                                    <span>
                                      {item2.blip?.length < 10
                                        ? "0" + item2.blip?.length
                                        : item2.blip?.length}{" "}
                                      {t("bliperDetail.blips")}
                                    </span>
                                  </h3>
                                  <ul>
                                    <li title="Like">
                                      <img src={LikeIcon} alt="Like" />{" "}
                                      <span>
                                        {flowersCount(item2?.likeCount)}
                                      </span>
                                    </li>
                                    <li title="Dislike">
                                      <img src={UnlikeIcon} alt="Unlike" />{" "}
                                      <span>
                                        {flowersCount(item2?.dislikeCount)}
                                      </span>
                                    </li>
                                    <li title="Comment">
                                      <img src={CommentIcon} alt="Comment" />{" "}
                                      <span>
                                        {flowersCount(item2?.commentCount)}
                                      </span>
                                    </li>
                                    <li title="Report">
                                      <img src={ReportIcon} alt="Report" />{" "}
                                      <span>
                                        {flowersCount(item2?.reportCount)}
                                      </span>
                                    </li>
                                  </ul>
                                  <h6>
                                    <button
                                      onClick={() => {
                                        dispatch(setPlaceBlips(item2));
                                        navigate("/bliper-details-view-blips");
                                      }}
                                    >
                                      {t("buttons.viewMore")}
                                    </button>
                                    {/* <button
                                        className="btnClStyle"
                                        onClick={() => setDeleteModal(true)}
                                      >
                                        <img src={DeleteIcon} alt="Delete" />
                                      </button> */}
                                  </h6>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
                <div className="mt-4">
                  <Pagination
                    currentPage={currentPage}
                    totalCount={count}
                    currentPageCount={blipsByDate?.length}
                    pageSize={pageLimit}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {deleteModal && (
        <Modal className="custumCssMCebter" show={deleteModal}>
          <ModalHeader>
            <h6>&nbsp;</h6>
            <button
              type="button"
              className=""
              aria-label="Close"
              onClick={() => {
                setDeleteModal(false);
              }}
            >
              <img src={CloseModalIcon} alt="Close Button" />
            </button>
          </ModalHeader>
          <ModalBody>
            <div className="centerModalContent">
              <div className="proConBox">
                <img src={DeleteModalImg} alt="Delete" className="img-fluid" />
              </div>
              <p>{t("blipersManagement.deleteConfirmation")}</p>
              <div className="textConBoxBtn">
                <button>
                  <img src={DeleteIcon} alt="Delete" />
                  {t("buttons.delete")}
                </button>
                <button
                  className="cancelBtun"
                  onClick={() => setDeleteModal(false)}
                >
                  {t("buttons.cancel")}
                </button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      )}
      {showCalendar && (
        <>
          <Calendar
            onChange={setDateRange}
            onClose={(e) => setShowCalendar(false)}
          />
        </>
      )}
    </>
  );
};
