import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Header } from "../../../components/common/Header";
import { SideBar } from "../../../components/common/SideBar";
import { useNavigate } from "react-router-dom";
import { Dropdown, Modal, ModalBody, ModalHeader } from "react-bootstrap";
import {
  ViewIcon,
  DeleteIcon,
  SearchIcon,
  ReloadIcon,
  CloseModalIcon,
  DeleteModalImg,
  EditIcon,
  InactiveCoinModalIcon,
  ActiveCoinModalIcon,
  DropDownTBIcon,
  PlaceImg01,
  RemoveFromAnchorIcon,
  MarkAsAnchorIcon,
} from "../../../assets/image-path";
import {
  deleteSystemBlipCoinById,
  getSystemBlipCoinById,
  updateSystemBlipCoinStatusById,
  setBlipCoinStatusKey,
  getAnchorBlips,
  updateAsAnchorFlag,
} from "../../../store/dashboard/DashboardSlice";
import { generateNewToken, setLoading } from "../../../store/auth/AuthSlice";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../../components/dashboard/pagination/Pagination";
import VideoPlayer from "../../../components/dashboard/video-player/VideoPlayer";
import addCloudFrontBaseUrl from "../../../utils/addCloudFrontBaseUrl";

export const AnchorBlips = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [deleteModal, setDeleteModal] = useState(false);

  const [statusModal, setStatusModal] = useState(false);
  const [selectedData, setSelectedData] = useState();

  const [search, setSearch] = useState("");
  const [showVideo, setShowVideo] = useState(false);
  const [statusFilter, setStatusFilter] = useState("");
  const [coinType, setCoinType] = useState("");
  //Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit] = useState(+process.env.REACT_APP_PER_PAGE_LIMIT);

  const anchorBlipList = useSelector(
    (state) => state?.dashboard?.anchorBlips?.data
  );

  const count = useSelector((state) => state?.dashboard?.anchorBlips?.count);

  useEffect(() => {
    if (search === "" || search?.length > 2) {
      getAnchorBlipsAPI();
    } // eslint-disable-next-line
  }, [currentPage, search, statusFilter, coinType]);

  const getAnchorBlipsAPI = () => {
    const params = {
      pageLimit,
      currentPage,
      search,
      statusFilter,
    };
    dispatch(setLoading(true));
    dispatch(getAnchorBlips(params)).then((response) => {
      dispatch(setLoading(false));
      if (response.payload === "token_expired") {
        dispatch(generateNewToken()).then((res) => {
          if (res.payload.success) {
            getAnchorBlipsAPI();
          }
        });
      }
    });
  };

  //   Update AsAnchor FlagAPI
  const updateAsAnchorFlagAPI = (id) => {
    const params = {
      id,
      isAnchor: false,
    };
    dispatch(setLoading(true));
    dispatch(updateAsAnchorFlag(params)).then((response) => {
      dispatch(setLoading(false));
      if (response.payload === "token_expired") {
        dispatch(generateNewToken()).then((res) => {
          if (res.payload.success) {
            updateAsAnchorFlagAPI(id);
          }
        });
      } else if (response.payload.success) {
        getAnchorBlipsAPI();
        setDeleteModal(false);
        setSelectedData();
      }
    });
  };

  return (
    <>
      <div id="body_wapper" className="mainBody">
        <div className="wapper">
          <SideBar />
          <div className="main-container">
            <Header headingName={""} />
            <div className="containerBody">
              <div className="mainSelectBox">
                <div className="headingPart cusHeadingLarge cusResponCoins">
                  <h2>{t("AnchorBlipPage.pageTitle")}</h2>
                  <span>
                    <div className="rightMarginPT cusResponCoinsmobile mobileshowcusCoin">
                      <span className="searchBgMain">
                        <input
                          type="text"
                          className=""
                          placeholder={t("AnchorBlipPage.searchPlaceholder")}
                          value={search}
                          onChange={(e) => {
                            const s = e.currentTarget?.value
                              ?.replace(/[^\w\s]|_/g, "")
                              .replace(/\s{2,}/g, " ");
                            setSearch(s);
                            s?.length > 2 && setCurrentPage(1);
                          }}
                        />
                        <img src={SearchIcon} alt="" />
                      </span>
                    </div>
                    <div className="rightMarginPT">
                      <div className="reloadBTun">
                        <button
                          onClick={(e) => {
                            setSearch("");
                            setStatusFilter("");
                            setCoinType("");
                            dispatch(setBlipCoinStatusKey(""));
                            setCurrentPage(1);
                          }}
                        >
                          <img src={ReloadIcon} alt="reload" />
                        </button>
                      </div>

                      <Dropdown
                        drop
                        className="dropdown-filter dropdown"
                        onSelect={(e) => {
                          setStatusFilter(e);
                          dispatch(setBlipCoinStatusKey(e));
                          setCurrentPage(1);
                        }}
                      >
                        <Dropdown.Toggle>
                          <span>{statusFilter || "Filter By Status"}</span>
                          <img src={DropDownTBIcon} alt="icon" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          variant=""
                          id="style-5"
                          className="cusDrupdown"
                        >
                          <Dropdown.Item eventKey="" key={""}>
                            Clear Filter
                          </Dropdown.Item>
                          <Dropdown.Item eventKey="active" key={"active"}>
                            active
                          </Dropdown.Item>
                          <Dropdown.Item eventKey="inactive" key={"inactive"}>
                            inactive
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <div className="rightMarginPT cusResponCoinsmobile destopshowcusCoin">
                      <span className="searchBgMain">
                        <input
                          type="text"
                          className=""
                          placeholder={t("AnchorBlipPage.searchPlaceholder")}
                          value={search}
                          onChange={(e) => {
                            const s = e.currentTarget?.value
                              ?.replace(/[^\w\s]|_/g, "")
                              .replace(/\s{2,}/g, " ");
                            setSearch(s);
                            s?.length > 2 && setCurrentPage(1);
                          }}
                        />
                        <img src={SearchIcon} alt="" />
                      </span>
                    </div>
                  </span>
                </div>
                <div className="tableCusMain">
                  <div className="table-responsive" id="style-5">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>{t("AnchorBlipPage.srNo")}</th>
                          <th>{t("AnchorBlipPage.blip")}</th>
                          <th>{t("AnchorBlipPage.title")}</th>
                          <th>{t("AnchorBlipPage.location")}</th>
                          <th>{t("AnchorBlipPage.status")}</th>
                          <th>{t("AnchorBlipPage.view")}</th>
                          <th>{t("AnchorBlipPage.action")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {anchorBlipList?.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                #
                                {parseInt(pageLimit) *
                                  parseInt(currentPage - 1) +
                                  parseInt(index + 1)}
                              </td>
                              <td className="proTableUser">
                                <img
                                  src={
                                    item?.thumbnailUrl
                                      ? addCloudFrontBaseUrl(item?.thumbnailUrl)
                                      : PlaceImg01
                                  }
                                  alt=""
                                  className="placeprofileimg"
                                />
                              </td>
                              <td className="textLargeAdd text-capitalize">
                                {item?.title ? item?.title : "N/A"}
                              </td>

                              <td className="textLargeAdd">{item?.address}</td>

                              <td>
                                <span
                                  className={
                                    item?.status === "active"
                                      ? "activeTd textGreen"
                                      : "inactiveTd textRed"
                                  }
                                >
                                  {item?.status}
                                </span>
                              </td>
                              <td>
                                <button
                                  onClick={(e) => {
                                    setSelectedData(item);
                                    setShowVideo(true);
                                  }}
                                  className="viewBtton"
                                >
                                  {t("buttons.view")}
                                </button>
                              </td>
                              <td className="">
                                <span className="activeAllBtn">
                                  <button>
                                    <img
                                      src={DeleteIcon}
                                      alt="Delete"
                                      title="Delete"
                                      onClick={(e) => {
                                        setSelectedData(item);
                                        setDeleteModal(true);
                                      }}
                                    />
                                  </button>
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <Pagination
                    currentPage={currentPage}
                    totalCount={count}
                    currentPageCount={anchorBlipList?.length}
                    pageSize={pageLimit}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {deleteModal && (
        <Modal className="custumCssMCebter" show={deleteModal}>
          <ModalHeader>
            <h6>&nbsp;</h6>
            <button
              type="button"
              className=""
              aria-label="Close"
              onClick={() => {
                setDeleteModal(false);
              }}
            >
              <img src={CloseModalIcon} alt="Close Button" />
            </button>
          </ModalHeader>
          <ModalBody>
            <div className="centerModalContent">
              <div className="proConBox">
                <img src={RemoveFromAnchorIcon} alt="" className="img-fluid" />
              </div>
              <p>{t("blipersBlip.anchorBlipConfirmationFalse")}</p>
              <div className="textConBoxBtn">
                <button
                  onClick={(e) => updateAsAnchorFlagAPI(selectedData?._id)}
                >
                  <img src={DeleteIcon} alt="" />
                  {t("buttons.remove")}
                </button>
                <button
                  className="cancelBtun"
                  onClick={() => setDeleteModal(false)}
                >
                  {t("buttons.cancel")}
                </button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      )}

      {showVideo && (
        <VideoPlayer
          videoSrc={addCloudFrontBaseUrl(selectedData?.originalLink)}
          onClose={(e) => setShowVideo(false)}
        />
      )}
    </>
  );
};
