import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Header } from "../../../components/common/Header";
import { SideBar } from "../../../components/common/SideBar";
import { useNavigate } from "react-router-dom";
import { Dropdown, Modal, ModalBody, ModalHeader } from "react-bootstrap";
import {
  ViewIcon,
  EditIcon,
  DeleteIcon,
  SearchIcon,
  ReloadIcon,
  AddPlusIcon,
  CloseModalIcon,
  UserProfile,
  DeleteModalImg,
  ActiveModalImg,
  InactiveModalImg,
  DropDownTBIcon,
} from "../../../assets/image-path";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteSubAdminById,
  getSubAdminById,
  getSubAdmins,
  updateSubAdminStatusById,
} from "../../../store/dashboard/DashboardSlice";
import { generateNewToken, setLoading } from "../../../store/auth/AuthSlice";
import Pagination from "../../../components/dashboard/pagination/Pagination";

export const SubAdminManagement = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(); // Use the translation hook
  const [viewModal, setViewModal] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedData, setSelectedData] = useState();

  const [search, setSearch] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  //Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit] = useState(+process.env.REACT_APP_PER_PAGE_LIMIT);
  const { data: subAdminList, count } = useSelector(
    (state) => state?.dashboard?.subAdmins
  );
  const { userType } = useSelector((state) => state?.auth);

  // Fetch Sub Admins whenever the currentPage or search changes
  useEffect(() => {
    if (search === "" || search?.length > 2) {
      getSubAdminsAPI();
    } // eslint-disable-next-line
  }, [currentPage, search, statusFilter]);

  const getSubAdminsAPI = () => {
    const params = { pageLimit, currentPage, search, statusFilter };
    dispatch(setLoading(true));
    dispatch(getSubAdmins(params)).then((response) => {
      dispatch(setLoading(false));
      if (response.payload === "token_expired") {
        dispatch(generateNewToken()).then((res) => {
          if (res.payload.success) {
            getSubAdminsAPI();
          }
        });
      }
    });
  };

  //   Delete sub admin by id
  const deleteSubAdminByIdAPI = (id) => {
    dispatch(setLoading(true));
    dispatch(deleteSubAdminById(id)).then((response) => {
      dispatch(setLoading(false));
      if (response.payload === "token_expired") {
        dispatch(generateNewToken()).then((res) => {
          if (res.payload.success) {
            deleteSubAdminByIdAPI(id);
          }
        });
      } else if (response.payload.success) {
        getSubAdminsAPI();
        setDeleteModal(false);
        setViewModal(false);
        setSelectedData();
      }
    });
  };

  //   Get sub Admin By Id API
  const getSubAdminByIdAPI = (id) => {
    dispatch(setLoading(true));
    dispatch(getSubAdminById(id)).then((response) => {
      dispatch(setLoading(false));
      if (response.payload === "token_expired") {
        dispatch(generateNewToken()).then((res) => {
          if (res.payload.success) {
            getSubAdminByIdAPI(id);
          }
        });
      } else if (response.payload.success) {
        navigate("edit");
      }
    });
  };

  //   Update sub Admin Status
  const updateSubAdminStatusAPI = (values) => {
    const params = {
      id: values._id,
      status: values.status === "active" ? "inactive" : "active",
    };
    dispatch(setLoading(true));
    dispatch(updateSubAdminStatusById(params)).then((response) => {
      dispatch(setLoading(false));
      if (response.payload === "token_expired") {
        dispatch(generateNewToken()).then((res) => {
          if (res.payload.success) {
            updateSubAdminStatusAPI(values);
          }
        });
      } else if (response.payload.success) {
        getSubAdminsAPI();
        setStatusModal(false);
      }
    });
  };

  return (
    <>
      <div id="body_wapper" className="mainBody">
        <div className="wapper">
          <SideBar />
          <div className="main-container">
            <Header headingName={""} />
            <div className="containerBody">
              <div className="mainSelectBox">
                <div className="headingPart cusHeadingLarge">
                  <h2>{t("subAdminManagement.title")}</h2>
                  <span>
                    <div className="rightMarginPT">
                      <div className="reloadBTun">
                        <button
                          onClick={(e) => {
                            setSearch("");
                            setStatusFilter("");
                            setCurrentPage(1);
                          }}
                        >
                          <img src={ReloadIcon} alt="reload" />
                        </button>
                      </div>
                      <Dropdown
                        drop
                        className="dropdown-filter dropdown"
                        onSelect={(e) => {
                          setStatusFilter(e);
                          setCurrentPage(1);
                        }}
                      >
                        <Dropdown.Toggle>
                          <span>{statusFilter || "Filter By Status"}</span>
                          <img src={DropDownTBIcon} alt="icon" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          variant=""
                          id="style-5"
                          className="cusDrupdown"
                        >
                          <Dropdown.Item eventKey="" key={""}>
                            Clear Filter
                          </Dropdown.Item>
                          <Dropdown.Item eventKey="active" key={"active"}>
                            active
                          </Dropdown.Item>
                          <Dropdown.Item eventKey="inactive" key={"inactive"}>
                            inactive
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      <span className="searchBgMain">
                        <input
                          type="text"
                          placeholder={t(
                            "subAdminManagement.searchPlaceholder"
                          )}
                          value={search}
                          onChange={(e) => {
                            const s = e.currentTarget?.value
                              ?.replace(/[^\w\s]|_/g, "")
                              .replace(/\s{2,}/g, " ");
                            setSearch(s);
                            s?.length > 2 && setCurrentPage(1);
                          }}
                        />
                        <img src={SearchIcon} alt="Search" />
                      </span>
                    </div>
                    <div className="rightMarginPT">
                      {userType === "Admin" && (
                        <button
                          className="gradientBG addBtun cusresbuttR"
                          onClick={(e) => navigate("add")}
                        >
                          <img src={AddPlusIcon} alt="Add" />
                          {t("subAdminManagement.addSubAdmin")}
                        </button>
                      )}
                    </div>
                  </span>
                </div>
                <div className="tableCusMain">
                  <div className="table-responsive" id="style-5">
                    <table className="table">
                      <thead>
                        <tr>
                          <th> {t("subAdminManagement.srNo")}</th>
                          <th> {t("subAdminManagement.fullName")}</th>
                          <th> {t("subAdminManagement.email")}</th>
                          <th> {t("subAdminManagement.status")}</th>
                          <th className="text-center">
                            {" "}
                            {t("subAdminManagement.action")}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {subAdminList?.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                #
                                {parseInt(pageLimit) *
                                  parseInt(currentPage - 1) +
                                  parseInt(index + 1)}
                              </td>
                              <td className="text-capitalize">
                                {item.fullName}
                              </td>
                              <td>{item.email}</td>
                              <td>
                                {/* <span
                                  className={
                                    item.status === "active"
                                      ? "activeTd textGreen"
                                      : "inactiveTd textRed"
                                  }
                                >
                                  {item.status}
                                </span> */}
                                {userType === "Admin" && (
                                  <small className="swichBx">
                                    <input
                                      type="checkbox"
                                      id={item._id}
                                      checked={item.status === "active"}
                                      onClick={() => {
                                        setSelectedData(item);
                                        setStatusModal(true);
                                      }}
                                      readOnly
                                    />
                                    <label htmlFor={item._id}></label>
                                  </small>
                                )}
                              </td>
                              <td className="text-center d-flex justify-content-center">
                                <span className="activeAllBtn">
                                  <button>
                                    <img
                                      src={ViewIcon}
                                      alt="View"
                                      title="View"
                                      onClick={(e) => {
                                        setSelectedData(item);
                                        setViewModal(true);
                                      }}
                                    />
                                  </button>
                                  {userType === "Admin" && (
                                    <>
                                      <button>
                                        <img
                                          src={EditIcon}
                                          alt="Edit"
                                          title="Edit"
                                          onClick={(e) =>
                                            getSubAdminByIdAPI(item._id)
                                          }
                                        />
                                      </button>
                                      <button>
                                        <img
                                          src={DeleteIcon}
                                          alt="Delete"
                                          title="Delete"
                                          onClick={(e) => {
                                            setSelectedData(item);
                                            setDeleteModal(true);
                                          }}
                                        />
                                      </button>
                                    </>
                                  )}
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <Pagination
                    currentPage={currentPage}
                    totalCount={count}
                    currentPageCount={subAdminList?.length}
                    pageSize={pageLimit}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {viewModal && (
        <Modal className="custumCssM" show={viewModal}>
          <ModalHeader>
            <h6>{t("subAdminManagement.subAdminInformation")}</h6>
            <button
              type="button"
              className=""
              aria-label="Close"
              onClick={() => {
                setViewModal(false);
              }}
            >
              <img src={CloseModalIcon} alt="Close Button" />
            </button>
          </ModalHeader>
          <ModalBody>
            <div className="viewContent">
              <div className="proConBox">
                <img src={UserProfile} alt="User" />
              </div>
              <ul>
                <li>
                  {t("formLabel.fullNameLabel")}
                  <span className="lableHead text-capitalize">
                    {selectedData?.fullName}
                  </span>
                </li>
                <li>
                  {t("formLabel.emailLabel")}
                  <span className="lableHead">{selectedData?.email}</span>
                </li>

                <li>
                  {t("subAdminManagement.status")}
                  <span
                    className={
                      selectedData?.status === "active"
                        ? "lableHead text-capitalize textGreen"
                        : "lableHead text-capitalize textRed"
                    }
                  >
                    {selectedData?.status}
                  </span>
                </li>
              </ul>
              {userType === "Admin" && (
                <div className="textConBoxBtn">
                  <button onClick={(e) => getSubAdminByIdAPI(selectedData._id)}>
                    <img src={EditIcon} alt="Edit" />
                    {t("buttons.edit")}
                  </button>
                  <button
                    className="deletBg"
                    onClick={(e) => setDeleteModal(true)}
                  >
                    <img src={DeleteIcon} alt="Delete" />
                    {t("buttons.delete")}
                  </button>
                </div>
              )}
            </div>
          </ModalBody>
        </Modal>
      )}

      {deleteModal && (
        <Modal className="custumCssMCebter" show={deleteModal}>
          <ModalHeader>
            <h6>&nbsp;</h6>
            <button
              type="button"
              className=""
              aria-label="Close"
              onClick={() => {
                setDeleteModal(false);
              }}
            >
              <img src={CloseModalIcon} alt="Close Button" />
            </button>
          </ModalHeader>
          <ModalBody>
            <div className="centerModalContent">
              <div className="proConBox">
                <img src={DeleteModalImg} alt="Delete" className="img-fluid" />
              </div>
              <p>{t("subAdminManagement.deleteConfirmation")}</p>
              <div className="textConBoxBtn">
                <button
                  onClick={(e) => deleteSubAdminByIdAPI(selectedData?._id)}
                >
                  <img src={DeleteIcon} alt="Delete" />
                  {t("buttons.delete")}
                </button>
                <button
                  className="cancelBtun"
                  onClick={() => setDeleteModal(false)}
                >
                  {t("buttons.cancel")}
                </button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      )}
      {statusModal && (
        <Modal className="custumCssMCebter" show={statusModal}>
          <ModalHeader>
            <h6>&nbsp;</h6>
            <button
              type="button"
              className=""
              aria-label="Close"
              onClick={() => {
                setStatusModal(false);
              }}
            >
              <img src={CloseModalIcon} alt="Close Button" />
            </button>
          </ModalHeader>
          <ModalBody>
            <div className="centerModalContent">
              <div className="proConBox">
                <img
                  src={
                    selectedData?.status === "active"
                      ? InactiveModalImg
                      : ActiveModalImg
                  }
                  alt="Active"
                  className="img-fluid"
                />
              </div>
              <p>
                {t("subAdminManagement.statusConfirmationPre")}{" "}
                {selectedData?.status === "active" ? "Inactive" : "Active"}{" "}
                {t("subAdminManagement.statusConfirmationAfter")}
              </p>
              <div className="textConBoxBtn">
                <button
                  className="gradientBG"
                  onClick={(e) => updateSubAdminStatusAPI(selectedData)}
                >
                  {selectedData?.status === "active"
                    ? t("buttons.inactive")
                    : t("buttons.active")}
                </button>
                <button
                  className="cancelBtun"
                  onClick={() => setStatusModal(false)}
                >
                  {t("buttons.cancel")}
                </button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};
