import React, { useEffect, useState } from "react";
import {
  CloseModalIcon,
  DropDownicon,
  LogoutIcon,
  ToggleMenuIcon,
  UserProfile,
} from "../../assets/image-path";
import { useDispatch, useSelector } from "react-redux";

import {
  dashboardLogoutReset,
  setSideBarShow,
} from "../../store/dashboard/DashboardSlice";
import storage from "redux-persist/lib/storage";
import { useNavigate } from "react-router-dom";

import { logout, logoutToServer } from "./../../store/auth/AuthSlice";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";

export const Header = ({ ...props }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { headingName } = props;
  const [show, setShow] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);

  const sideBarShow = useSelector((state) => state?.dashboard?.sideBarShow);
  const { loginUser, userType } = useSelector((state) => state?.auth);


  // useEffect to close dropdown on outside click
  useEffect(() => {
    if (show) {
      document.addEventListener("click", function (event) {
        const box = document.querySelector("#dropdown_perent");
        const isClickInsideBox = box?.contains(event.target);

        if (!isClickInsideBox) {
          setShow(false);
        }
      });
    }
  }, [show]);

  useEffect(() => {
    // Get the element by its id
    const bodyWrapper = document.getElementById("body_wapper");
    // Check if the element exists before manipulating it
    if (bodyWrapper) {
      // Remove the "sortingMain" class when sideBarShow is true, and add it when false
      if (sideBarShow) {
        bodyWrapper.classList.remove("sortingMain");
      } else {
        bodyWrapper.classList.add("sortingMain");
      }
    }
    // eslint-disable-next-line
  }, [sideBarShow]);

  useEffect(() => {
    if (userType === "") {
      navigate("/");
    }
    // eslint-disable-next-line
  }, [userType]);

  const Logout =  () => {
    dispatch(logoutToServer());
    dispatch(logout());
    dispatch(dashboardLogoutReset());
    localStorage.clear();
    storage?.localStorage?.clear();
    navigate("/");
  };



  return (
    <>
      <div className="header_dash">
        <div className="">
          <nav className="navbar navbar-expand-md navbar-dark ">
            <div className="collapse navbar-collapse" id="navbarCollapse">
              <div
                className="toggleCusMenu"
                onClick={() => {
                  dispatch(setSideBarShow());
                }}
              >
                <img src={ToggleMenuIcon} alt="1" />
              </div>
              <div className="AdminNameHead">
                <h2>{headingName} </h2>
              </div>
              <ul className="navbar-nav">
                <li
                  id="dropdown_perent"
                  className="nav-item dropdown"
                  onClick={() => {
                    setShow(!show);
                  }}
                >
                  <button
                    className={
                      show
                        ? "nav-link profile-droup  active"
                        : "nav-link profile-droup "
                    }
                    id="navbarDropdownMenuLink"
                    data-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img src={UserProfile} className="profileBox" alt="User" />
                    <span className="profile_NameOverf">
                      {loginUser?.fullName}
                      <small>
                        {loginUser?.role === "1"
                          ? "Admin"
                          : loginUser?.role === "2"
                          ? "Sub Admin"
                          : "Invalid User"}
                      </small>
                    </span>
                    <img
                      src={DropDownicon}
                      className="right-doun"
                      alt="dropdown"
                    />
                  </button>
                  <div
                    className={
                      show ? "dropdown-menu dropdownOpen" : "dropdown-menu"
                    }
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    <button
                      className="dropdown-item"
                      onClick={() => navigate("/change-password")}
                    >
                      Change Password
                    </button>
                    <hr />
                    <button
                      className="dropdown-item dropdown-item-no"
                      onClick={(e) => setLogoutModal(true)}
                    >
                      Logout
                    </button>
                  </div>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
      {logoutModal && (
        <Modal className="custumCssMCebter" show={logoutModal}>
          <ModalHeader>
            <h6>&nbsp;</h6>
            <button
              type="button"
              className=""
              aria-label="Close"
              onClick={() => {
                setLogoutModal(false);
              }}
            >
              <img src={CloseModalIcon} alt="Close Button" />
            </button>
          </ModalHeader>
          <ModalBody>
            <div className="centerModalContent">
              <div className="proConBox">
                <img src={LogoutIcon} alt="Delete" className="img-fluid" />
              </div>
              <p>Are you sure you want to logout?</p>
              <div className="textConBoxBtn">
                <button onClick={(e) => Logout()}>Logout</button>
                <button
                  className="cancelBtun"
                  onClick={() => setLogoutModal(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};
