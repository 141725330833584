import React, { useEffect, useState } from "react";
import "./loader.css";

const Loader = ({ loading }) => {
  const [showLoader, setShowLoader] = useState(loading);

  useEffect(() => {
    // Show the loader immediately when loading is true
    if (loading) {
      setShowLoader(loading);
    }

    // If loading is false, keep the loader visible for a minimum of 2 seconds
    if (!loading) {
      const timer = setTimeout(() => {
        setShowLoader(false);
      }, 800);

      // Clear the timer if the component unmounts before the 2 seconds
      return () => clearTimeout(timer);
    }
  }, [loading]);

  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);

  useEffect(() => {
    // Record the start time when loading changes to true
    if (loading && !startTime) {
      setStartTime(Date.now());
    }

    // If loading is false, record the end time
    if (!loading && startTime && !endTime) {
      setEndTime(Date.now());
    }

    // If loading is false and both start and end times are recorded, log the time difference
    if (!loading && startTime && endTime) {
      const timeDifference = endTime - startTime;
      // console.log("Time between true to false: ", timeDifference, "ms");
      if (timeDifference > 800) {
        setShowLoader(false);
      }
      setStartTime(null);
      setEndTime(null);
    }
  }, [loading, startTime, endTime]);

  return (
    <>
      {showLoader ? (
        <div className="loader-background">
          <div className="loader"></div>
        </div>
      ) : null}
    </>
  );
};
export default Loader;
// import React from "react";
// import "./loader.css";
// const Loader = ({ loading }) => {
//   return (
//     <>
//       {loading ? (
//         <div className="loader-background">
//           <div className="loader"></div>
//         </div>
//       ) : (
//         <div className="">
//           <div className=""></div>
//         </div>
//       )}
//     </>
//   );
// };

// export default Loader;
