import React, { useState } from "react";
import Cookies from "js-cookie";
import { Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

import { useDispatch } from "react-redux";
//  images
import {
  KeyIcon,
  EyelIcon,
  OffEyelIcon,
  EditBackIcon,
} from "../../assets/image-path";
import {
  forgotPassword,
  resetPasswordWithOtp,
  setLoading,
} from "../../store/auth/AuthSlice";
import { OtpComponent } from "./OtpComponent";
import OtpTimer from "./OtpTimer";
import { useNavigate } from "react-router-dom";

export const ResetPasswordWithOtpForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(); // Use the translation hook
  const [disableResendOTP, setDisableResendOTP] = useState(true);
  const [showTimer, setShowTimer] = useState(true);
  const [time, setTime] = useState(60);

  // Function to resend OTP
  const forgotPasswordOTPAPI = (event) => {
    event.preventDefault();
    const parms = {
      email: Cookies.get("forgotPassEmail")
        ? Cookies.get("forgotPassEmail")
        : "",
    };
    dispatch(setLoading(true));
    dispatch(forgotPassword(parms)).then((response) => {
      dispatch(setLoading(false));
      if (response?.payload?.success) {
        setShowTimer(!showTimer);
        setTime(60);
      }
    });
  };

  const resetPasswordWithOtpAPI = (values) => {
    const parms = {
      email: Cookies.get("forgotPassEmail")
        ? Cookies.get("forgotPassEmail")
        : "",
      password: values.password,
      otp: values.otp,
    };
    dispatch(setLoading(true));
    dispatch(resetPasswordWithOtp(parms)).then((response) => {
      dispatch(setLoading(false));
      if (response.payload.success) {
        Cookies.remove("forgotPassEmail");
        navigate("/");
      }
    });
  };
  return (
    <>
      <div className="AuthHeaDM">
        <h2> {t("resetPasswordPage.resetPasswordHeader")}</h2>
        <p>{t("resetPasswordPage.resetPasswordIntro")}</p>
        <h6>
          "
          {Cookies.get("forgotPassEmail") ? Cookies.get("forgotPassEmail") : ""}
          "
          <button onClick={() => navigate("/forgot-password")}>
            <img src={EditBackIcon} alt="Back" />
          </button>
        </h6>
      </div>

      <Formik
        initialValues={{
          otp: "",
          password: "",
          confirm_password: "",
          passwordShow: false,
          confirm_passwordShow: false,
          otpShow: false,
        }}
        validationSchema={Yup.object().shape({
          password: Yup.string()
            .required(t("formErrorMsg.passwordRequired"))
            .matches(
              /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
              t("formErrorMsg.passwordMatchesValidation")
            ),
          confirm_password: Yup.string()
            .required(t("formErrorMsg.confirmPasswordRequired"))
            .oneOf(
              [Yup.ref("password"), null],
              t("formErrorMsg.confirmPasswordMatchValidation")
            ),
          otp: Yup.string()
            .required("OTP is required")
            .min(6, "Please Enter Valid OTP"),
        })}
        onSubmit={(values) => {
          // same shape as initial values
          resetPasswordWithOtpAPI(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>{t("formLabel.OTP")}</label>

              <OtpComponent
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                handleBlur={handleBlur}
              />
              {errors.otp && touched.otp && (
                <div className="error-msg">{errors.otp}</div>
              )}
              <div className="receiveCode">
                {t("resetPasswordPage.resendOTPText")}

                <button
                  type="button"
                  disabled={disableResendOTP}
                  className={disableResendOTP && "cursor-not-allowed"}
                  onClick={(e) => forgotPasswordOTPAPI(e)}
                >
                  {t("resetPasswordPage.resendOTPButton")}
                </button>
                <OtpTimer
                  initialMinute={0}
                  initialSeconds={time}
                  setDisableResendOTP={setDisableResendOTP}
                  showTimer={showTimer}
                />
              </div>
            </div>

            <div className="form-group">
              <label>{t("formLabel.passwordLabel")}</label>
              <div className="inputPossion">
                <img
                  onClick={(e) =>
                    setFieldValue("passwordShow", !values.passwordShow)
                  }
                  src={values.passwordShow ? OffEyelIcon : EyelIcon}
                  alt="Eye"
                  className="eyeHide"
                />
                <input
                  type={values.passwordShow ? "text" : "password"}
                  placeholder={t("formPlaceholder.passwordPlaceholder")}
                  className="form-control form-controlPass"
                  name="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                />
                <img src={KeyIcon} alt="Key" />
              </div>
              {errors.password && touched.password && (
                <div className="error-msg">{errors.password}</div>
              )}
            </div>

            <div className="form-group">
              <label>{t("formLabel.confirmPasswordLabel")}</label>
              <div className="inputPossion">
                <img
                  onClick={(e) =>
                    setFieldValue(
                      "confirm_passwordShow",
                      !values.confirm_passwordShow
                    )
                  }
                  src={values.confirm_passwordShow ? OffEyelIcon : EyelIcon}
                  alt="Eye"
                  className="eyeHide"
                />
                <input
                  type={values.confirm_passwordShow ? "text" : "password"}
                  placeholder={t("formPlaceholder.confirmPasswordPlaceholder")}
                  className="form-control form-controlPass"
                  name="confirm_password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.confirm_password}
                />
                <img src={KeyIcon} alt="Key" />
              </div>
              {errors.confirm_password && touched.confirm_password && (
                <div className="error-msg">{errors.confirm_password}</div>
              )}
            </div>

            <div className="formButtonBx">
              <button className="gradientBG submitBtN" type="submit">
                {t("resetPasswordPage.updatePasswordButton")}
              </button>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};
