import React from "react";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import * as Yup from "yup";

import { Header } from "../../../components/common/Header";
import { SideBar } from "../../../components/common/SideBar";

import {
  EyelIcon,
  KeyIcon,
  OffEyelIcon,
  PreviousIcon,
} from "../../../assets/image-path";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { generateNewToken, setLoading } from "../../../store/auth/AuthSlice";
import { addSubAdmin } from "../../../store/dashboard/DashboardSlice";

export const AddSubAdmin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(); // Use the translation hook

  //   Add sub Admin
  const addSubAdminAPI = (values) => {
    const params = {
      fullName: values.fullName,
      email: values.email,
      password: values.password,
    };
    dispatch(setLoading(true));
    dispatch(addSubAdmin(params)).then((response) => {
      dispatch(setLoading(false));
      if (response.payload === "token_expired") {
        dispatch(generateNewToken()).then((res) => {
          if (res.payload.success) {
            addSubAdminAPI(values);
          }
        });
      } else if (response.payload.success) {
        navigate(-1);
      }
    });
  };

  return (
    <>
      <div id="body_wapper" className="mainBody">
        <div className="wapper">
          <SideBar />
          <div className="main-container">
            <Header headingName={""} />
            <div className="containerBody">
              <div className="mainSelectBox">
                <div className="headingPart">
                  <h2>
                    <ul>
                      <li>{t("subAdminManagement.title")}</li>
                      <li> {">"} </li>
                      <li>{t("subAdminManagement.addSubAdmin")}</li>
                    </ul>
                  </h2>
                  <div className="rightMarginPT">
                    <button className="backbTun" onClick={() => navigate(-1)}>
                      <img src={PreviousIcon} alt="Back" /> {t("buttons.back")}
                    </button>
                  </div>
                </div>

                <div className="basicContainer">
                  <Formik
                    initialValues={{
                      fullName: "",
                      email: "",
                      password: "",
                      confirm_password: "",
                      confirm_passwordShow: false,
                      passwordShow: false,
                    }}
                    validationSchema={Yup.object().shape({
                      fullName: Yup.string().required(
                        t("formErrorMsg.fullNameRequired")
                      ),
                      email: Yup.string()
                        .email(t("formErrorMsg.emailInvalid"))
                        .required(t("formErrorMsg.emailRequired")),
                      password: Yup.string()
                        .required(t("formErrorMsg.passwordRequired"))
                        .matches(
                          /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                          t("formErrorMsg.passwordMatchesValidation")
                        ),
                      confirm_password: Yup.string()
                        .required(t("formErrorMsg.confirmPasswordRequired"))
                        .oneOf(
                          [Yup.ref("password"), null],
                          t("formErrorMsg.confirmPasswordMatchValidation")
                        ),
                    })}
                    onSubmit={(values) => {
                      addSubAdminAPI(values);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      setFieldValue,
                    }) => (
                      <form
                        className="basicInformation"
                        onSubmit={handleSubmit}
                      >
                        <h2> {t("addSubAdminPage.formTitle")}</h2>
                        <div className="row cusWidthCol-MD">
                          <div className="col-xl-6 col-lg-6">
                            <div className="form-group">
                              <label>{t("formLabel.fullNameLabel")}</label>
                              <input
                                type="text"
                                placeholder={t(
                                  "formPlaceholder.fullNamePlaceholder"
                                )}
                                className="form-control"
                                name="fullName"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.fullName}
                              />
                              {errors.fullName && touched.fullName && (
                                <div className="error-msg">
                                  {errors.fullName}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 ">
                            <div className="form-group">
                              <label>{t("formLabel.emailLabel")}</label>
                              <input
                                type="text"
                                placeholder={t(
                                  "formPlaceholder.emailPlaceholder"
                                )}
                                className="form-control"
                                name="email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                              />
                              {errors.email && touched.email && (
                                <div className="error-msg">{errors.email}</div>
                              )}
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 ">
                            <div className="form-group">
                              <label>{t("formLabel.passwordLabel")}</label>
                              <div className="inputPossion">
                                <img
                                  onClick={(e) =>
                                    setFieldValue(
                                      "passwordShow",
                                      !values.passwordShow
                                    )
                                  }
                                  src={
                                    values.passwordShow ? OffEyelIcon : EyelIcon
                                  }
                                  alt="Eye"
                                  className="eyeHide"
                                />
                                <input
                                  type={
                                    values.passwordShow ? "text" : "password"
                                  }
                                  placeholder={t(
                                    "formPlaceholder.passwordPlaceholder"
                                  )}
                                  className="form-control passwordfield"
                                  name="password"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.password}
                                />
                                <img src={KeyIcon} alt="Key" />
                              </div>

                              {errors.password && touched.password && (
                                <div className="error-msg">
                                  {errors.password}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 ">
                            <div className="form-group">
                              <label>
                                {t("formLabel.confirmPasswordLabel")}
                              </label>
                              <div className="inputPossion">
                                <img
                                  onClick={(e) =>
                                    setFieldValue(
                                      "confirm_passwordShow",
                                      !values.confirm_passwordShow
                                    )
                                  }
                                  src={
                                    values.confirm_passwordShow
                                      ? OffEyelIcon
                                      : EyelIcon
                                  }
                                  alt="Eye"
                                  className="eyeHide"
                                />
                                <input
                                  type={
                                    values.confirm_passwordShow
                                      ? "text"
                                      : "password"
                                  }
                                  placeholder={t(
                                    "formPlaceholder.confirmPasswordPlaceholder"
                                  )}
                                  className="form-control passwordfield"
                                  name="confirm_password"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.confirm_password}
                                />
                                <img src={KeyIcon} alt="Key" />
                              </div>

                              {errors.confirm_password &&
                                touched.confirm_password && (
                                  <div className="error-msg">
                                    {errors.confirm_password}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="col-xl-4 col-lg-5">
                            <div className="form-groupAdd">
                              <button className="gradientBG" type="submit">
                                {t("buttons.add")}
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
