import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { Header } from "../../../components/common/Header";
import { SideBar } from "../../../components/common/SideBar";

import { generateNewToken, setLoading } from "../../../store/auth/AuthSlice";
import {
  getPeakBlipSetting,
  updatePeakBlipSetting,
} from "../../../store/dashboard/DashboardSlice";

export const Setting = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [peakBlipObj, setPeakBlipObj] = useState({
    totalBlipViewCountInDay: 0,
  });

  useEffect(() => {
    getPeakBlipSettingAPI();
    // eslint-disable-next-line
  }, []);
  const getPeakBlipSettingAPI = () => {
    dispatch(setLoading(true));
    dispatch(getPeakBlipSetting()).then((response) => {
      dispatch(setLoading(false));
      if (response.payload === "token_expired") {
        dispatch(generateNewToken()).then((res) => {
          if (res.payload.success) {
            getPeakBlipSettingAPI();
          }
        });
      } else if (response.payload?.success) {
        setPeakBlipObj(response.payload?.data);
      }
    });
  };

  const updatePeakBlipSettingAPI = (params) => {
    dispatch(setLoading(true));
    dispatch(updatePeakBlipSetting(params)).then((response) => {
      dispatch(setLoading(false));
      if (response.payload === "token_expired") {
        dispatch(generateNewToken()).then((res) => {
          if (res.payload.success) {
            updatePeakBlipSettingAPI(params);
          }
        });
      } else if (response.payload?.success) {
        getPeakBlipSettingAPI();
      }
    });
  };
  const updatePickCount = () => {
    const params = {
      id: peakBlipObj?._id,
      totalBlipViewCountInDay: parseInt(peakBlipObj?.totalBlipViewCountInDay),
    };
    updatePeakBlipSettingAPI(params);
  };
  return (
    <>
      <div id="body_wapper" className="mainBody">
        <div className="wapper">
          <SideBar />
          <div className="main-container">
            <Header headingName={""} />
            <div className="containerBody">
              <div className="mainSelectBox">
                <div className="headingPart">
                  <h2>{t("settingPage.title")}</h2>
                </div>

                <div className="basicContainer">
                  <div className="settingPage">
                    <div className="row align-items-center">
                      <div className="col-lg-7 col-md-5 col-sm-3">
                        <label>{t("settingPage.peakLabel")} :</label>
                      </div>
                      <div className="col-lg-5 col-md-7 col-sm-9">
                        <div className="row">
                          <div className="col-md-7 col-sm-7">
                            <div className="form-group">
                              <input
                                placeholder="Enter Peak/Day"
                                type="text"
                                className="form-control"
                                maxLength="3"
                                onChange={(e) => {
                                  const s = e.currentTarget?.value
                                    ?.replace(/[^\d]/g, "")
                                    ?.replace(/^0+/, "");
                                  setPeakBlipObj({
                                    ...peakBlipObj,
                                    totalBlipViewCountInDay: s,
                                    // ?.replace(/^0+(?=[1-9])/, "")
                                  });
                                }}
                                value={peakBlipObj?.totalBlipViewCountInDay}
                                style={{
                                  borderColor:
                                    peakBlipObj?.totalBlipViewCountInDay < 1 &&
                                    "red",
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-md-5 col-sm-5">
                            <button
                              disabled={
                                peakBlipObj?.totalBlipViewCountInDay < 1
                              }
                              className="gradientBG"
                              type="button"
                              onClick={(e) => updatePickCount()}
                            >
                              Update
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
