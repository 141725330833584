import axios from "axios";

// import storage from "redux-persist/lib/storage";
import {
  GET_DASHBOARD_DATA_API,
  ADD_SUB_ADMIN_API,
  GET_SUB_ADMINS_API,
  UPDATE_SUB_ADMIN_BY_ID_API,
  DELETE_SUB_ADMIN_BY_ID_API,
  GET_SUB_ADMIN_BY_ID_API,
  UPDATE_SUB_ADMIN_STATUS_BY_ID_API,
  CREATE_CONTENT_MANAGEMENT_API,
  UPDATE_CONTENT_MANAGEMENT_API,
  GET_CONTENT_MANAGEMENT_API,
  GET_BLIPERS_API,
  GET_BLIPER_BY_ID_API,
  UPDATE_BLIPER_STATUS_BY_ID_API,
  DELETE_BLIPER_BY_ID_API,
  UPDATE_BLIPER_VERIFICATION_STATUS_BY_ID_API,
  GET_BLIPERS_VERIFICATION_REQUEST_API,
  ADD_SYSTEM_BLIP_COIN_API,
  DELETE_SYSTEM_BLIP_COIN_BY_ID_API,
  UPDATE_SYSTEM_BLIP_COIN_STATUS_BY_ID_API,
  UPDATE_SYSTEM_BLIP_COIN_BY_ID_API,
  GET_SYSTEM_BLIP_COIN_API,
  GET_SYSTEM_BLIP_COIN_BY_ID_API,
  UPDATE_BLIPER_PROFILE_IMAGE_BY_ID_API,
  GET_REPORTED_BLIPERS_API,
  BLOCK_REPORTED_BLIPER_API,
  GET_REPORTED_BLIPER_REPORTS_API,
  GET_REPORTED_BLIPS_API,
  GET_REPORTED_BLIP_REPORTS_API,
  BLOCK_REPORTED_BLIP_API,
  GET_BLIPS_BY_BLIPER_ID_API,
  UPDATE_BLIP_STATUS_BY_ID_API,
  GET_BLIPERS_VERIFICATION_HISTORY_API,
  GET_PEAK_BLIP_SETTING_API,
  UPDATE_PEAK_BLIP_SETTING_API,
  UPDATE_BLIPER_API,
  UPDATE_AS_ANCHOR_FLAG_API,
  GET_ANCHOR_BLIPS_API,
} from "./../ApiUrls";

// Get Dashboard Data
const getDashboardData = async (data) => {
  const accessToken = localStorage.getItem("accessToken");
  const config = {
    headers: {
      Authorization: "Bearer " + accessToken,
    },
  };
  const response = await axios.get(GET_DASHBOARD_DATA_API, config);
  return response.data;
};

// Add Sub Admin
const addSubAdmin = async (data) => {
  const accessToken = localStorage.getItem("accessToken");
  const config = {
    headers: {
      Authorization: "Bearer " + accessToken,
    },
  };
  const response = await axios.post(ADD_SUB_ADMIN_API, data, config);
  return response.data;
};

// Get Sub Admins List
const getSubAdmins = async (data) => {
  const accessToken = localStorage.getItem("accessToken");
  const config = {
    headers: {
      Authorization: "Bearer " + accessToken,
    },
  };
  const URL =
    GET_SUB_ADMINS_API +
    "?page=" +
    data.currentPage +
    "&limit=" +
    data.pageLimit +
    "&search=" +
    data.search +
    "&status=" +
    data.statusFilter;
  const response = await axios.get(URL, config);
  return response.data;
};

// Get Sub Admin By Id
const getSubAdminById = async (id) => {
  const accessToken = localStorage.getItem("accessToken");
  const config = {
    headers: {
      Authorization: "Bearer " + accessToken,
    },
  };
  const URL = GET_SUB_ADMIN_BY_ID_API + id;
  const response = await axios.get(URL, config);
  return response.data;
};

// Update Sub Admin By ID
const updateSubAdminById = async (data) => {
  const accessToken = localStorage.getItem("accessToken");
  const config = {
    headers: {
      Authorization: "Bearer " + accessToken,
    },
  };
  const URL = UPDATE_SUB_ADMIN_BY_ID_API + data.id;
  const response = await axios.put(URL, data, config);
  return response.data;
};

// Update Sub Admin Status By ID
const updateSubAdminStatusById = async (data) => {
  const accessToken = localStorage.getItem("accessToken");
  const config = {
    headers: {
      Authorization: "Bearer " + accessToken,
    },
  };
  const URL = UPDATE_SUB_ADMIN_STATUS_BY_ID_API + data.id;
  const response = await axios.put(URL, data, config);
  return response.data;
};

// Delete Sub Admin By ID
const deleteSubAdminById = async (id) => {
  const accessToken = localStorage.getItem("accessToken");
  const config = {
    headers: {
      Authorization: "Bearer " + accessToken,
    },
  };
  const URL = DELETE_SUB_ADMIN_BY_ID_API + id;
  const response = await axios.delete(URL, config);
  return response.data;
};

// Create Content Management
const createContentManagement = async (data) => {
  const accessToken = localStorage.getItem("accessToken");
  const config = {
    headers: {
      Authorization: "Bearer " + accessToken,
    },
  };
  const URL = CREATE_CONTENT_MANAGEMENT_API;
  const response = await axios.post(URL, data, config);
  return response.data;
};

// Update Content Management
const updateContentManagement = async (data) => {
  const accessToken = localStorage.getItem("accessToken");
  const config = {
    headers: {
      Authorization: "Bearer " + accessToken,
    },
  };
  const URL = UPDATE_CONTENT_MANAGEMENT_API + data.id;
  const response = await axios.put(URL, data, config);
  return response.data;
};

// Get Content Management
const getContentManagement = async () => {
  const accessToken = localStorage.getItem("accessToken");
  const config = {
    headers: {
      Authorization: "Bearer " + accessToken,
    },
  };
  const URL = GET_CONTENT_MANAGEMENT_API;
  const response = await axios.get(URL, config);
  return response.data;
};

// Get Blipers
const getBlipers = async (data) => {
  const accessToken = localStorage.getItem("accessToken");
  const config = {
    headers: {
      Authorization: "Bearer " + accessToken,
    },
  };
  const URL =
    GET_BLIPERS_API +
    "?page=" +
    data.currentPage +
    "&limit=" +
    data.pageLimit +
    "&search=" +
    data.search +
    "&status=" +
    data.statusFilter +
    "&verifyStatus=" +
    data.verifyFilter;
  const response = await axios.get(URL, config);
  return response.data;
};

// Update Bliper
const updateBliper = async (data) => {
  const accessToken = localStorage.getItem("accessToken");
  const config = {
    headers: {
      Authorization: "Bearer " + accessToken,
    },
  };
  const URL = UPDATE_BLIPER_API;
  const response = await axios.post(URL, data, config);
  return response.data;
};

// Get Bliper By Id
const getBliperById = async (id) => {
  const accessToken = localStorage.getItem("accessToken");
  const config = {
    headers: {
      Authorization: "Bearer " + accessToken,
    },
  };
  const URL = GET_BLIPER_BY_ID_API + id;
  const response = await axios.get(URL, config);
  return response.data;
};

// Update Bliper Status By ID
const updateBliperStatusById = async (data) => {
  const accessToken = localStorage.getItem("accessToken");
  const config = {
    headers: {
      Authorization: "Bearer " + accessToken,
    },
  };
  const URL = UPDATE_BLIPER_STATUS_BY_ID_API + data.id;
  const response = await axios.put(URL, data, config);
  return response.data;
};

// Update Bliper Verification Status By ID
const updateBliperVerificationStatusById = async (data) => {
  const accessToken = localStorage.getItem("accessToken");
  const config = {
    headers: {
      Authorization: "Bearer " + accessToken,
    },
  };
  const URL = UPDATE_BLIPER_VERIFICATION_STATUS_BY_ID_API + data.id;
  const response = await axios.put(URL, data, config);
  return response.data;
};

// Delete Bliper By ID
const deleteBliperById = async (id) => {
  const accessToken = localStorage.getItem("accessToken");
  const config = {
    headers: {
      Authorization: "Bearer " + accessToken,
    },
  };
  const URL = DELETE_BLIPER_BY_ID_API + id;
  const response = await axios.delete(URL, config);
  return response.data;
};

// Update Bliper Profile Image By ID
const updateBliperProfileImageById = async (data) => {
  const accessToken = localStorage.getItem("accessToken");
  const config = {
    headers: {
      Authorization: "Bearer " + accessToken,
    },
  };
  const URL = UPDATE_BLIPER_PROFILE_IMAGE_BY_ID_API + data.id;
  const response = await axios.put(URL, data, config);
  return response.data;
};

// Get Blips By BliperId
const getBlipsByBliperId = async (data) => {
  const accessToken = localStorage.getItem("accessToken");
  const config = {
    headers: {
      Authorization: "Bearer " + accessToken,
    },
  };
  const URL =
    GET_BLIPS_BY_BLIPER_ID_API +
    data.id +
    "?page=" +
    data.currentPage +
    "&limit=" +
    data.pageLimit +
    "&search=" +
    data.search +
    "&filter_by_anchor=" +
    data?.filter_by_anchor +
    "&fromDate=" +
    data.dateRange.startDate +
    "&toDate=" +
    data.dateRange.endDate;
  const response = await axios.get(URL, config);
  return response.data;
};

// Update Blip Status By Id
const updateBlipStatusById = async (data) => {
  const accessToken = localStorage.getItem("accessToken");
  const config = {
    headers: {
      Authorization: "Bearer " + accessToken,
    },
  };
  const URL = UPDATE_BLIP_STATUS_BY_ID_API + data.id;
  const response = await axios.put(URL, data, config);
  return response.data;
};

// Add System Blip Coins With Viedo & Place
const addSystemBlipCoin = async (data) => {
  const accessToken = localStorage.getItem("accessToken");
  const config = {
    headers: {
      Authorization: "Bearer " + accessToken,
    },
  };
  const response = await axios.post(ADD_SYSTEM_BLIP_COIN_API, data, config);
  return response.data;
};

// Get System Blip Coins With Viedo & Place
const getSystemBlipCoin = async (data) => {
  const accessToken = localStorage.getItem("accessToken");
  const config = {
    headers: {
      Authorization: "Bearer " + accessToken,
    },
  };
  const URL =
    GET_SYSTEM_BLIP_COIN_API +
    "?page=" +
    data.currentPage +
    "&limit=" +
    data.pageLimit +
    "&search=" +
    data.search +
    "&status=" +
    data.statusFilter +
    "&coinType=" +
    data.coinType;
  const response = await axios.get(URL, config);
  return response.data;
};

// Get System Blip Coin By Id With Viedo & Place
const getSystemBlipCoinById = async (id) => {
  const accessToken = localStorage.getItem("accessToken");
  const config = {
    headers: {
      Authorization: "Bearer " + accessToken,
    },
  };
  const URL = GET_SYSTEM_BLIP_COIN_BY_ID_API + id;
  const response = await axios.get(URL, config);
  return response.data;
};

// Update System Blip Coin By Id With Viedo & Place
const updateSystemBlipCoinById = async (data) => {
  const accessToken = localStorage.getItem("accessToken");
  const config = {
    headers: {
      Authorization: "Bearer " + accessToken,
    },
  };
  const URL = UPDATE_SYSTEM_BLIP_COIN_BY_ID_API + data.id;
  const response = await axios.put(URL, data, config);
  return response.data;
};

// Update Status Of System Blip Coin By Id With Viedo & Place
const updateSystemBlipCoinStatusById = async (data) => {
  const accessToken = localStorage.getItem("accessToken");
  const config = {
    headers: {
      Authorization: "Bearer " + accessToken,
    },
  };
  const URL = UPDATE_SYSTEM_BLIP_COIN_STATUS_BY_ID_API + data.id;
  const response = await axios.put(URL, data, config);
  return response.data;
};

// Delete System Blip Coin By Id With Viedo & Place
const deleteSystemBlipCoinById = async (id) => {
  const accessToken = localStorage.getItem("accessToken");
  const config = {
    headers: {
      Authorization: "Bearer " + accessToken,
    },
  };
  const URL = DELETE_SYSTEM_BLIP_COIN_BY_ID_API + id;
  const response = await axios.delete(URL, config);
  return response.data;
};
// Get Blipers Verification Request
const getBlipersVerificationRequest = async (data) => {
  const accessToken = localStorage.getItem("accessToken");
  const config = {
    headers: {
      Authorization: "Bearer " + accessToken,
    },
  };
  const URL =
    GET_BLIPERS_VERIFICATION_REQUEST_API +
    "?page=" +
    data.currentPage +
    "&limit=" +
    data.pageLimit +
    "&search=" +
    data.search +
    "&verifyStatus=" +
    data.verifyFilter;
  const response = await axios.get(URL, config);
  return response.data;
};

// Get Blipers Verification History
const getBlipersVerificationHistory = async (data) => {
  const accessToken = localStorage.getItem("accessToken");
  const config = {
    headers: {
      Authorization: "Bearer " + accessToken,
    },
  };
  const URL = GET_BLIPERS_VERIFICATION_HISTORY_API + data.id;
  const response = await axios.get(URL, config);
  return response.data;
};

// Get Reported Blipers
const getReportedBlipers = async (data) => {
  const accessToken = localStorage.getItem("accessToken");
  const config = {
    headers: {
      Authorization: "Bearer " + accessToken,
    },
  };
  const URL =
    GET_REPORTED_BLIPERS_API +
    "?page=" +
    data.currentPage +
    "&limit=" +
    data.pageLimit +
    "&search=" +
    data.search +
    "&status=" +
    data.statusFilter;
  const response = await axios.get(URL, config);
  return response.data;
};

// get Bliper Reports
const getBliperReportsById = async (data) => {
  const accessToken = localStorage.getItem("accessToken");
  const config = {
    headers: {
      Authorization: "Bearer " + accessToken,
    },
  };
  const URL =
    GET_REPORTED_BLIPER_REPORTS_API +
    "?page=" +
    data.currentPage +
    "&limit=" +
    data.pageLimit;
  const response = await axios.post(URL, data, config);
  return response.data;
};

// Block Reported Bliper
const blockReportedBliper = async (data) => {
  const accessToken = localStorage.getItem("accessToken");
  const config = {
    headers: {
      Authorization: "Bearer " + accessToken,
    },
  };
  const URL = BLOCK_REPORTED_BLIPER_API;
  const response = await axios.post(URL, data, config);
  return response.data;
};

// Get Reported Blips
const getReportedBlips = async (data) => {
  const accessToken = localStorage.getItem("accessToken");
  const config = {
    headers: {
      Authorization: "Bearer " + accessToken,
    },
  };
  const URL =
    GET_REPORTED_BLIPS_API +
    "?page=" +
    data.currentPage +
    "&limit=" +
    data.pageLimit +
    "&search=" +
    data.search +
    "&status=" +
    data.statusFilter;
  const response = await axios.get(URL, config);
  return response.data;
};

// get Blip Reports
const getBlipReportsById = async (data) => {
  const accessToken = localStorage.getItem("accessToken");
  const config = {
    headers: {
      Authorization: "Bearer " + accessToken,
    },
  };
  const URL =
    GET_REPORTED_BLIP_REPORTS_API +
    "?page=" +
    data.currentPage +
    "&limit=" +
    data.pageLimit;
  const response = await axios.post(URL, data, config);
  return response.data;
};

// Block Reported Blip
const blockReportedBlip = async (data) => {
  const accessToken = localStorage.getItem("accessToken");
  const config = {
    headers: {
      Authorization: "Bearer " + accessToken,
    },
  };
  const URL = BLOCK_REPORTED_BLIP_API;
  const response = await axios.post(URL, data, config);
  return response.data;
};

// get Peak Blip Setting
const getPeakBlipSetting = async (data) => {
  const accessToken = localStorage.getItem("accessToken");
  const config = {
    headers: {
      Authorization: "Bearer " + accessToken,
    },
  };
  const URL = GET_PEAK_BLIP_SETTING_API;
  const response = await axios.get(URL, config);
  return response.data;
};

// Update Peak Blip Setting
const updatePeakBlipSetting = async (data) => {
  const accessToken = localStorage.getItem("accessToken");
  const config = {
    headers: {
      Authorization: "Bearer " + accessToken,
    },
  };
  const URL = UPDATE_PEAK_BLIP_SETTING_API;
  const response = await axios.post(URL, data, config);
  return response.data;
};
// update AsAnchor Flag
const updateAsAnchorFlag = async (data) => {
  const accessToken = localStorage.getItem("accessToken");
  const config = {
    headers: {
      Authorization: "Bearer " + accessToken,
    },
  };
  const URL = UPDATE_AS_ANCHOR_FLAG_API + data.id;
  const response = await axios.put(URL, data, config);
  return response.data;
};

// get Anchor Blips
const getAnchorBlips = async (data) => {
  const accessToken = localStorage.getItem("accessToken");
  const config = {
    headers: {
      Authorization: "Bearer " + accessToken,
    },
  };

  const URL =
    GET_ANCHOR_BLIPS_API +
    "?page=" +
    data.currentPage +
    "&limit=" +
    data.pageLimit +
    "&search=" +
    data.search +
    "&status=" +
    data.statusFilter;
  const response = await axios.get(URL, config);
  return response.data;
};
const dashboardService = {
  getDashboardData,
  addSubAdmin,
  getSubAdmins,
  getSubAdminById,
  updateSubAdminById,
  updateSubAdminStatusById,
  deleteSubAdminById,
  getContentManagement,
  createContentManagement,
  updateContentManagement,
  getBlipers,
  updateBliper,
  getBliperById,
  updateBliperStatusById,
  updateBliperVerificationStatusById,
  deleteBliperById,
  updateBliperProfileImageById,
  getBlipsByBliperId,
  updateBlipStatusById,
  getBlipersVerificationRequest,
  getBlipersVerificationHistory,
  addSystemBlipCoin,
  getSystemBlipCoin,
  getSystemBlipCoinById,
  updateSystemBlipCoinById,
  updateSystemBlipCoinStatusById,
  deleteSystemBlipCoinById,
  getReportedBlipers,
  getBliperReportsById,
  blockReportedBliper,
  getReportedBlips,
  getBlipReportsById,
  blockReportedBlip,
  getPeakBlipSetting,
  updatePeakBlipSetting,
  updateAsAnchorFlag,
  getAnchorBlips,
};

export default dashboardService;
