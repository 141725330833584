import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import dashboardService from "./DashboardService";

const initialState = {
  isSuccess: false,
  isError: false,
  isLoading: false,
  message: "",
  sideBarShow: true,
  bliperStatusKey: "",
  veri_StatusKey: "",
  blipCoinStatusKey: "",
  dashboardData: {},
  subAdmins: { data: [], count: 0 },
  blipers: { data: [], count: 0 },
  subAdminDetailById: {},
  bliperDetailById: {},
  contentManagementLinks: [],
  systemBlipCoins: { data: [], count: 0 },
  systemBlipCoinDetailById: {},
  blipersVerificationRequests: { data: [], count: 0 },
  reportedBlipers: { data: [], count: 0 },
  bliperReportsById: { data: [], count: 0 },
  reportedBlips: { data: [], count: 0 },
  blipReportsById: { data: [], count: 0 },
  blipsByBliperId: {
    data: [],
    count: 0,
  },
  bliperIdForViewBlip: "",
  placeBlips: { location: "", blip: [] },
  anchorBlips: { data: [], count: 0 },
};

// Get Dashboard Data
export const getDashboardData = createAsyncThunk(
  "Get Dashboard Data",
  async (data, thunkAPI) => {
    try {
      const response = await dashboardService.getDashboardData(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Add Sub Admin
export const addSubAdmin = createAsyncThunk(
  "Add Sub Admin",
  async (data, thunkAPI) => {
    try {
      const response = await dashboardService.addSubAdmin(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get Sub Admins
export const getSubAdmins = createAsyncThunk(
  "Get Sub Admins",
  async (data, thunkAPI) => {
    try {
      const response = await dashboardService.getSubAdmins(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Get Sub Admin Info By Id
export const getSubAdminById = createAsyncThunk(
  "Get Sub Admin By Id",
  async (data, thunkAPI) => {
    try {
      const response = await dashboardService.getSubAdminById(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update Sub Admin By Id
export const updateSubAdminById = createAsyncThunk(
  "Update Sub Admin By Id",
  async (data, thunkAPI) => {
    try {
      const response = await dashboardService.updateSubAdminById(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update Sub Admin Status By Id
export const updateSubAdminStatusById = createAsyncThunk(
  "Update Sub Admin Status By Id",
  async (data, thunkAPI) => {
    try {
      const response = await dashboardService.updateSubAdminStatusById(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete Sub Admin By Id
export const deleteSubAdminById = createAsyncThunk(
  "Delete Sub Admin By Id",
  async (data, thunkAPI) => {
    try {
      const response = await dashboardService.deleteSubAdminById(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Create Content Management
export const createContentManagement = createAsyncThunk(
  "Create Content Management",
  async (data, thunkAPI) => {
    try {
      const response = await dashboardService.createContentManagement(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update Content Management
export const updateContentManagement = createAsyncThunk(
  "Update Content Management",
  async (data, thunkAPI) => {
    try {
      const response = await dashboardService.updateContentManagement(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// get Content Management
export const getContentManagement = createAsyncThunk(
  "Get Content Management",
  async (data, thunkAPI) => {
    try {
      const response = await dashboardService.getContentManagement(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// get Blipers
export const getBlipers = createAsyncThunk(
  "Get Blipers",
  async (data, thunkAPI) => {
    try {
      const response = await dashboardService.getBlipers(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Update Bliper
export const updateBliper = createAsyncThunk(
  "Update Bliper",
  async (data, thunkAPI) => {
    try {
      const response = await dashboardService.updateBliper(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get Bliper Info By Id
export const getBliperById = createAsyncThunk(
  "Get Bliper By Id",
  async (data, thunkAPI) => {
    try {
      const response = await dashboardService.getBliperById(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update Bliper Status By Id
export const updateBliperStatusById = createAsyncThunk(
  "Update Bliper Status By Id",
  async (data, thunkAPI) => {
    try {
      const response = await dashboardService.updateBliperStatusById(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete Bliper By Id
export const deleteBliperById = createAsyncThunk(
  "Delete Bliper By Id",
  async (data, thunkAPI) => {
    try {
      const response = await dashboardService.deleteBliperById(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update Bliper Profile Image By Id
export const updateBliperProfileImageById = createAsyncThunk(
  "Update Bliper Profile Image By Id",
  async (data, thunkAPI) => {
    try {
      const response = await dashboardService.updateBliperProfileImageById(
        data
      );
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get Blips By Bliper Id
export const getBlipsByBliperId = createAsyncThunk(
  "Get Blips By Bliper Id",
  async (data, thunkAPI) => {
    try {
      const response = await dashboardService.getBlipsByBliperId(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update Blip Status By Id
export const updateBlipStatusById = createAsyncThunk(
  "Update Blip Status By Id",
  async (data, thunkAPI) => {
    try {
      const response = await dashboardService.updateBlipStatusById(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Add System Blip Coins With Viedo & Place
export const addSystemBlipCoin = createAsyncThunk(
  " Add System Blip Coins",
  async (data, thunkAPI) => {
    try {
      const response = await dashboardService.addSystemBlipCoin(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get System Blip Coins With Viedo & Place
export const getSystemBlipCoin = createAsyncThunk(
  "Get System Blip Coins",
  async (data, thunkAPI) => {
    try {
      const response = await dashboardService.getSystemBlipCoin(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Get System Blip Coin By Id With Viedo & Place
export const getSystemBlipCoinById = createAsyncThunk(
  "Get System Blip Coin By Id",
  async (data, thunkAPI) => {
    try {
      const response = await dashboardService.getSystemBlipCoinById(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Update System Blip Coin By Id With Viedo & Place
export const updateSystemBlipCoinById = createAsyncThunk(
  "Update System Blip Coin By Id",
  async (data, thunkAPI) => {
    try {
      const response = await dashboardService.updateSystemBlipCoinById(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Update System Blip Coin Status By Id With Viedo & Place
export const updateSystemBlipCoinStatusById = createAsyncThunk(
  "Update System Blip Coin Status By Id",
  async (data, thunkAPI) => {
    try {
      const response = await dashboardService.updateSystemBlipCoinStatusById(
        data
      );
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Delete System Blip Coin By Id With Viedo & Place
export const deleteSystemBlipCoinById = createAsyncThunk(
  "Delete System Blip Coin By Id",
  async (data, thunkAPI) => {
    try {
      const response = await dashboardService.deleteSystemBlipCoinById(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get Blipers Verification Request
export const getBlipersVerificationRequest = createAsyncThunk(
  "Get Blipers Verification Request",
  async (data, thunkAPI) => {
    try {
      const response = await dashboardService.getBlipersVerificationRequest(
        data
      );
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update Verification Status Bliper By Id
export const updateBliperVerificationStatusById = createAsyncThunk(
  "Update Bliper Verification Status By Id",
  async (data, thunkAPI) => {
    try {
      const response =
        await dashboardService.updateBliperVerificationStatusById(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get Blipers Verification History
export const getBlipersVerificationHistory = createAsyncThunk(
  "Get Blipers Verification History",
  async (data, thunkAPI) => {
    try {
      const response = await dashboardService.getBlipersVerificationHistory(
        data
      );
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get Reported Blipers
export const getReportedBlipers = createAsyncThunk(
  "Get Reported Blipers",
  async (data, thunkAPI) => {
    try {
      const response = await dashboardService.getReportedBlipers(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Block Reported Bliper
export const blockReportedBliper = createAsyncThunk(
  "Block Reported Bliper",
  async (data, thunkAPI) => {
    try {
      const response = await dashboardService.blockReportedBliper(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get Bliper Block Request Details By Id
export const getBliperReportsById = createAsyncThunk(
  "Get Bliper Reports",
  async (data, thunkAPI) => {
    try {
      const response = await dashboardService.getBliperReportsById(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//

// Get Reported Blips
export const getReportedBlips = createAsyncThunk(
  "Get Reported Blips",
  async (data, thunkAPI) => {
    try {
      const response = await dashboardService.getReportedBlips(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Block Reported Blip
export const blockReportedBlip = createAsyncThunk(
  "Block Reported Blip",
  async (data, thunkAPI) => {
    try {
      const response = await dashboardService.blockReportedBlip(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get Blip Reports By Id
export const getBlipReportsById = createAsyncThunk(
  "Get Blip Report",
  async (data, thunkAPI) => {
    try {
      const response = await dashboardService.getBlipReportsById(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get PeakBlip Setting
export const getPeakBlipSetting = createAsyncThunk(
  "Get PeakBlip Setting",
  async (data, thunkAPI) => {
    try {
      const response = await dashboardService.getPeakBlipSetting(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Update PeakBlip Setting
export const updatePeakBlipSetting = createAsyncThunk(
  "Update PeakBlip Setting",
  async (data, thunkAPI) => {
    try {
      const response = await dashboardService.updatePeakBlipSetting(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update As Anchor Flag
export const updateAsAnchorFlag = createAsyncThunk(
  "Update As Anchor Flag",
  async (data, thunkAPI) => {
    try {
      const response = await dashboardService.updateAsAnchorFlag(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get Anchor Blips
export const getAnchorBlips = createAsyncThunk(
  "Get Anchor Blips",
  async (data, thunkAPI) => {
    try {
      const response = await dashboardService.getAnchorBlips(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
    setSideBarShow: (state) => {
      state.sideBarShow = !state.sideBarShow;
    },
    dashboardLogoutReset: () => {
      return { ...initialState };
    },
    setBliperIdForViewBlip: (state, action) => {
      state.bliperIdForViewBlip = action.payload;
    },
    setPlaceBlips: (state, action) => {
      state.placeBlips = action.payload;
    },
    setBliperStatusKey: (state, action) => {
      state.bliperStatusKey = action.payload;
    },
    setVeri_StatusKey: (state, action) => {
      state.veri_StatusKey = action.payload;
    },
    setBlipCoinStatusKey: (state, action) => {
      state.blipCoinStatusKey = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDashboardData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDashboardData.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.success) {
          state.dashboardData = action.payload?.data;
        } else {
          state.isSuccess = action.payload.success;
          state.isError = !action.payload.success;
          state.message = action.payload.message;
        }
      })
      .addCase(getDashboardData.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(addSubAdmin.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addSubAdmin.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = action.payload.success;
        state.isError = !action.payload.success;
        state.message = action.payload.message;
      })
      .addCase(addSubAdmin.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(getSubAdmins.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSubAdmins.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.success) {
          state.subAdmins = action.payload;
        } else {
          state.isSuccess = action.payload.success;
          state.isError = !action.payload.success;
          state.message = action.payload.message;
        }
      })
      .addCase(getSubAdmins.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(getSubAdminById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSubAdminById.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.success) {
          state.subAdminDetailById = action.payload.data;
        } else {
          state.isSuccess = action.payload.success;
          state.isError = !action.payload.success;
          state.message = action.payload.message;
        }
      })
      .addCase(getSubAdminById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(updateSubAdminById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateSubAdminById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = action.payload.success;
        state.isError = !action.payload.success;
        state.message = action.payload.message;
      })
      .addCase(updateSubAdminById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })

      .addCase(updateSubAdminStatusById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateSubAdminStatusById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = action.payload.success;
        state.isError = !action.payload.success;
        state.message = action.payload.message;
      })
      .addCase(updateSubAdminStatusById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(deleteSubAdminById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteSubAdminById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = action.payload.success;
        state.isError = !action.payload.success;
        state.message = action.payload.message;
      })
      .addCase(deleteSubAdminById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(getContentManagement.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getContentManagement.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.success) {
          state.contentManagementLinks = action.payload?.data;
        } else {
          state.isSuccess = action.payload.success;
          state.isError = !action.payload.success;
          state.message = action.payload.message;
        }
      })
      .addCase(getContentManagement.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })

      .addCase(createContentManagement.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createContentManagement.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = action.payload.success;
        state.isError = !action.payload.success;
        state.message = action.payload.message;
      })
      .addCase(createContentManagement.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(updateContentManagement.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateContentManagement.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = action.payload.success;
        state.isError = !action.payload.success;
        state.message = action.payload.message;
      })
      .addCase(updateContentManagement.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(getBlipers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBlipers.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.success) {
          state.blipers = action.payload;
        } else {
          state.isSuccess = action.payload.success;
          state.isError = !action.payload.success;
          state.message = action.payload.message;
        }
      })
      .addCase(getBlipers.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(getBliperById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBliperById.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.success) {
          state.bliperDetailById = action.payload.data;
        } else {
          state.isSuccess = action.payload.success;
          state.isError = !action.payload.success;
          state.message = action.payload.message;
        }
      })
      .addCase(getBliperById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })

      .addCase(updateBliperStatusById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateBliperStatusById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = action.payload.success;
        state.isError = !action.payload.success;
        state.message = action.payload.message;
      })
      .addCase(updateBliperStatusById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(updateBliperVerificationStatusById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        updateBliperVerificationStatusById.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.isSuccess = action.payload.success;
          state.isError = !action.payload.success;
          state.message = action.payload.message;
        }
      )
      .addCase(updateBliperVerificationStatusById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(deleteBliperById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteBliperById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = action.payload.success;
        state.isError = !action.payload.success;
        state.message = action.payload.message;
      })
      .addCase(deleteBliperById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(updateBliperProfileImageById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateBliperProfileImageById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = action.payload.success;
        state.isError = !action.payload.success;
        state.message = action.payload.message;
      })
      .addCase(updateBliperProfileImageById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(addSystemBlipCoin.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addSystemBlipCoin.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = action.payload.success;
        state.isError = !action.payload.success;
        state.message = action.payload.message;
      })
      .addCase(addSystemBlipCoin.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(getSystemBlipCoin.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSystemBlipCoin.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.success) {
          state.systemBlipCoins = action.payload;
        } else {
          state.isSuccess = action.payload.success;
          state.isError = !action.payload.success;
          state.message = action.payload.message;
        }
      })
      .addCase(getSystemBlipCoin.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(getSystemBlipCoinById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSystemBlipCoinById.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.success) {
          state.systemBlipCoinDetailById = action.payload.data;
        } else {
          state.isSuccess = action.payload.success;
          state.isError = !action.payload.success;
          state.message = action.payload.message;
        }
      })
      .addCase(getSystemBlipCoinById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(updateSystemBlipCoinById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateSystemBlipCoinById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = action.payload.success;
        state.isError = !action.payload.success;
        state.message = action.payload.message;
      })
      .addCase(updateSystemBlipCoinById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })

      .addCase(updateSystemBlipCoinStatusById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateSystemBlipCoinStatusById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = action.payload.success;
        state.isError = !action.payload.success;
        state.message = action.payload.message;
      })
      .addCase(updateSystemBlipCoinStatusById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(deleteSystemBlipCoinById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteSystemBlipCoinById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = action.payload.success;
        state.isError = !action.payload.success;
        state.message = action.payload.message;
      })
      .addCase(deleteSystemBlipCoinById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(getBlipersVerificationRequest.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBlipersVerificationRequest.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.success) {
          state.blipersVerificationRequests = action.payload;
        } else {
          state.isSuccess = action.payload.success;
          state.isError = !action.payload.success;
          state.message = action.payload.message;
        }
      })
      .addCase(getBlipersVerificationRequest.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(getReportedBlipers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getReportedBlipers.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.success) {
          state.reportedBlipers = action.payload;
        } else {
          state.isSuccess = action.payload.success;
          state.isError = !action.payload.success;
          state.message = action.payload.message;
        }
      })
      .addCase(getReportedBlipers.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(blockReportedBliper.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(blockReportedBliper.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = action.payload.success;
        state.isError = !action.payload.success;
        state.message = action.payload.message;
      })
      .addCase(blockReportedBliper.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(getBliperReportsById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBliperReportsById.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.success) {
          state.bliperReportsById = action.payload;
        } else {
          state.isSuccess = action.payload.success;
          state.isError = !action.payload.success;
          state.message = action.payload.message;
        }
      })
      .addCase(getBliperReportsById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      //
      .addCase(getReportedBlips.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getReportedBlips.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.success) {
          state.reportedBlips = action.payload;
        } else {
          state.isSuccess = action.payload.success;
          state.isError = !action.payload.success;
          state.message = action.payload.message;
        }
      })
      .addCase(getReportedBlips.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(blockReportedBlip.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(blockReportedBlip.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = action.payload.success;
        state.isError = !action.payload.success;
        state.message = action.payload.message;
      })
      .addCase(blockReportedBlip.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(getBlipReportsById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBlipReportsById.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.success) {
          state.blipReportsById = action.payload;
        } else {
          state.isSuccess = action.payload.success;
          state.isError = !action.payload.success;
          state.message = action.payload.message;
        }
      })
      .addCase(getBlipReportsById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(getBlipsByBliperId.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBlipsByBliperId.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.success) {
          state.blipsByBliperId = action.payload;
        } else {
          state.isSuccess = action.payload.success;
          state.isError = !action.payload.success;
          state.message = action.payload.message;
        }
      })
      .addCase(getBlipsByBliperId.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(updateBlipStatusById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateBlipStatusById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = action.payload.success;
        state.isError = !action.payload.success;
        state.message = action.payload.message;
      })
      .addCase(updateBlipStatusById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(getBlipersVerificationHistory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBlipersVerificationHistory.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.success) {
        } else {
          state.isSuccess = action.payload.success;
          state.isError = !action.payload.success;
          state.message = action.payload.message;
        }
      })
      .addCase(getBlipersVerificationHistory.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(getPeakBlipSetting.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPeakBlipSetting.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.success) {
        } else {
          state.isSuccess = action.payload.success;
          state.isError = !action.payload.success;
          state.message = action.payload.message;
        }
      })
      .addCase(getPeakBlipSetting.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(updatePeakBlipSetting.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updatePeakBlipSetting.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = action.payload.success;
        state.isError = !action.payload.success;
        state.message = action.payload.message;
      })
      .addCase(updatePeakBlipSetting.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(updateAsAnchorFlag.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateAsAnchorFlag.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = action.payload.success;
        state.isError = !action.payload.success;
        state.message = action.payload.message;
      })
      .addCase(updateAsAnchorFlag.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(getAnchorBlips.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAnchorBlips.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.success) {
          state.anchorBlips = action.payload;
        } else {
          state.isSuccess = action.payload.success;
          state.isError = !action.payload.success;
          state.message = action.payload.message;
        }
      })
      .addCase(getAnchorBlips.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      });
  },
});
export const {
  reset,
  setSideBarShow,
  dashboardLogoutReset,
  setBliperIdForViewBlip,
  setPlaceBlips,
  setBliperStatusKey,
  setVeri_StatusKey,
  setBlipCoinStatusKey,
} = dashboardSlice.actions;
export default dashboardSlice.reducer;
