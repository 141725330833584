import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Header } from "../../../components/common/Header";
import { SideBar } from "../../../components/common/SideBar";
import { useNavigate } from "react-router-dom";
import { Dropdown, Modal, ModalBody, ModalHeader } from "react-bootstrap";
import {
  ViewIcon,
  DeleteIcon,
  SearchIcon,
  ReloadIcon,
  CloseModalIcon,
  DeleteModalImg,
  ActiveModalImg,
  BlipsIconTb,
  UnVerify,
  UserProfile,
  InactiveModalImg,
  VerifiedIcon,
  verifiedActionModalIcon,
  EditIcon,
  DropDownTBIcon,
} from "../../../assets/image-path";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteBliperById,
  getBliperById,
  getBlipers,
  getBlipsByBliperId,
  setBliperIdForViewBlip,
  setBliperStatusKey,
  updateBliperStatusById,
  updateBliperVerificationStatusById,
} from "../../../store/dashboard/DashboardSlice";
import { generateNewToken, setLoading } from "../../../store/auth/AuthSlice";
import Pagination from "../../../components/dashboard/pagination/Pagination";
import addCloudFrontBaseUrl from "../../../utils/addCloudFrontBaseUrl";

export const BlipersManagement = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(); // Use the translation hook
  const isInitialRender = useRef(true);
  const [verifyStatus, setVerifyStatus] = useState("");
  const [unVerify, setUnVerify] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const [selectedData, setSelectedData] = useState();
  const [search, setSearch] = useState("");
  const [statusFilter, setStatusFilter] = useState(null);
  const [verifyFilter, setVerifyFilter] = useState("");
  //Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit] = useState(+process.env.REACT_APP_PER_PAGE_LIMIT);
  const bliperStatusKey = useSelector(
    (state) => state?.dashboard?.bliperStatusKey
  );
  const { data: blipersList, count } = useSelector(
    (state) => state?.dashboard?.blipers
  );
  useEffect(() => {
    setStatusFilter(bliperStatusKey);
  }, [bliperStatusKey]);
  // Fetch Sub Admins whenever the currentPage or search changes
  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    if (search === "" || search?.length > 2) {
      getBlipersAPI();
    } // eslint-disable-next-line
  }, [currentPage, search, statusFilter, verifyFilter]);

  const getBlipersAPI = () => {
    const params = {
      pageLimit,
      currentPage,
      search,
      statusFilter,
      verifyFilter,
    };
    dispatch(setLoading(true));
    dispatch(getBlipers(params)).then((response) => {
      dispatch(setLoading(false));
      if (response.payload === "token_expired") {
        dispatch(generateNewToken()).then((res) => {
          if (res.payload.success) {
            getBlipersAPI();
          }
        });
      }
    });
  };

  //   Delete Bliper by id
  const deleteBliperByIdAPI = (id) => {
    dispatch(setLoading(true));
    dispatch(deleteBliperById(id)).then((response) => {
      dispatch(setLoading(false));
      if (response.payload === "token_expired") {
        dispatch(generateNewToken()).then((res) => {
          if (res.payload.success) {
            deleteBliperByIdAPI(id);
          }
        });
      } else if (response.payload.success) {
        getBlipersAPI();
        setDeleteModal(false);
        setSelectedData();
      }
    });
  };

  //   Get Bliper By Id API
  const getBliperByIdAPI = (id) => {
    dispatch(setLoading(true));
    dispatch(getBliperById(id)).then((response) => {
      dispatch(setLoading(false));
      if (response.payload === "token_expired") {
        dispatch(generateNewToken()).then((res) => {
          if (res.payload.success) {
            getBliperByIdAPI(id);
          }
        });
      } else if (response.payload.success) {
        navigate("details");
      }
    });
  };

  //   Edit Bliper By Id API
  const editBliperAPI = (id) => {
    dispatch(setLoading(true));
    dispatch(getBliperById(id)).then((response) => {
      dispatch(setLoading(false));
      if (response.payload === "token_expired") {
        dispatch(generateNewToken()).then((res) => {
          if (res.payload.success) {
            editBliperAPI(id);
          }
        });
      } else if (response.payload.success) {
        navigate("edit");
      }
    });
  };

  // get Blips By Bliper Id
  const getBlipsByBliperIdAPI = (id) => {
    dispatch(setLoading(true));
    dispatch(
      getBlipsByBliperId({
        id: id,
        pageLimit,
        currentPage: 1,
        search: "",
        dateRange: { startDate: "", endDate: "" },
        filter_by_anchor: "",
      })
    ).then((response) => {
      dispatch(setLoading(false));
      if (response.payload === "token_expired") {
        dispatch(generateNewToken()).then((res) => {
          if (res.payload.success) {
            getBlipsByBliperIdAPI(id);
          }
        });
      } else if (response.payload.success) {
        dispatch(setBliperIdForViewBlip(id));
        navigate("blips");
      }
    });
  };
  //   Update Bliper Status
  const updateBliperStatusAPI = (values) => {
    const params = {
      id: values._id,
      status: values.status === "active" ? "deactive" : "active",
    };
    dispatch(setLoading(true));
    dispatch(updateBliperStatusById(params)).then((response) => {
      dispatch(setLoading(false));
      if (response.payload === "token_expired") {
        dispatch(generateNewToken()).then((res) => {
          if (res.payload.success) {
            updateBliperStatusAPI(values);
          }
        });
      } else if (response.payload.success) {
        getBlipersAPI();
        setStatusModal(false);
      }
    });
  };

  //   Update Bliper Verification Status
  const updateBliperVerificationStatusByIdAPI = (values) => {
    const params = {
      id: values._id,
      status: verifyStatus === "Verified" ? "verified" : "unverified",
    };
    dispatch(setLoading(true));
    dispatch(updateBliperVerificationStatusById(params)).then((response) => {
      dispatch(setLoading(false));
      if (response.payload === "token_expired") {
        dispatch(generateNewToken()).then((res) => {
          if (res.payload.success) {
            updateBliperVerificationStatusByIdAPI(values);
          }
        });
      } else if (response.payload.success) {
        getBlipersAPI();
        setUnVerify(false);
      }
    });
  };
  return (
    <>
      <div id="body_wapper" className="mainBody">
        <div className="wapper">
          <SideBar />
          <div className="main-container">
            <Header headingName={""} />
            <div className="containerBody">
              <div className="mainSelectBox">
                <div className="headingPart cusHeadingLarge">
                  <h2>{t("blipersManagement.title")}</h2>
                  <span>
                    <div className="rightMarginPT">
                      <div className="reloadBTun">
                        <button
                          onClick={(e) => {
                            setSearch("");
                            setStatusFilter("");
                            setVerifyFilter("");
                            dispatch(setBliperStatusKey(""));
                            setCurrentPage(1);
                          }}
                        >
                          <img src={ReloadIcon} alt="reload" />
                        </button>
                      </div>
                      <Dropdown
                        drop
                        className="dropdown-filter dropdown"
                        onSelect={(e) => {
                          setVerifyFilter(e);
                          setCurrentPage(1);
                        }}
                      >
                        <Dropdown.Toggle>
                          <span>{verifyFilter || "Filter By Verify"}</span>
                          <img src={DropDownTBIcon} alt="icon" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          variant=""
                          id="style-5"
                          className="cusDrupdown"
                        >
                          <Dropdown.Item eventKey="" key={"clear filter"}>
                            clear filter
                          </Dropdown.Item>
                          <Dropdown.Item
                            eventKey="unverified"
                            key={"unverified"}
                          >
                            unverified
                          </Dropdown.Item>
                          <Dropdown.Item eventKey="verified" key={"verified"}>
                            verified
                          </Dropdown.Item>
                          <Dropdown.Item eventKey="declined" key={"declined"}>
                            declined
                          </Dropdown.Item>
                          <Dropdown.Item eventKey="requested" key={"requested"}>
                            requested
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      <Dropdown
                        drop
                        className="dropdown-filter dropdown"
                        onSelect={(e) => {
                          setStatusFilter(e);
                          dispatch(setBliperStatusKey(e));
                          setCurrentPage(1);
                        }}
                      >
                        <Dropdown.Toggle>
                          <span>
                            {statusFilter === "deactive"
                              ? "inactive"
                              : statusFilter || "Filter By Status"}
                          </span>
                          <img src={DropDownTBIcon} alt="icon" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          variant=""
                          id="style-5"
                          className="cusDrupdown"
                        >
                          <Dropdown.Item eventKey="" key={""}>
                            Clear Filter
                          </Dropdown.Item>
                          <Dropdown.Item eventKey="active" key={"active"}>
                            active
                          </Dropdown.Item>
                          <Dropdown.Item eventKey="deactive" key={"inactive"}>
                            inactive
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <div className="rightMarginPT">
                      <span className="searchBgMain blipecuremob">
                        <input
                          type="text"
                          placeholder={t("blipersManagement.searchPlaceholder")}
                          value={search}
                          onChange={(e) => {
                            const s = e.currentTarget?.value
                              ?.replace(/[^\w\s]|_/g, "")
                              .replace(/\s{2,}/g, " ");
                            setSearch(s);
                            s?.length > 2 && setCurrentPage(1);
                          }}
                        />
                        <img src={SearchIcon} alt="" />
                      </span>
                    </div>
                  </span>

                  {/* <h2>{t("blipersManagement.title")}</h2>
                  <span>
                    <div className="rightMarginPT">
                      <div className="reloadBTun">
                        <button
                          onClick={(e) => {
                            setSearch("");
                            setStatusFilter("");
                            setVerifyFilter("");
                            dispatch(setBliperStatusKey(""));
                            setCurrentPage(1);
                          }}
                        >
                          <img src={ReloadIcon} alt="reload" />
                        </button>
                      </div>
                      <Dropdown
                        drop
                        className="dropdown-filter dropdown"
                        onSelect={(e) => {
                          setVerifyFilter(e);
                          setCurrentPage(1);
                        }}
                      >
                        <Dropdown.Toggle>
                          <span>{verifyFilter || "Filter By Verify"}</span>
                          <img src={DropDownTBIcon} alt="icon" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          variant=""
                          id="style-5"
                          className="cusDrupdown"
                        >
                          <Dropdown.Item eventKey="" key={"clear filter"}>
                            clear filter
                          </Dropdown.Item>
                          <Dropdown.Item
                            eventKey="unverified"
                            key={"unverified"}
                          >
                            unverified
                          </Dropdown.Item>
                          <Dropdown.Item eventKey="verified" key={"verified"}>
                            verified
                          </Dropdown.Item>
                          <Dropdown.Item eventKey="declined" key={"declined"}>
                            declined
                          </Dropdown.Item>
                          <Dropdown.Item eventKey="requested" key={"requested"}>
                            requested
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      <Dropdown
                        drop
                        className="dropdown-filter dropdown"
                        onSelect={(e) => {
                          setStatusFilter(e);
                          dispatch(setBliperStatusKey(e));
                          setCurrentPage(1);
                        }}
                      >
                        <Dropdown.Toggle>
                          <span>
                            {statusFilter === "deactive"
                              ? "inactive"
                              : statusFilter || "Filter By Status"}
                          </span>
                          <img src={DropDownTBIcon} alt="icon" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          variant=""
                          id="style-5"
                          className="cusDrupdown"
                        >
                          <Dropdown.Item eventKey="" key={""}>
                            Clear Filter
                          </Dropdown.Item>
                          <Dropdown.Item eventKey="active" key={"active"}>
                            active
                          </Dropdown.Item>
                          <Dropdown.Item eventKey="deactive" key={"inactive"}>
                            inactive
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>

                      <span className="searchBgMain">
                        <input
                          type="text"
                          placeholder={t("blipersManagement.searchPlaceholder")}
                          value={search}
                          onChange={(e) => {
                            const s = e.currentTarget?.value
                              ?.replace(/[^\w\s]|_/g, "")
                              .replace(/\s{2,}/g, " ");
                            setSearch(s);
                            s?.length > 2 && setCurrentPage(1);
                          }}
                        />
                        <img src={SearchIcon} alt="" />
                      </span>
                    </div>
                  </span> */}
                </div>
                <div className="tableCusMain">
                  <div className="table-responsive" id="style-5">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>{t("blipersManagement.srNo")}</th>
                          <th>{t("blipersManagement.fullName")}</th>
                          <th>{t("blipersManagement.email")}</th>
                          {/* <th>{t("blipersManagement.phone")}</th> */}
                          <th>{t("blipersManagement.userName")}</th>
                          <th>{t("blipersManagement.verifyUser")}</th>
                          <th>{t("blipersManagement.status")}</th>
                          <th className="text-center">
                            {t("blipersManagement.action")}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {blipersList?.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                #
                                {parseInt(pageLimit) *
                                  parseInt(currentPage - 1) +
                                  parseInt(index + 1)}
                              </td>
                              <td className="text-capitalize proTableUser">
                                <img
                                  src={
                                    item.profileImage
                                      ? addCloudFrontBaseUrl(item.profileImage)
                                      : UserProfile
                                  }
                                  alt=""
                                  className="profimePicBox"
                                />
                                {item.fullName ? item.fullName : "N/A"}
                                <img
                                  src={
                                    item?.verificationRequestStatus ===
                                    "verified"
                                      ? VerifiedIcon
                                      : ""
                                  }
                                  alt=""
                                  className="verifyTick"
                                />
                              </td>
                              <td>
                                {item.email === null || item.email === ""
                                  ? "N/A"
                                  : item.email}
                              </td>
                              {/* <td>
                                {item.phoneNumber === null ||
                                item.phoneNumber === ""
                                  ? "N/A"
                                  : item.countryCode + " " + item.phoneNumber}
                              </td> */}
                              <td>{item.userName}</td>
                              <td
                                className={
                                  item?.verificationRequestStatus === "verified"
                                    ? "text-capitalize textGreen"
                                    : item?.verificationRequestStatus ===
                                      "requested"
                                    ? "text-capitalize"
                                    : "text-capitalize textRed"
                                }
                              >
                                {item?.verificationRequestStatus}
                                {/* <Dropdown
                                  drop
                                  className="selectCus dropdown"
                                  onSelect={(e) => {
                                    if (
                                      e.toLowerCase() ===
                                      item?.verificationRequestStatus
                                    ) {
                                    } else {
                                      setVerifyStatus(e);
                                      setUnVerify(true);
                                      setSelectedData(item);
                                    }
                                  }}
                                >
                                  <Dropdown.Toggle>
                                    <span className="text-capitalize">
                                      {item?.verificationRequestStatus}
                                    </span>
                                    <img src={DropDownTBIcon} alt="icon" />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu
                                    variant=""
                                    id="style-5"
                                    className="cusDrupdown"
                                  >
                                    <Dropdown.Item
                                      eventKey="Verified"
                                      key={"Verified"}
                                    >
                                      Verified
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="Unverified"
                                      key={"Unverified"}
                                    >
                                      Unverified
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown> */}
                              </td>
                              <td>
                                {/* <span
                                  className={
                                    item.status === "active"
                                      ? "activeTd textGreen"
                                      : "inactiveTd textRed"
                                  }
                                >
                                  {item.status === "active"
                                    ? "active"
                                    : "inctive"}
                                </span> */}
                                <small
                                  className="swichBx"
                                  onClick={() => {
                                    setSelectedData(item);
                                    setStatusModal(true);
                                  }}
                                >
                                  <input
                                    type="checkbox"
                                    id={item._id}
                                    checked={item.status === "active"}
                                    readOnly
                                  />
                                  <label htmlFor={item._id}></label>
                                </small>
                              </td>
                              <td className="text-center d-flex justify-content-center">
                                <span className="activeAllBtn">
                                  <button>
                                    <img
                                      src={BlipsIconTb}
                                      alt="Blips"
                                      title="Blips"
                                      onClick={(e) =>
                                        getBlipsByBliperIdAPI(item._id)
                                      }
                                    />
                                  </button>
                                  <button>
                                    <img
                                      src={ViewIcon}
                                      alt="View"
                                      title="View"
                                      onClick={(e) => {
                                        getBliperByIdAPI(item?._id);
                                      }}
                                    />
                                  </button>
                                  <button>
                                    <img
                                      src={EditIcon}
                                      alt="Edit"
                                      title="Edit"
                                      onClick={(e) => editBliperAPI(item._id)}
                                    />
                                  </button>
                                  <button>
                                    <img
                                      src={DeleteIcon}
                                      alt="Delete"
                                      title="Delete"
                                      onClick={(e) => {
                                        setSelectedData(item);
                                        setDeleteModal(true);
                                      }}
                                    />
                                  </button>
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <Pagination
                    currentPage={currentPage}
                    totalCount={count}
                    currentPageCount={blipersList?.length}
                    pageSize={pageLimit}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {deleteModal && (
        <Modal className="custumCssMCebter" show={deleteModal}>
          <ModalHeader>
            <h6>&nbsp;</h6>
            <button
              type="button"
              className=""
              aria-label="Close"
              onClick={() => {
                setDeleteModal(false);
              }}
            >
              <img src={CloseModalIcon} alt="Close Button" />
            </button>
          </ModalHeader>
          <ModalBody>
            <div className="centerModalContent">
              <div className="proConBox">
                <img src={DeleteModalImg} alt="Delete" className="img-fluid" />
              </div>
              <p>{t("blipersManagement.deleteConfirmation")}</p>
              <div className="textConBoxBtn">
                <button onClick={(e) => deleteBliperByIdAPI(selectedData?._id)}>
                  <img src={DeleteIcon} alt="Delete" />
                  {t("buttons.delete")}
                </button>
                <button
                  className="cancelBtun"
                  onClick={() => setDeleteModal(false)}
                >
                  {t("buttons.cancel")}
                </button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      )}

      {statusModal && (
        <Modal className="custumCssMCebter" show={statusModal}>
          <ModalHeader>
            <h6>&nbsp;</h6>
            <button
              type="button"
              className=""
              aria-label="Close"
              onClick={() => {
                setStatusModal(false);
              }}
            >
              <img src={CloseModalIcon} alt="Close Button" />
            </button>
          </ModalHeader>
          <ModalBody>
            <div className="centerModalContent">
              <div className="proConBox">
                <img
                  src={
                    selectedData?.status === "active"
                      ? InactiveModalImg
                      : ActiveModalImg
                  }
                  alt="Active"
                  className="img-fluid"
                />
              </div>
              <p>
                {t("blipersManagement.statusConfirmationPre")}{" "}
                {selectedData?.status === "active"
                  ? t("buttons.inactive")
                  : t("buttons.active")}{" "}
                {t("blipersManagement.statusConfirmationAfter")}
              </p>
              <div className="textConBoxBtn">
                <button
                  className="gradientBG"
                  onClick={(e) => updateBliperStatusAPI(selectedData)}
                >
                  {selectedData?.status === "active"
                    ? t("buttons.inactive")
                    : t("buttons.active")}
                </button>
                <button
                  className="cancelBtun"
                  onClick={() => setStatusModal(false)}
                >
                  {t("buttons.cancel")}
                </button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      )}

      {unVerify && (
        <Modal className="custumCssMCebter" show={unVerify}>
          <ModalHeader>
            <h6>&nbsp;</h6>
            <button
              type="button"
              className=""
              aria-label="Close"
              onClick={() => {
                setUnVerify(false);
              }}
            >
              <img src={CloseModalIcon} alt="Close Button" />
            </button>
          </ModalHeader>
          <ModalBody>
            <div className="centerModalContent">
              <div className="proConBox">
                <img
                  src={
                    verifyStatus === "Verified"
                      ? verifiedActionModalIcon
                      : UnVerify
                  }
                  alt="UnVerify"
                  className="img-fluid"
                />
              </div>
              <p>
                {t("blipersManagement.verifyConfirmationPre")}{" "}
                {verifyStatus === "Verified"
                  ? t("buttons.verify")
                  : t("buttons.unverify")}{" "}
                {t("blipersManagement.verifyConfirmationAfter")}
              </p>
              <div className="textConBoxBtn">
                <button
                  onClick={(e) =>
                    updateBliperVerificationStatusByIdAPI(selectedData)
                  }
                  className="gradientBG"
                >
                  {verifyStatus === "Verified"
                    ? t("buttons.verify")
                    : t("buttons.unverify")}
                </button>
                <button
                  className="cancelBtun"
                  onClick={() => setUnVerify(false)}
                >
                  {t("buttons.cancel")}
                </button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};
