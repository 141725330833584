import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SideBar } from "../../../components/common/SideBar";
import { Header } from "../../../components/common/Header";

import {
  CloseModalIcon,
  DeleteIcon,
  DeleteModalImg,
  PreviousIcon,
} from "../../../assets/image-path";
import { useDispatch, useSelector } from "react-redux";
import { deleteSystemBlipCoinById } from "../../../store/dashboard/DashboardSlice";
import { generateNewToken, setLoading } from "../../../store/auth/AuthSlice";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import VideoPlayer from "../../../components/dashboard/video-player/VideoPlayer";
import { useTranslation } from "react-i18next";
import addCloudFrontBaseUrl from "../../../utils/addCloudFrontBaseUrl";

export const BlipCoinsDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [showVideo, setShowVideo] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const systemBlipCoinDetailById = useSelector(
    (state) => state?.dashboard?.systemBlipCoinDetailById
  );

  //   Delete System Blip Coin by id
  const deleteSystemBlipCoinByIdAPI = (id) => {
    dispatch(setLoading(true));
    dispatch(deleteSystemBlipCoinById(id)).then((response) => {
      dispatch(setLoading(false));
      if (response.payload === "token_expired") {
        dispatch(generateNewToken()).then((res) => {
          if (res.payload.success) {
            deleteSystemBlipCoinByIdAPI(id);
          }
        });
      } else if (response.payload.success) {
        setDeleteModal(false);
        navigate(-1);
      }
    });
  };

  return (
    <>
      <div id="body_wapper" className="mainBody">
        <div className="wapper">
          <SideBar />
          <div className="main-container">
            <Header headingName={""} />
            <div className="containerBody">
              <div className="mainSelectBox">
                <div className="headingPart">
                  <h2>
                    <ul>
                      <li>{t("blipCoinPage.pageTitle")}</li>
                      <li> {">"} </li>
                      <li>{t("blipCoinDetail.pageTitle")}</li>
                    </ul>
                  </h2>
                  <div className="rightMarginPT">
                    <button className="backbTun" onClick={() => navigate(-1)}>
                      <img src={PreviousIcon} alt="" />
                      {t("buttons.back")}
                    </button>
                  </div>
                </div>
                <div className="basicContainer">
                  <div className="col-xl-7 col-lg-11">
                    <div className="tableViewDetail">
                      <div className="placeviewbox">
                        <img
                          src={addCloudFrontBaseUrl(
                            systemBlipCoinDetailById?.blipId?.thumbnailUrl
                          )}
                          alt="Place"
                          className="img-fluid"
                        />

                        <h6>
                          <button onClick={(e) => setShowVideo(true)}>
                            {t("buttons.viewVideo")}
                          </button>
                        </h6>
                      </div>
                      <div className="tableRound table-responsive">
                        <table className="table">
                          <tr>
                            <td>{t("blipCoinDetail.title")}</td>
                            <td className="text-capitalize">
                              {systemBlipCoinDetailById?.blipId?.title}
                            </td>
                          </tr>
                          <tr>
                            <td>{t("blipCoinDetail.location")}</td>
                            <td className="text-capitalize">
                              {systemBlipCoinDetailById?.blipId?.address}
                            </td>
                          </tr>
                          <tr>
                            <td>{t("blipCoinDetail.monetisationValue")} </td>
                            <td className="text-capitalize">
                              {systemBlipCoinDetailById?.blip_coins}{" "}
                              {t("blipCoinDetail.monetisationName")}
                              {" ("}
                              {systemBlipCoinDetailById?.blip_coin_type}
                              {") "}
                            </td>
                          </tr>
                          <tr>
                            <td>{t("blipCoinDetail.status")}</td>
                            <td className="text-capitalize">
                              <span
                                className={
                                  systemBlipCoinDetailById?.blipId?.status ===
                                  "active"
                                    ? "textGreen "
                                    : "textRed"
                                }
                              >
                                {systemBlipCoinDetailById?.blipId?.status}
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="idProofBox">
                        <div className="footBtnPt">
                          <button
                            className="col-xl-4 col-lg-6 col-md-8"
                            onClick={(e) => setDeleteModal(true)}
                          >
                            <img src={DeleteIcon} alt="Delete" />
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {deleteModal && (
        <Modal className="custumCssMCebter" show={deleteModal}>
          <ModalHeader>
            <h6>&nbsp;</h6>
            <button
              type="button"
              className=""
              aria-label="Close"
              onClick={() => {
                setDeleteModal(false);
              }}
            >
              <img src={CloseModalIcon} alt="Close Button" />
            </button>
          </ModalHeader>
          <ModalBody>
            <div className="centerModalContent">
              <div className="proConBox">
                <img src={DeleteModalImg} alt="Delete" className="img-fluid" />
              </div>
              <p>Are you sure you want to delete this System Blip Coin?</p>
              <div className="textConBoxBtn">
                <button
                  onClick={(e) =>
                    deleteSystemBlipCoinByIdAPI(
                      systemBlipCoinDetailById?.blipId?._id
                    )
                  }
                >
                  <img src={DeleteIcon} alt="Delete" />
                  Delete
                </button>
                <button
                  className="cancelBtun"
                  onClick={() => setDeleteModal(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      )}
      {showVideo && (
        <>
          <VideoPlayer
            videoSrc={addCloudFrontBaseUrl(
              systemBlipCoinDetailById?.blipId?.originalLink
            )}
            onClose={(e) => setShowVideo(false)}
          />
        </>
      )}
    </>
  );
};
