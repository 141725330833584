import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Header } from "../../../components/common/Header";
import { SideBar } from "../../../components/common/SideBar";
import { useNavigate } from "react-router-dom";
import { Dropdown, Modal, ModalBody, ModalHeader } from "react-bootstrap";
import {
  ViewIcon,
  DeleteIcon,
  SearchIcon,
  ReloadIcon,
  CloseModalIcon,
  DeleteModalImg,
  EditIcon,
  AddPlusIcon,
  InactiveCoinModalIcon,
  ActiveCoinModalIcon,
  DropDownTBIcon,
  PlatinumCoin,
  SilverCoin,
  GoldCoin,
} from "../../../assets/image-path";
import {
  deleteSystemBlipCoinById,
  getSystemBlipCoin,
  getSystemBlipCoinById,
  updateSystemBlipCoinStatusById,
  setBlipCoinStatusKey,
} from "../../../store/dashboard/DashboardSlice";
import { generateNewToken, setLoading } from "../../../store/auth/AuthSlice";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../../components/dashboard/pagination/Pagination";

export const BlipCoins = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isInitialRender = useRef(true);
  const [deleteModal, setDeleteModal] = useState(false);

  const [statusModal, setStatusModal] = useState(false);
  const [selectedData, setSelectedData] = useState();

  const [search, setSearch] = useState("");

  const [statusFilter, setStatusFilter] = useState(null);
  const [coinType, setCoinType] = useState("");
  //Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit] = useState(+process.env.REACT_APP_PER_PAGE_LIMIT);
  const blipCoinStatusKey = useSelector(
    (state) => state?.dashboard?.blipCoinStatusKey
  );
  const { data: systemBlipCoinsList, count } = useSelector(
    (state) => state?.dashboard?.systemBlipCoins
  );

  useEffect(() => {
    setStatusFilter(blipCoinStatusKey);
  }, [blipCoinStatusKey]);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    if (search === "" || search?.length > 2) {
      getSystemBlipCoinAPI();
    } // eslint-disable-next-line
  }, [currentPage, search, statusFilter, coinType]);

  const getSystemBlipCoinAPI = () => {
    const params = {
      pageLimit,
      currentPage,
      search,
      statusFilter,
      coinType,
    };
    dispatch(setLoading(true));
    dispatch(getSystemBlipCoin(params)).then((response) => {
      dispatch(setLoading(false));
      if (response.payload === "token_expired") {
        dispatch(generateNewToken()).then((res) => {
          if (res.payload.success) {
            getSystemBlipCoinAPI();
          }
        });
      }
    });
  };

  //   Delete System Blip Coin by id
  const deleteSystemBlipCoinByIdAPI = (id) => {
    dispatch(setLoading(true));
    dispatch(deleteSystemBlipCoinById(id)).then((response) => {
      dispatch(setLoading(false));
      if (response.payload === "token_expired") {
        dispatch(generateNewToken()).then((res) => {
          if (res.payload.success) {
            deleteSystemBlipCoinByIdAPI(id);
          }
        });
      } else if (response.payload.success) {
        getSystemBlipCoinAPI();
        setDeleteModal(false);
        setSelectedData();
      }
    });
  };

  //   Get System Blip Coin By Id API
  const getSystemBlipCoinByIdAPI = (id, target) => {
    dispatch(setLoading(true));
    dispatch(getSystemBlipCoinById(id)).then((response) => {
      dispatch(setLoading(false));
      if (response.payload === "token_expired") {
        dispatch(generateNewToken()).then((res) => {
          if (res.payload.success) {
            getSystemBlipCoinByIdAPI(id);
          }
        });
      } else if (response.payload.success) {
        if (target === "Edit") {
          navigate("update-monetisation");
        } else {
          navigate("details");
        }
      }
    });
  };

  //   Update System Blip Coin Status
  const updateSystemBlipCoinStatusByIdAPI = (values) => {
    const params = {
      id: values._id,
      status: values.status === "active" ? "inactive" : "active",
    };
    dispatch(setLoading(true));
    dispatch(updateSystemBlipCoinStatusById(params)).then((response) => {
      dispatch(setLoading(false));
      if (response.payload === "token_expired") {
        dispatch(generateNewToken()).then((res) => {
          if (res.payload.success) {
            updateSystemBlipCoinStatusByIdAPI(values);
          }
        });
      } else if (response.payload.success) {
        getSystemBlipCoinAPI();
        setStatusModal(false);
      }
    });
  };

  return (
    <>
      <div id="body_wapper" className="mainBody">
        <div className="wapper">
          <SideBar />
          <div className="main-container">
            <Header headingName={""} />
            <div className="containerBody">
              <div className="mainSelectBox">
                <div className="headingPart cusHeadingLarge cusResponCoins">
                  <h2>{t("blipCoinPage.pageTitle")}</h2>
                  <span>
                    <div className="rightMarginPT cusResponCoinsmobile mobileshowcusCoin">
                      <span className="searchBgMain">
                        <input
                          type="text"
                          className=""
                          placeholder={t("blipCoinPage.searchPlaceholder")}
                          value={search}
                          onChange={(e) => {
                            const s = e.currentTarget?.value
                              ?.replace(/[^\w\s]|_/g, "")
                              .replace(/\s{2,}/g, " ");
                            setSearch(s);
                            s?.length > 2 && setCurrentPage(1);
                          }}
                        />
                        <img src={SearchIcon} alt="" />
                      </span>
                      <button
                        className="gradientBG addBtun cusresbuttR"
                        onClick={() => navigate("create-monetisation")}
                      >
                        <img src={AddPlusIcon} alt="Add" />
                        {t("blipCoinPage.addCoinBtn")}
                      </button>
                    </div>
                    <div className="rightMarginPT">
                      <div className="reloadBTun">
                        <button
                          onClick={(e) => {
                            setSearch("");
                            setStatusFilter("");
                            setCoinType("");
                            dispatch(setBlipCoinStatusKey(""));
                            setCurrentPage(1);
                          }}
                        >
                          <img src={ReloadIcon} alt="reload" />
                        </button>
                      </div>
                      <Dropdown
                        drop
                        className="dropdown-filter dropdown"
                        onSelect={(e) => {
                          setCoinType(e);
                          setCurrentPage(1);
                        }}
                      >
                        <Dropdown.Toggle>
                          <span>{coinType || "Filter By Coin Type"}</span>
                          <img src={DropDownTBIcon} alt="icon" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          variant=""
                          id="style-5"
                          className="cusDrupdown"
                        >
                          <Dropdown.Item eventKey="" key={"clear filter"}>
                            clear filter
                          </Dropdown.Item>
                          <Dropdown.Item eventKey="platinum" key={"platinum"}>
                            <img src={PlatinumCoin} alt="" /> platinum
                          </Dropdown.Item>
                          <Dropdown.Item eventKey="gold" key={"gold"}>
                            <img src={GoldCoin} alt="" /> gold
                          </Dropdown.Item>
                          <Dropdown.Item eventKey="silver" key={"silver"}>
                            <img src={SilverCoin} alt="" /> silver
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      <Dropdown
                        drop
                        className="dropdown-filter dropdown"
                        onSelect={(e) => {
                          setStatusFilter(e);
                          dispatch(setBlipCoinStatusKey(e));
                          setCurrentPage(1);
                        }}
                      >
                        <Dropdown.Toggle>
                          <span>{statusFilter || "Filter By Status"}</span>
                          <img src={DropDownTBIcon} alt="icon" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          variant=""
                          id="style-5"
                          className="cusDrupdown"
                        >
                          <Dropdown.Item eventKey="" key={""}>
                            Clear Filter
                          </Dropdown.Item>
                          <Dropdown.Item eventKey="active" key={"active"}>
                            active
                          </Dropdown.Item>
                          <Dropdown.Item eventKey="inactive" key={"inactive"}>
                            inactive
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <div className="rightMarginPT cusResponCoinsmobile destopshowcusCoin">
                      <span className="searchBgMain">
                        <input
                          type="text"
                          className=""
                          placeholder={t("blipCoinPage.searchPlaceholder")}
                          value={search}
                          onChange={(e) => {
                            const s = e.currentTarget?.value
                              ?.replace(/[^\w\s]|_/g, "")
                              .replace(/\s{2,}/g, " ");
                            setSearch(s);
                            s?.length > 2 && setCurrentPage(1);
                          }}
                        />
                        <img src={SearchIcon} alt="" />
                      </span>
                      <button
                        className="gradientBG addBtun cusresbuttR"
                        onClick={() => navigate("create-monetisation")}
                      >
                        <img src={AddPlusIcon} alt="Add" />
                        {t("blipCoinPage.addCoinBtn")}
                      </button>
                    </div>
                  </span>
                </div>
                <div className="tableCusMain">
                  <div className="table-responsive" id="style-5">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>{t("blipCoinPage.srNo")}</th>
                          <th>{t("blipCoinPage.title")}</th>
                          <th>{t("blipCoinPage.location")}</th>
                          <th>{t("blipCoinPage.monetisationValue")}</th>
                          <th>{t("blipCoinPage.coinType")}</th>
                          <th>{t("blipCoinPage.status")}</th>
                          <th className="text-center">
                            {t("blipCoinPage.action")}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {systemBlipCoinsList?.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                #
                                {parseInt(pageLimit) *
                                  parseInt(currentPage - 1) +
                                  parseInt(index + 1)}
                              </td>

                              <td className="textLargeAdd text-capitalize">
                                {item?.blipId?.title}
                              </td>
                              <td className="textLargeAdd">
                                {item?.blipId?.address}
                              </td>
                              <td>{item.blip_coins} Coins</td>
                              <td className="text-capitalize">
                                {item.blip_coin_type}{" "}
                              </td>

                              <td>
                                {/* <span
                                  className={
                                    item?.blipId?.status === "active"
                                      ? "activeTd textGreen"
                                      : "inactiveTd textRed"
                                  }
                                >
                                  {item?.blipId?.status}
                                </span> */}
                                <small className="swichBx">
                                  <input
                                    type="checkbox"
                                    id={item?.blipId?._id}
                                    checked={item?.blipId?.status === "active"}
                                    onClick={() => {
                                      setSelectedData(item?.blipId);
                                      setStatusModal(true);
                                    }}
                                    readOnly
                                  />
                                  <label htmlFor={item?.blipId?._id}></label>
                                </small>
                              </td>
                              <td className="text-center d-flex justify-content-center">
                                <span className="activeAllBtn">
                                  <button>
                                    <img
                                      src={ViewIcon}
                                      alt="View"
                                      title="View"
                                      onClick={(e) =>
                                        getSystemBlipCoinByIdAPI(
                                          item?.blipId?._id,
                                          "View"
                                        )
                                      }
                                    />
                                  </button>

                                  <button>
                                    <img
                                      src={EditIcon}
                                      alt="Edit"
                                      title="Edit"
                                      onClick={(e) =>
                                        getSystemBlipCoinByIdAPI(
                                          item?.blipId?._id,
                                          "Edit"
                                        )
                                      }
                                    />
                                  </button>
                                  <button>
                                    <img
                                      src={DeleteIcon}
                                      alt="Delete"
                                      title="Delete"
                                      onClick={(e) => {
                                        setSelectedData(item?.blipId);
                                        setDeleteModal(true);
                                      }}
                                    />
                                  </button>
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <Pagination
                    currentPage={currentPage}
                    totalCount={count}
                    currentPageCount={systemBlipCoinsList?.length}
                    pageSize={pageLimit}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {deleteModal && (
        <Modal className="custumCssMCebter" show={deleteModal}>
          <ModalHeader>
            <h6>&nbsp;</h6>
            <button
              type="button"
              className=""
              aria-label="Close"
              onClick={() => {
                setDeleteModal(false);
              }}
            >
              <img src={CloseModalIcon} alt="Close Button" />
            </button>
          </ModalHeader>
          <ModalBody>
            <div className="centerModalContent">
              <div className="proConBox">
                <img src={DeleteModalImg} alt="Delete" className="img-fluid" />
              </div>
              <p>{t("blipCoinPage.deleteConfirmation")}</p>
              <div className="textConBoxBtn">
                <button
                  onClick={(e) =>
                    deleteSystemBlipCoinByIdAPI(selectedData?._id)
                  }
                >
                  <img src={DeleteIcon} alt="" />
                  {t("buttons.delete")}
                </button>
                <button
                  className="cancelBtun"
                  onClick={() => setDeleteModal(false)}
                >
                  {t("buttons.cancel")}
                </button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      )}

      {statusModal && (
        <Modal className="custumCssMCebter" show={statusModal}>
          <ModalHeader>
            <h6>&nbsp;</h6>
            <button
              type="button"
              className=""
              aria-label="Close"
              onClick={() => {
                setStatusModal(false);
              }}
            >
              <img src={CloseModalIcon} alt="Close Button" />
            </button>
          </ModalHeader>
          <ModalBody>
            <div className="centerModalContent">
              <div className="proConBox">
                <img
                  src={
                    selectedData?.status === "active"
                      ? InactiveCoinModalIcon
                      : ActiveCoinModalIcon
                  }
                  alt="Active"
                  className="img-fluid"
                />
              </div>
              <p>
                {t("blipCoinPage.statusConfirmationPre")}{" "}
                {selectedData?.status === "active"
                  ? t("buttons.inactive")
                  : t("buttons.active")}{" "}
                {t("blipCoinPage.statusConfirmationAfter")}
              </p>
              <div className="textConBoxBtn">
                <button
                  className="gradientBG"
                  onClick={(e) =>
                    updateSystemBlipCoinStatusByIdAPI(selectedData)
                  }
                >
                  {selectedData?.status === "active"
                    ? t("buttons.inactive")
                    : t("buttons.active")}
                </button>
                <button
                  className="cancelBtun"
                  onClick={() => setStatusModal(false)}
                >
                  {t("buttons.cancel")}
                </button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};
