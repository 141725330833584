import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  AdminLogo,
  DashboardIcon,
  SubAdminManagementIcon,
  BlipersManagementIcon,
  VerificationRequestIcon,
  ContentModerationIcon,
  BlipCoinsIcon,
  ContentManagementIcon,
  DropLeftIcon,
  AdminLogoIcon,
  BlipIcon,
  SettingIcon,
  AnchorBlipsSidebarIcon,
} from "../../assets/image-path";
import {
  setBlipCoinStatusKey,
  setBliperStatusKey,
  setVeri_StatusKey,
} from "../../store/dashboard/DashboardSlice";

export const SideBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(); // Use the translation hook
  const [subMenuNav, setSubMenuNav] = useState(false);

  return (
    <>
      <aside>
        <div className="aside-logo">
          <img
            src={AdminLogo}
            alt="Logo"
            onClick={() => {
              navigate("/");
            }}
            className="sortingIconLogoHide"
          />
          <img
            src={AdminLogoIcon}
            alt="Logo"
            onClick={() => {
              navigate("/");
            }}
            className="sortingIconLogo"
          />
        </div>
        <span className="menuScrol" id="style-5">
          <li
            className={
              window.location.pathname === "/dashboard" ? "active" : ""
            }
          >
            <button
              onClick={() => {
                navigate("/dashboard");
              }}
            >
              <img src={DashboardIcon} alt="" />
              <span className="textSpanHide">{t("sidebarMenu.dashboard")}</span>
            </button>
          </li>

          <li
            className={
              window.location.pathname === "/sub-admin-management" ||
              window.location.pathname === "/sub-admin-management/add" ||
              window.location.pathname === "/sub-admin-management/edit"
                ? "active"
                : ""
            }
          >
            <button
              onClick={() => {
                navigate("/sub-admin-management");
              }}
            >
              <img src={SubAdminManagementIcon} alt="" />
              <span className="textSpanHide">
                {t("sidebarMenu.subAdminManagement")}
              </span>
            </button>
          </li>

          <li
            className={
              window.location.pathname === "/blipers-management" ||
              window.location.pathname === "/blipers-management/blips" ||
              window.location.pathname === "/blipers-management/details" ||
              window.location.pathname === "/bliper-active-details" ||
              window.location.pathname === "/bliper-details-view-blips"
                ? "active"
                : ""
            }
          >
            <button
              onClick={() => {
                dispatch(setBliperStatusKey(""));
                navigate("/blipers-management");
              }}
            >
              <img src={BlipersManagementIcon} alt="" />
              <span className="textSpanHide">
                {t("sidebarMenu.blipersManagement")}
              </span>
            </button>
          </li>

          <li
            className={
              window.location.pathname === "/verification-request" ||
              window.location.pathname === "/verification-request/details"
                ? "active"
                : ""
            }
          >
            <button
              onClick={() => {
                dispatch(setVeri_StatusKey(""));
                navigate("/verification-request");
              }}
            >
              <img src={VerificationRequestIcon} alt="" />
              <span className="textSpanHide">
                {t("sidebarMenu.verificationRequest")}
              </span>
            </button>
          </li>

          <li
            className={
              window.location.pathname === "/reported-blipers" ||
              window.location.pathname === "/reported-blipers/reports" ||
              window.location.pathname === "/reported-blips" ||
              window.location.pathname === "/reported-blips/reports"
                ? "submenuDropDown show active"
                : "submenuDropDown show"
            }
          >
            <button onClick={(e) => setSubMenuNav(!subMenuNav)}>
              <img src={ContentModerationIcon} alt="" />
              <span className="textSpanHide">
                {t("sidebarMenu.contentModeration")}
              </span>
              <img src={DropLeftIcon} alt="" className="imgRotate" />
            </button>
            <div
              className={
                subMenuNav ||
                window.location.pathname === "/reported-blipers" ||
                window.location.pathname === "/reported-blipers/reports" ||
                window.location.pathname === "/reported-blips" ||
                window.location.pathname === "/reported-blips/reports"
                  ? "submenuDrop opneSub"
                  : "submenuDrop"
              }
            >
              <ul>
                <li
                  className={
                    window.location.pathname === "/reported-blipers" ||
                    window.location.pathname === "/reported-blipers/reports"
                      ? "active"
                      : ""
                  }
                >
                  <button onClick={() => navigate("/reported-blipers")}>
                    <img src={BlipersManagementIcon} alt="" />
                    <span className="textSpanHide">
                      {t("sidebarMenu.blipers")}
                    </span>
                  </button>
                </li>
                <li
                  className={
                    window.location.pathname === "/reported-blips" ||
                    window.location.pathname === "/reported-blips/reports"
                      ? "active"
                      : ""
                  }
                >
                  <button onClick={() => navigate("/reported-blips")}>
                    <img src={BlipIcon} alt="" />
                    <span className="textSpanHide">
                      {t("sidebarMenu.blips")}
                    </span>
                  </button>
                </li>
              </ul>
            </div>
          </li>
          <li
            className={
              window.location.pathname === "/blip-coins" ||
              window.location.pathname === "/blip-coins/create-monetisation" ||
              window.location.pathname === "/blip-coins/update-monetisation" ||
              window.location.pathname === "/blip-coins/details"
                ? "active"
                : ""
            }
          >
            <button
              onClick={() => {
                dispatch(setBlipCoinStatusKey(""));
                navigate("/blip-coins");
              }}
            >
              <img src={BlipCoinsIcon} alt="" />
              <span className="textSpanHide">{t("sidebarMenu.blipCoins")}</span>
            </button>
          </li>
          <li
            className={
              window.location.pathname === "/anchor-blips" ? "active" : ""
            }
          >
            <button
              onClick={() => {
                navigate("/anchor-blips");
              }}
            >
              <img src={AnchorBlipsSidebarIcon} alt="" />
              <span className="textSpanHide">
                {t("sidebarMenu.anchorBlips")}
              </span>
            </button>
          </li>
          <li
            className={
              window.location.pathname === "/content-management" ? "active" : ""
            }
          >
            <button onClick={() => navigate("/content-management")}>
              <img src={ContentManagementIcon} alt="" />
              <span className="textSpanHide">
                {t("sidebarMenu.contentManagement")}
              </span>
            </button>
          </li>
          <li
            className={window.location.pathname === "/setting" ? "active" : ""}
          >
            <button onClick={() => navigate("/setting")}>
              <img src={SettingIcon} alt="" />
              <span className="textSpanHide">{t("sidebarMenu.setting")}</span>
            </button>
          </li>
        </span>
        <div className="button_version">
          <h2>{process.env.REACT_APP_BUILD_VERSION}</h2>
        </div>
      </aside>
    </>
  );
};
