import React, { useEffect, useRef, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

import { SideBar } from "../../../components/common/SideBar";
import { Header } from "../../../components/common/Header";
import AWS from "aws-sdk";
import { Buffer } from "buffer";
import {
  DropDownTBIcon,
  GoldCoin,
  PlatinumCoin,
  PreviousIcon,
  SilverCoin,
} from "../../../assets/image-path";

import { addSystemBlipCoin } from "../../../store/dashboard/DashboardSlice";
import { generateNewToken, setLoading } from "../../../store/auth/AuthSlice";
import { useDispatch } from "react-redux";
// import s3Upload from "../../../utils/upload-in-s3-bucket";
import AddressInput from "../../../components/dashboard/map/search-input";
import Map from "../../../components/dashboard/map/Map";
import VideoPlayer from "../../../components/dashboard/video-player/VideoPlayer";
import FilesDragAndDrop from "../../../components/dashboard/filesDragAndDrop";
import { useTranslation } from "react-i18next";
import { Dropdown, ProgressBar } from "react-bootstrap";

export const CreateMonetisation = () => {
  const inputRef = useRef(null);
  const divRef = useRef(null);
  const formikRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [progressBar, setProgressBar] = useState({
    show: false,
    video: 0,
    image: 0,
  });
  const [showVideo, setShowVideo] = useState(false);
  const [thumbnail, setThumbnail] = useState();
  const [error, setError] = useState(null);

  const [mapCenter, setMapCenter] = useState({
    lat: 22.6957011,
    lng: 75.887468,
  });

  const DisplayUploadProgress = () => {
    const progressPrecent = progressBar.video * 0.95 + progressBar.image * 0.05;
    return (
      <div className="row align-items-end">
        <div className="col-md-12">
          {progressPrecent < 100 ? (
            <h6> Uploading...</h6>
          ) : (
            <h6 className="text-success"> Uploaded</h6>
          )}

          <ProgressBar
            // animated
            variant="success"
            now={progressPrecent}
            label={`${parseInt(progressPrecent)}%`}
          />
        </div>
      </div>
    );
  };

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        var latitude = position.coords.latitude;
        var longitude = position.coords.longitude;
        if (formikRef.current) {
          formikRef.current.setFieldValue("latitude", parseFloat(latitude));
          formikRef.current.setFieldValue("longitude", parseFloat(longitude));
        }
      });
    } else {
      console.log("Geolocation is not available in this browser.");
    }
  }, []);

  const addSystemBlipCoinAPI = async (values) => {
    try {
      dispatch(setLoading(true));
      dispatch(addSystemBlipCoin(values)).then((response) => {
        dispatch(setLoading(false));
        if (response.payload === "token_expired") {
          dispatch(generateNewToken()).then((res) => {
            if (res.payload.success) {
              addSystemBlipCoinAPI(values);
            }
          });
        } else if (response.payload.success) {
          setProgressBar((prevState) => {
            return {
              ...prevState,
              show: false,
            };
          });

          navigate(-1);
        }
      });
    } catch (error) {
      dispatch(setLoading(false));
      // Handle any errors that occur during the upload or other parts of the code
      console.error("Error in addSystemBlipCoinAPI:", error);
    }
  };

  // Define a function to get the duration of a video file
  function getVideoDuration(file) {
    return new Promise((resolve, reject) => {
      const video = document.createElement("video");
      video.onloadedmetadata = () => {
        resolve(video.duration);
      };
      video.onerror = () => {
        reject("Unable to get video duration.");
      };
      video.src = URL.createObjectURL(file);
    });
  }

  const handleFileChange = (event) => {
    setError(null);
    const file = event.target.files[0];
    const fileReader = new FileReader();

    if (file.type.match("image")) {
      // fileReader.onload = () => {
      //   const img = document.createElement("img");
      //   img.src = fileReader.result;
      //   divRef.current.appendChild(img);
      // };
      // fileReader.readAsDataURL(file);
    } else {
      fileReader.onload = () => {
        const blob = new Blob([fileReader.result], { type: file.type });

        const url = URL.createObjectURL(blob);
        const video = document.createElement("video");

        const timeupdate = () => {
          if (snapImage()) {
            video?.removeEventListener("timeupdate", timeupdate);
            video?.pause();
          }
        };

        video.addEventListener("loadeddata", () => {
          if (snapImage()) {
            video.removeEventListener("timeupdate", timeupdate);
          }
        });

        const snapImage = () => {
          const canvas = document.createElement("canvas");

          // Calculate the new height based on the aspect ratio
          const aspectRatio = video.videoWidth / video.videoHeight;
          const newHeight = 200 / aspectRatio;

          // Set the canvas size to 200xnewHeight
          canvas.width = 200;
          canvas.height = newHeight;

          canvas.getContext("2d").drawImage(video, 0, 0, 200, newHeight);

          if (canvas?.width > 0) {
            const image = canvas?.toDataURL();
            setThumbnail(dataURLtoBlob(image));
            const success = image?.length > 100000;

            // if (success) {
            //   const img = document.createElement("img");
            //   img.src = image;
            //   divRef.current.appendChild(img);
            //   URL.revokeObjectURL(url);
            // }
            return success;
          } else
            setError("Video File is Corrupted, Please Select New Video File");
        };

        video.addEventListener("timeupdate", timeupdate);
        video.preload = "metadata";
        video.src = url;
        // Load video in Safari / IE11
        video.muted = true;
        video.playsInline = true;
        video.play();
      };
      fileReader.readAsArrayBuffer(file);
    }
  };

  function dataURLtoBlob(dataURL) {
    // Split the data URL into the MIME type and data
    const [header, data] = dataURL?.split(",");

    // Extract the MIME type from the header
    const mimeMatch = header?.match(/:(.*?);/);
    if (!mimeMatch) {
      setError("Video File is Corrupted, Please Select New Video File");
      // throw new Error("Invalid data URL: No MIME type found");
    }

    const mimeType = mimeMatch[1];

    // Convert the base64-encoded data to a Uint8Array
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    // Create a Blob from the Uint8Array and MIME type
    return new Blob([byteArray], { type: mimeType });
  }

  Buffer.from("anything", "base64");
  window.Buffer = window.Buffer || require("buffer").Buffer;

  const s3Upload = async (file, key, contentType) => {
    const S3_BUCKET = process.env.REACT_APP_AWS_BUCKET;
    // S3 Credentials
    AWS.config.update({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    });

    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: process.env.REACT_APP_AWS_REGION,
    });

    // Set the S3 object parameters
    const params = {
      Bucket: S3_BUCKET,
      Key: key.toString().trim(),
      Body: file,
      ContentDisposition: "inline",
      ContentType: contentType,
    };
    var options = { partSize: 50 * 1024 * 1024, queueSize: 1 };

    return new Promise((resolve, reject) => {
      // Upload the file to S3
      s3.upload(params, options, (err, data) => {
        if (err) {
          console.error("Error uploading file:", err);

          reject(err); // Reject the promise with an error
        } else if (data?.Location) {
          // console.log("File uploaded successfully:", data?.Location);
          resolve(data?.Location); // Resolve the promise with the file URL
        }
      }).on("httpUploadProgress", (progress) => {
        const progressVal = Math.round(
          (progress.loaded / progress.total) * 100
        );

        if (contentType === "image/png") {
          setProgressBar((prevState) => {
            return {
              ...prevState,
              show: true,
              image: progressVal,
            };
          });
        } else {
          setProgressBar((prevState) => {
            return {
              ...prevState,
              show: true,
              video: progressVal,
            };
          });
        }
      });
    });
  };

  return (
    <>
      <div id="body_wapper" className="mainBody">
        <div className="wapper">
          <SideBar />
          <div className="main-container">
            <Header headingName={""} />
            <div className="containerBody">
              <div className="mainSelectBox">
                <div className="headingPart">
                  <h2>
                    <ul>
                      <li>{t("blipCoinPage.pageTitle")}</li>
                      <li> {">"} </li>
                      <li>{t("addCoinPage.pageTitle")}</li>
                    </ul>
                  </h2>

                  <div className="rightMarginPT">
                    <button className="backbTun" onClick={() => navigate(-1)}>
                      <img src={PreviousIcon} alt="" />
                      {t("buttons.back")}
                    </button>
                  </div>
                </div>
                <div className="basicContainer">
                  <Formik
                    innerRef={formikRef}
                    initialValues={{
                      title: "",
                      blip_coin_type: "",
                      monetisation_Value: "",
                      address: "",
                      city: "",
                      latitude: mapCenter?.lat,
                      longitude: mapCenter?.lng,
                      video: "",
                    }}
                    validationSchema={Yup.object().shape({
                      title: Yup.string().required(
                        t("formErrorMsg.titleRequired")
                      ),
                      blip_coin_type: Yup.string().required(
                        t("formErrorMsg.coin_typeRequired")
                      ),
                      monetisation_Value: Yup.number()
                        .typeError(t("formErrorMsg.monetisationValueTypeError"))
                        .required(t("formErrorMsg.monetisationValueRequired"))
                        .max(99999, t("formErrorMsg.monetisationMaximumDigits"))
                        .integer("Decimal numbers are not allowed"),

                      address: Yup.string().required(
                        t("formErrorMsg.locationRequired")
                      ),

                      video: Yup.mixed()
                        .required(t("formErrorMsg.videoRequired"))
                        .test(
                          "fileType",
                          t("formErrorMsg.videoTypeError"),
                          (value) => {
                            return (
                              value &&
                              [
                                "video/mp4",
                                // "video/mkv",
                                // "video/x-matroska",
                                // "video/avi",
                                // "video/flv",
                                "video/quicktime",
                              ].includes(value.type)
                            );
                          }
                        )
                        .test(
                          "fileSize",
                          t("formErrorMsg.videoSizeError"),
                          (value) => {
                            return value && value.size <= 50 * 1024 * 1024; // 50MB
                          }
                        )
                        .test(
                          "videoDuration",
                          t("formErrorMsg.videoDurationError"),
                          async (value) => {
                            if (!value) {
                              return false; // No file provided, so it can't have a duration.
                            }

                            try {
                              const duration = await getVideoDuration(value);

                              return duration <= 45; // 45 seconds
                            } catch (error) {
                              return false; // Unable to get the duration.
                            }
                          }
                        ),
                    })}
                    onSubmit={async (values) => {
                      // dispatch(setLoading(true));
                      setProgressBar({ ...progressBar, show: true });
                      var ts = Math.floor(Date.now() / 1000);

                      const fileExtension = values?.video?.name
                        ?.split(".")
                        .pop()
                        .toLowerCase();

                      const blipKey =
                        "system-blips/" + ts + "system-blip." + fileExtension;

                      const thumbnailKey =
                        "system-blips/" + ts + "system-blip.png";

                      const videoUrl = await s3Upload(
                        values.video,
                        blipKey,
                        values.video.type
                      );
                      const thumbnailUrl = await s3Upload(
                        thumbnail,
                        thumbnailKey,
                        "image/png"
                      );

                      const params = {
                        title: values.title,
                        blip_coin_type: values.blip_coin_type,
                        blip_coins: parseInt(values.monetisation_Value),
                        address: values.address,
                        city: values.city,
                        latitude: values.latitude,
                        longitude: values.longitude,
                        // latitude: 22.72265774005667,
                        // longitude: 75.88668091525099,
                        originalLink: videoUrl ? blipKey : undefined,
                        thumbnailUrl: thumbnailUrl ? thumbnailKey : undefined,
                        blipFrom: "system",
                      };
                      addSystemBlipCoinAPI(params);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      setFieldValue,
                      setFieldTouched,
                    }) => (
                      <form
                        className="basicInformation"
                        onSubmit={handleSubmit}
                      >
                        <div className="row cusWidthCol-MD">
                          <div className="col-xl-12 col-lg-12">
                            <div className="form-group">
                              <label>{t("formLabel.title")}</label>
                              <input
                                type="text"
                                placeholder={t("formPlaceholder.title")}
                                className="form-control"
                                name="title"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.title}
                              />
                              {errors.title && touched.title && (
                                <div className="error-msg">{errors.title}</div>
                              )}
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6">
                            <div className="form-group">
                              <label>{t("formLabel.blip_coin_type")}</label>
                              <Dropdown
                                drop
                                className="select100 dropdown"
                                onSelect={(e) => {
                                  setFieldValue("blip_coin_type", e);
                                }}
                                onToggle={(isOpen) => {
                                  if (isOpen) {
                                    setFieldTouched("blip_coin_type", true);
                                  }
                                }}
                              >
                                <Dropdown.Toggle>
                                  <span>
                                    {values.blip_coin_type
                                      ? values.blip_coin_type
                                      : t("formPlaceholder.blip_coin_type")}
                                  </span>
                                  <img src={DropDownTBIcon} alt="icon" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu
                                  variant=""
                                  id="style-5"
                                  className="cusDrupdown"
                                >
                                  <Dropdown.Item
                                    eventKey="platinum"
                                    key="platinum"
                                  >
                                    <img src={PlatinumCoin} alt="" /> platinum
                                  </Dropdown.Item>
                                  <Dropdown.Item eventKey="silver" key="silver">
                                    <img src={SilverCoin} alt="" /> silver
                                  </Dropdown.Item>
                                  <Dropdown.Item eventKey="gold" key="gold">
                                    <img src={GoldCoin} alt="" /> gold
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>

                              {errors.blip_coin_type &&
                                touched.blip_coin_type && (
                                  <div className="error-msg">
                                    {errors.blip_coin_type}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 ">
                            <div className="form-group">
                              <label>{t("formLabel.monetisationValue")}</label>
                              <input
                                type="text"
                                placeholder={t(
                                  "formPlaceholder.monetisationValue"
                                )}
                                maxLength={5}
                                className="form-control"
                                name="monetisation_Value"
                                onChange={(e) => {
                                  const s = e.currentTarget?.value
                                    ?.replace(/[^\d]/g, "")
                                    ?.replace(/^0+/, "");

                                  setFieldValue("monetisation_Value", s);
                                }}
                                onBlur={handleBlur}
                                value={values.monetisation_Value}
                              />
                              {errors.monetisation_Value &&
                                touched.monetisation_Value && (
                                  <div className="error-msg">
                                    {errors.monetisation_Value}
                                  </div>
                                )}
                            </div>
                          </div>

                          <div className="col-xl-12 ">
                            <div className="form-group">
                              <label>{t("formLabel.location")}</label>
                              <AddressInput setFieldValue={setFieldValue}>
                                <input
                                  placeholder={t("formPlaceholder.location")}
                                  name="address"
                                  type="text"
                                  className="form-control"
                                  defaultValue={values.address}
                                  onChange={(e) => handleChange}
                                  onKeyUp={(e) => handleChange}
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      e.preventDefault(); // Prevent form submission on Enter key
                                    }
                                  }}
                                  onBlur={handleBlur}
                                />
                              </AddressInput>
                              {errors.address && touched.address && (
                                <div className="error-msg">
                                  {errors.address}
                                </div>
                              )}
                              <Map
                                center={{
                                  lat: values?.latitude,
                                  lng: values?.longitude,
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-xl-12 ">
                            <div className="form-group">
                              <label>{t("formLabel.addVideo")}</label>
                              <FilesDragAndDrop
                                setFieldValue={setFieldValue}
                                video={values?.video}
                                showVideo={(e) => setShowVideo(true)}
                                inputRef={inputRef}
                                handleFileChange={handleFileChange}
                                setFieldTouched={setFieldTouched}
                              />

                              {values?.video && showVideo && (
                                <>
                                  <VideoPlayer
                                    videoSrc={URL.createObjectURL(
                                      values?.video
                                    )}
                                    onClose={(e) => setShowVideo(false)}
                                  />
                                </>
                              )}

                              {errors.video && touched.video && (
                                <div className="error-msg">{errors.video}</div>
                              )}
                              {error && (
                                <div className="error-msg">{error}</div>
                              )}

                              <div ref={divRef}></div>
                            </div>
                            {progressBar.show && <DisplayUploadProgress />}
                          </div>

                          <div className="col-xl-4 col-lg-5">
                            <div className="form-groupAdd">
                              <button
                                className="gradientBG"
                                type="submit"
                                disabled={error || progressBar.show}
                              >
                                {t("buttons.create")}
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
