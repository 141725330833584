import React, { useEffect } from "react";
import { LoginComponent } from "../../components/auth/LoginComponent";
import { LoginAuth, Logo } from "../../assets/image-path";
import { FooterCoppyRight } from "../../components/common/FooterCoppyRight";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export const Login = () => {
  const navigate = useNavigate();
  const { userType } = useSelector((state) => state?.auth);

  useEffect(() => {
    if (userType !== "") {
      navigate("/dashboard");
    } // eslint-disable-next-line
  }, [userType]);

  return (
    <>
      <div className="authoMain">
        <div className="container-fluid">
          <div className="authContainer">
            <div className="logoPath">
              <img src={Logo} alt="Logo" />
            </div>
            <div className="row">
              <div className="col-xl-6 col-lg-5 d-none d-lg-block">
                <img src={LoginAuth} alt="Auth" className="img-fluid" />
              </div>
              <div className="col-xl-6 col-lg-7">
                <div className="AuthContent">
                  <LoginComponent />
                </div>
              </div>
            </div>
          </div>
          <FooterCoppyRight />
        </div>
      </div>
    </>
  );
};
