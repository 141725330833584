import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SideBar } from "../../../../components/common/SideBar";
import { Header } from "../../../../components/common/Header";

import {
  BlockBliperModalIcon,
  BlockUserIcon,
  CloseModalIcon,
  IgnoreModalIcon,
  PreviousIcon,
  UnblockUserIcon,
  UserProfile,
} from "../../../../assets/image-path";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import {
  blockReportedBliper,
  getBliperReportsById,
} from "../../../../store/dashboard/DashboardSlice";
import { generateNewToken, setLoading } from "../../../../store/auth/AuthSlice";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../../../components/dashboard/pagination/Pagination";
import { formatDateTime } from "../../../../utils/formatDate";
import { useTranslation } from "react-i18next";
import addCloudFrontBaseUrl from "../../../../utils/addCloudFrontBaseUrl";

export const ContentBliperDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [reasonModal, setReasonModal] = useState(false);
  const [blockModal, setBlockModal] = useState(false);
  const [selectedData, setSelectedData] = useState();
  //Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit] = useState(+process.env.REACT_APP_PER_PAGE_LIMIT);
  const bliperReportsList = useSelector(
    (state) => state?.dashboard?.bliperReportsById?.data
  );
  const count = useSelector(
    (state) => state?.dashboard?.bliperReportsById?.count
  );

  useEffect(() => {
    getBliperReportsByIdAPI();
    // eslint-disable-next-line
  }, [currentPage]);
  //  Get Bliper Reports By Id API
  const getBliperReportsByIdAPI = () => {
    dispatch(setLoading(true));
    dispatch(
      getBliperReportsById({
        pageLimit,
        currentPage,
        spamUserId: bliperReportsList[0]?.spamUserId?._id,
      })
    ).then((response) => {
      dispatch(setLoading(false));
      if (response.payload === "token_expired") {
        dispatch(generateNewToken()).then((res) => {
          if (res.payload.success) {
            getBliperReportsByIdAPI();
          }
        });
      }
    });
  };

  //   Block Reported Bliper
  const blockReportedBliperAPI = () => {
    const params = {
      userId: bliperReportsList[0]?.spamUserId?._id,
      status: bliperReportsList[0]?.status === "blocked" ? "ignore" : "blocked",
    };
    dispatch(setLoading(true));
    dispatch(blockReportedBliper(params)).then((response) => {
      dispatch(setLoading(false));
      if (response.payload === "token_expired") {
        dispatch(generateNewToken()).then((res) => {
          if (res.payload.success) {
            blockReportedBliperAPI();
          }
        });
      } else if (response.payload.success) {
        getBliperReportsByIdAPI();
        setBlockModal(false);
      }
    });
  };

  return (
    <>
      <div id="body_wapper" className="mainBody">
        <div className="wapper">
          <SideBar />
          <div className="main-container">
            <Header headingName={""} />
            <div className="containerBody">
              <div className="mainSelectBox">
                <div className="headingPart">
                  <h2>
                    <ul>
                      <li>{t("BlipersModeration.pageTitle")}</li>
                      <li> {">"} </li>
                      <li>{t("BlipersModerationDetail.pageTitle")}</li>
                    </ul>
                  </h2>
                  <div className="rightMarginPT">
                    <button className="backbTun" onClick={() => navigate(-1)}>
                      <img src={PreviousIcon} alt="Back" /> {t("buttons.back")}
                    </button>
                  </div>
                </div>
                <div className="basicContainer">
                  <div className="col-xl-7 col-lg-11">
                    <div className="tableViewDetail">
                      <div className="tableRound table-responsive">
                        <table className="table">
                          <tr>
                            <td>{t("BlipersModerationDetail.bliperName")} </td>
                            <td className="text-capitalize">
                              {bliperReportsList[0]?.spamUserId?.fullName}
                            </td>
                          </tr>
                          <tr>
                            <td>{t("BlipersModerationDetail.phone")}</td>
                            <td>
                              {bliperReportsList[0]?.spamUserId?.phoneNumber ===
                                null ||
                              bliperReportsList[0]?.spamUserId?.phoneNumber ===
                                ""
                                ? "N/A"
                                : bliperReportsList[0]?.spamUserId
                                    ?.countryCode +
                                  " " +
                                  bliperReportsList[0]?.spamUserId?.phoneNumber}
                            </td>
                          </tr>
                          <tr>
                            <td>{t("BlipersModerationDetail.email")}</td>
                            <td>
                              {bliperReportsList[0]?.spamUserId?.email ===
                                null ||
                              bliperReportsList[0]?.spamUserId?.email === ""
                                ? "N/A"
                                : bliperReportsList[0]?.spamUserId?.email}
                            </td>
                          </tr>

                          <tr>
                            <td>
                              {t("BlipersModerationDetail.verificationStatus")}
                            </td>
                            <td>
                              <span
                                className={
                                  bliperReportsList[0]?.spamUserId
                                    ?.verificationRequestStatus === "verified"
                                    ? "textGreen text-capitalize"
                                    : "textRed text-capitalize"
                                }
                              >
                                {
                                  bliperReportsList[0]?.spamUserId
                                    ?.verificationRequestStatus
                                }
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {t("BlipersModerationDetail.currentBlockStatus")}
                            </td>
                            <td>
                              <span
                                className={
                                  bliperReportsList[0]?.status === "blocked"
                                    ? "textRed"
                                    : "textGreen"
                                }
                              >
                                {bliperReportsList[0]?.status === "blocked"
                                  ? t("buttons.blocked")
                                  : t("buttons.ignore")}
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="idProofBox">
                        <div className="footBtnPt d-flex">
                          <button
                            className="col-xl-4 col-lg-6 col-md-8"
                            onClick={() => setBlockModal(true)}
                          >
                            <img
                              src={
                                bliperReportsList[0]?.status === "blocked"
                                  ? UnblockUserIcon
                                  : BlockUserIcon
                              }
                              alt="Icon"
                            />
                            {bliperReportsList[0]?.status === "blocked"
                              ? t("buttons.ignore")
                              : t("buttons.block")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tableCusMain mt-5">
                    <div className="table-responsive" id="style-5">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>{t("BlipersModerationDetail.srNo")}</th>
                            <th>{t("BlipersModerationDetail.reportedBy")}</th>
                            <th>{t("BlipersModerationDetail.dateTime")} </th>
                            <th>
                              {t("BlipersModerationDetail.requestForBlock")}
                            </th>
                            <th>{t("BlipersModerationDetail.reportTitle")}</th>
                            <th>{t("BlipersModerationDetail.reason")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {bliperReportsList?.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  #
                                  {parseInt(pageLimit) *
                                    parseInt(currentPage - 1) +
                                    parseInt(index + 1)}
                                </td>
                                <td className="text-capitalize proTableUser">
                                  <img
                                    src={
                                      item?.userId?.profileImage
                                        ? addCloudFrontBaseUrl(
                                            item?.userId?.profileImage
                                          )
                                        : UserProfile
                                    }
                                    alt=""
                                    className="profimePicBox"
                                  />
                                  {item?.userId?.fullName
                                    ? item?.userId?.fullName
                                    : "N/A"}
                                </td>
                                <td>{formatDateTime(item.createdDate)}</td>
                                <td
                                  className={
                                    item?.spamUserId?.isBlocked
                                      ? "textRed"
                                      : "textGreen"
                                  }
                                >
                                  {item?.spamUserId?.isBlocked ? "Yes" : "No"}
                                </td>
                                <td className=" text-capitalize">
                                  {item.title}
                                </td>
                                <td>
                                  <button
                                    className="viewBtton"
                                    onClick={() => {
                                      setSelectedData(item.discription);
                                      setReasonModal(true);
                                    }}
                                  >
                                    View
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    <Pagination
                      currentPage={currentPage}
                      totalCount={count}
                      currentPageCount={bliperReportsList?.length}
                      pageSize={pageLimit}
                      onPageChange={(page) => setCurrentPage(page)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {blockModal && (
        <Modal className="custumCssMCebter" show={blockModal}>
          <ModalHeader>
            <h6>&nbsp;</h6>
            <button
              type="button"
              className=""
              aria-label="Close"
              onClick={() => {
                setBlockModal(false);
              }}
            >
              <img src={CloseModalIcon} alt="Close Button" />
            </button>
          </ModalHeader>
          <ModalBody>
            <div className="centerModalContent">
              <div className="proConBox">
                <img
                  src={
                    bliperReportsList[0]?.status === "blocked"
                      ? IgnoreModalIcon
                      : BlockBliperModalIcon
                  }
                  alt="Delete"
                  className="img-fluid"
                />
              </div>
              <p>
                {t("BlipersModerationDetail.blockConfirmationPre")}{" "}
                {bliperReportsList[0]?.status === "blocked"
                  ? t("buttons.ignore")
                  : t("buttons.block")}{" "}
                {t("BlipersModerationDetail.blockConfirmationAfter")}
              </p>
              <div className="textConBoxBtn">
                <button
                  onClick={() => {
                    blockReportedBliperAPI();
                  }}
                >
                  <img
                    src={
                      bliperReportsList[0]?.status === "blocked"
                        ? UnblockUserIcon
                        : BlockUserIcon
                    }
                    alt="Icon"
                  />
                  {bliperReportsList[0]?.status === "blocked"
                    ? t("buttons.ignore")
                    : t("buttons.block")}
                </button>
                <button
                  className="cancelBtun"
                  onClick={() => setBlockModal(false)}
                >
                  {t("buttons.cancel")}
                </button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      )}

      {reasonModal && (
        <Modal className="custumCssMCebter" show={reasonModal}>
          <ModalHeader>
            <h6>Reason</h6>
            <button
              type="button"
              className=""
              aria-label="Close"
              onClick={() => {
                setReasonModal(false);
              }}
            >
              <img src={CloseModalIcon} alt="Close Button" />
            </button>
          </ModalHeader>
          <ModalBody>
            <div className="centerModalContent reasonmodalView" id="style-5">
              <p>{selectedData}</p>
            </div>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};
