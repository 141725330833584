import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import authService from "./AuthService";

const initialState = {
  apiLoading: false,
  isSuccess: false,
  isError: false,
  isLoading: false,
  message: "",
  accessToken: "",
  refreshToken: "",
  userType: "",
  loginUser: {},
};

// Admin Login
export const login = createAsyncThunk("login", async (data, thunkAPI) => {
  try {
    const response = await authService.login(data);
    return response;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Forgot Password
export const forgotPassword = createAsyncThunk(
  "Forgot Password",
  async (data, thunkAPI) => {
    try {
      const response = await authService.forgotPassword(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Reset Password
export const resetPasswordWithOtp = createAsyncThunk(
  "Reset Password With OTP",
  async (data, thunkAPI) => {
    try {
      const response = await authService.resetPasswordWithOtp(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Change Password
export const changePassword = createAsyncThunk(
  "Change Password",
  async (data, thunkAPI) => {
    try {
      const response = await authService.changePassword(data);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Grnerate New Token
export const generateNewToken = createAsyncThunk(
  "Generate New Token",
  async (_, thunkAPI) => {
    try {
      const response = await authService.generateNewToken();
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Logout Api
export const logoutToServer = createAsyncThunk(
  "Logout To Server",
  async (_, thunkAPI) => {
    try {
      const response = await authService.logoutToServer();
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
    logout: (state) => {
      state.accessToken = "";
      state.refreshToken = "";
      state.userType = "";
      state.loginUser = {};

    },

    setLoading: (state, action) => {
      state.apiLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.success) {
          state.loginUser = action.payload.data;
          state.accessToken = action.payload.accessToken;
          state.refreshToken = action.payload.refreshToken;
          state.userType =
            action.payload.userType === "1"
              ? "Admin"
              : action.payload.userType === "2"
                ? "Sub Admin"
                : "";
        } else {
          state.isSuccess = action.payload.success;
          state.isError = !action.payload.success;
          state.message = action.payload.message;
        }
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(forgotPassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = action.payload.success;
        state.isError = !action.payload.success;
        state.message = action.payload.message;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(resetPasswordWithOtp.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(resetPasswordWithOtp.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = action.payload.success;
        state.isError = !action.payload.success;
        state.message = action.payload.message;
      })
      .addCase(resetPasswordWithOtp.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(changePassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = action.payload.success;
        state.isError = !action.payload.success;
        state.message = action.payload.message;
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(generateNewToken.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(generateNewToken.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = action.payload.success;
        state.isError = !action.payload.success;
        state.message =action.payload.success? action.payload.message :"refresh_token_expired";
        state.accessToken = action.payload && action.payload.accessToken;
        state.refreshToken = action.payload && action.payload.refreshToken;
      })
      .addCase(generateNewToken.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = "refresh_token_expired";
      });
  },
});
export const { reset, logout, setLoading } = authSlice.actions;
export default authSlice.reducer;
