import React, { useState, useRef, useEffect } from "react";

export const OtpComponent = (props) => {
  const inputRefs = useRef([]);
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);

  //   set OTP value in formik function
  useEffect(() => {
    props.setFieldValue("otp", otp.join(""));
    // eslint-disable-next-line
  }, [otp]);

  // Function to handle change in OTP input fields
  const handleChange = (e, index) => {
    props.setFieldTouched("otp", true);
    const { value } = e.target;
    if (/^\d*$/.test(value)) {
      const updatedOtp = [...otp];
      updatedOtp[index] = value;
      setOtp(updatedOtp);

      // Move focus to the next input
      if (value !== "" && index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  // Function to handle key press in OTP input fields
  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && otp[index] === "" && index > 0) {
      // Move focus to the previous input and delete its value
      inputRefs.current[index - 1].focus();
      const updatedOtp = [...otp];
      updatedOtp[index - 1] = "";
      setOtp(updatedOtp);
    }
  };

  return (
    <div className="otp-component">
      <div className="otp-input-fields">
        {otp?.map((digit, index) => (
          <input
            onBlur={props.handleBlur}
            name="otp"
            type="text"
            key={index}
            value={digit}
            maxLength={1}
            onChange={(e) => handleChange(e, index)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            ref={(ref) => (inputRefs.current[index] = ref)}
          />
        ))}
      </div>
    </div>
  );
};
