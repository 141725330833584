import React from "react";
import { AppMail, AppWebsite } from "../../assets/image-path";

export const Help = () => {
  return (
    <>
      <div className="appMobile">
        <div className="aPPcontainerBody">
          <div className="container-fluid">
            <div className="contentP">
              <p>
                We're here to assist you! If you have any questions, concerns,
                feedback, or need assistance with anything related to Blips,
                please feel free to contact us via email.
              </p>
              <p>
                Our dedicated support team is committed to providing timely and
                helpful responses to your inquiries. Whether you're seeking help
                with your account, have questions about the app's features, or
                want to share your thoughts, we're here to listen.
              </p>
              <p>
                Please ensure you provide us with as much detail as possible
                about your query so that we can address your needs effectively.
                Our goal is to make your experience on Blips as enjoyable and
                smooth as possible.
              </p>
              <p>
                Thank you for using Blips, and we look forward to hearing from
                you!
              </p>

              <p>
                Warm regards,
                <br />
                <b className="pt-1 d-block">The Blips Team</b>
              </p>

              <ul className="socialApp">
                <li>
                  <img src={AppMail} alt="Mail" />
                  <span>
                    Email Us
                    <small>support@blips.com</small>
                  </span>
                </li>
                <li>
                  <img src={AppWebsite} alt="website" />
                  <span>
                    Website
                    <small>www.blips.com</small>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// import React, { useState, useEffect } from "react";

// export const Help = () => {
//   const [selectedFile, setSelectedFile] = useState(null);
//   const [resizedImage, setResizedImage] = useState(null);

//   const handleFileChange = (e) => {
//     const file = e.target.files[0];
//     setSelectedFile(file);
//   };

//   useEffect(() => {
//     const resizeImage = () => {
//       if (selectedFile) {
//         const reader = new FileReader();

//         reader.onload = (e) => {
//           const img = new Image();

//           // Set the image source to the data URL
//           img.src = e.target.result;

//           img.onload = () => {
//             const canvas = document.createElement("canvas");
//             const ctx = canvas.getContext("2d");

//             // Calculate the new height based on the aspect ratio
//             const aspectRatio = img.width / img.height;
//             const newHeight = 200 / aspectRatio;

//             // Set the canvas size to 200xnewHeight
//             canvas.width = 200;
//             canvas.height = newHeight;

//             // Draw the image on the canvas with the desired dimensions
//             ctx.drawImage(img, 0, 0, 200, newHeight);

//             // Convert the canvas content to a data URL
//             const resizedDataURL = canvas.toDataURL("image/jpeg");

//             // Set the resized image in the state
//             setResizedImage(resizedDataURL);
//           };
//         };

//         // Read the file as a data URL
//         reader.readAsDataURL(selectedFile);
//       }
//     };

//     resizeImage();
//   }, [selectedFile]);

//   return (
//     <div>
//       <input type="file" onChange={handleFileChange} accept="image/*" />
//       {resizedImage && (
//         <div>
//           <p>Resized Image:</p>
//           <img src={resizedImage} alt="Resized" />
//         </div>
//       )}
//     </div>
//   );
// };
