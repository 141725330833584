import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";

import storage from "redux-persist/lib/storage";
import { logout, reset } from "../../store/auth/AuthSlice";
import { dashboardLogoutReset } from "../../store/dashboard/DashboardSlice";

const AuthToastrError = () => {
  const dispatch = useDispatch();

  const { message, isError, isSuccess, isLoading } = useSelector(
    (state) => state?.auth
  );

  const Logout = () => {
    dispatch(logout());
    dispatch(dashboardLogoutReset());
    localStorage.clear();
    storage?.localStorage?.clear();
  };

  const textCapitalize = (str) => {
    const arr = str?.split(" ");
    for (var i = 0; i < arr?.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    const str2 = arr?.join(" ");
    return str2;
  };

  useEffect(() => {
    if (isError && !isSuccess) {
      if (message === "refresh_token_expired") {
        toastr.info("Session Expired", "Please Login Again");
        Logout();
      } else toastr.error("Error", textCapitalize(message));
    }
    if (!isError && isSuccess) {
      toastr.success("Success", textCapitalize(message));
    }

    dispatch(reset());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message, isError, isSuccess, isLoading]);
};

export default AuthToastrError;
