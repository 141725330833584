import React from "react";
import { useTranslation } from "react-i18next";
import { FileUpload, PlayButton } from "../../assets/image-path";
// import { FileFolderImg } from "./../../static/image-path";
const FilesDragAndDrop = ({
  setFieldValue,
  video,
  showVideo,
  inputRef,
  handleFileChange,
  setFieldTouched,
}) => {
  const drop = React.useRef(null);
  const { t } = useTranslation();
  React.useEffect(() => {
    const currentDrop = drop.current;
    currentDrop?.addEventListener("dragover", handleDragOver);
    currentDrop?.addEventListener("drop", handleDrop);

    return () => {
      currentDrop?.removeEventListener("dragover", handleDragOver);
      currentDrop?.removeEventListener("drop", handleDrop);
    };
    // eslint-disable-next-line
  }, []);

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const files = e.dataTransfer ? [...e.dataTransfer.files] : [];

    if (files && files.length) {
      setFieldValue("video", files[0]);
    }
  };

  return (
    <div ref={drop} className="FilesDragAndDrop">
      <label className="displayBlock" htmlFor="videoInput">
        <div className="videoTabbox">
          <img src={FileUpload} alt="Upload" />
          <p>
            {video ? (
              <button type="button" className="play-button" onClick={showVideo}>
                <img src={PlayButton} alt="Back" /> {t("buttons.play")}
              </button>
            ) : (
              t("formPlaceholder.addVideo")
            )}
            <span className="btnFile">
              {video
                ? t("formPlaceholder.chooseNewFile")
                : t("formPlaceholder.chooseFile")}
              <input
                className="isVisuallyHidden"
                id="videoInput"
                type="file"
                name="video"
                accept="video/*"
                onChange={(event) => {
                  setFieldTouched("video", true);
                  if (event.currentTarget.files.length > 0) {
                    setFieldValue("video", event.currentTarget.files[0]);
                    handleFileChange(event);
                  }
                }}
                ref={inputRef}
              />
            </span>
          </p>
        </div>
      </label>
    </div>
  );
};

export default FilesDragAndDrop;
