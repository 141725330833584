import React from "react";
import { useTranslation } from "react-i18next";

import { Header } from "../../../components/common/Header";
import { SideBar } from "../../../components/common/SideBar";

import { ContentManagementForm } from "../../../components/dashboard/ContentManagementForm";

export const ContentManagement = () => {
  const { t } = useTranslation();
  return (
    <>
      <div id="body_wapper" className="mainBody">
        <div className="wapper">
          <SideBar />
          <div className="main-container">
            <Header headingName={""} />
            <div className="containerBody">
              <div className="mainSelectBox">
                <div className="headingPart">
                  <h2>{t("contentManagementPage.title")}</h2>
                </div>

                <div className="basicContainer">
                  <ContentManagementForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
