import React, { useState } from "react";
import { Header } from "../../../components/common/Header";
import { SideBar } from "../../../components/common/SideBar";
import { useNavigate } from "react-router-dom";

import {
  PreviousIcon,
  LikeIcon,
  UnlikeIcon,
  CommentIcon,
  ReportIcon,
  CloseModalIcon,
  PlaceImgLarge01,
  PlayIcon,
  InactiveBlipModalIcon,
  ActiveBlipModalIcon,
  RemoveFromAnchorIcon,
  MarkAsAnchorIcon,
} from "../../../assets/image-path";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { formatDateTime } from "../../../utils/formatDate";
import VideoPlayer from "../../../components/dashboard/video-player/VideoPlayer";
import {
  getBlipsByBliperId,
  setPlaceBlips,
  updateAsAnchorFlag,
  updateBlipStatusById,
} from "../../../store/dashboard/DashboardSlice";
import { generateNewToken, setLoading } from "../../../store/auth/AuthSlice";
import addCloudFrontBaseUrl from "../../../utils/addCloudFrontBaseUrl";

export const BliperDetailsViewBlips = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [statusModal, setStatusModal] = useState(false);
  const [showVideo, setShowVideo] = useState();
  const [selectedBlip, setSelectedBlip] = useState();
  const [anchorActionModal, setAnchorActionModal] = useState(false);
  const { t } = useTranslation(); // Use the translation hook
  const [pageLimit] = useState(+process.env.REACT_APP_PER_PAGE_LIMIT);

  const placeBlips = useSelector((state) => state?.dashboard.placeBlips);
  const location = useSelector(
    (state) => state?.dashboard.placeBlips?.location
  );
  const blips = useSelector((state) => state?.dashboard.placeBlips?.blip);
  const bliperIdForViewBlip = useSelector(
    (state) => state?.dashboard?.bliperIdForViewBlip
  );
  console.log(blips);
  const handleBlipStatus = (item) => {
    const params = {
      id: item._id,
      status: item.status === "active" ? "inactive" : "active",
    };

    dispatch(setLoading(true));
    dispatch(updateBlipStatusById(params)).then((response) => {
      dispatch(setLoading(false));
      if (response.payload === "token_expired") {
        dispatch(generateNewToken()).then((res) => {
          if (res.payload.success) {
            handleBlipStatus(item);
          }
        });
      } else if (response.payload.success) {
        const updatedblip = blips.map((item) => {
          if (item._id === params.id) {
            return {
              ...item,
              status: item.status === "active" ? "inactive" : "active",
            };
          }
          return item;
        });
        dispatch(setPlaceBlips({ ...placeBlips, blip: updatedblip }));
        setStatusModal(false);
        setSelectedBlip();
        getBlipsByBliperIdAPI();
      }
    });
  };

  const updateAsAnchorFlagAPI = (item) => {
    const params = {
      id: item._id,
      isAnchor: item.isAnchor ? false : true,
    };

    dispatch(setLoading(true));
    dispatch(updateAsAnchorFlag(params)).then((response) => {
      dispatch(setLoading(false));
      if (response.payload === "token_expired") {
        dispatch(generateNewToken()).then((res) => {
          if (res.payload.success) {
            updateAsAnchorFlagAPI(item);
          }
        });
      } else if (response.payload.success) {
        const updatedblip = blips.map((item) => {
          if (item._id === params.id) {
            return {
              ...item,
              isAnchor: item.isAnchor ? false : true,
            };
          }
          return item;
        });
        dispatch(setPlaceBlips({ ...placeBlips, blip: updatedblip }));
        setAnchorActionModal(false);
        setSelectedBlip();
        getBlipsByBliperIdAPI();
      }
    });
  };
  // get Blips By Bliper Id
  const getBlipsByBliperIdAPI = () => {
    dispatch(setLoading(true));
    dispatch(
      getBlipsByBliperId({
        id: bliperIdForViewBlip,
        pageLimit,
        currentPage: 1,
        search: "",
        dateRange: { startDate: "", endDate: "" },
      })
    ).then((response) => {
      dispatch(setLoading(false));
      if (response.payload === "token_expired") {
        dispatch(generateNewToken()).then((res) => {
          if (res.payload.success) {
            getBlipsByBliperIdAPI();
          }
        });
      }
    });
  };

  function flowersCount(count) {
    if (count < 1000) {
      return count?.toString();
    } else if (count < 1000000) {
      return (count / 1000).toFixed(2) + "K";
    } else {
      return (count / 1000000).toFixed(2) + "M";
    }
  }
  return (
    <>
      <div id="body_wapper" className="mainBody">
        <div className="wapper">
          <SideBar />
          <div className="main-container">
            <Header headingName={""} />
            <div className="containerBody">
              <div className="mainSelectBox">
                <div className="headingPart">
                  <h2>
                    <ul>
                      <li>{t("blipersManagement.title")}</li>
                      <li> {">"} </li>
                      <li>{t("blipsByLocation.pageTitle")}</li>
                      <li> {">"} </li>
                      <li>{t("bliperDetail.blips")}</li>
                    </ul>
                  </h2>
                  <div className="rightMarginPT">
                    <button className="backbTun" onClick={() => navigate(-1)}>
                      <img src={PreviousIcon} alt="Back" />
                      {t("buttons.back")}
                    </button>
                  </div>
                </div>

                <div className="blipsboxContainer">
                  <h2 className="text-capitalize">
                    {location ? location : ""}{" "}
                    <span className="headingspanblip">
                      {blips?.length < 10 ? "0" + blips?.length : blips?.length}{" "}
                      {t("bliperDetail.blips")}
                    </span>
                  </h2>
                  <div className="row blipmacViewCus">
                    {blips?.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="col-xl-3 col-lg-6 col-md-12"
                        >
                          <div className="boxCusblips boxCusblipsview">
                            <div className="textboxblip">
                              <div className="boximgOver">
                                <div className="overfullBx">
                                  <img
                                    src={PlayIcon}
                                    alt=""
                                    onClick={(e) => {
                                      setSelectedBlip(item.originalLink);
                                      setShowVideo(true);
                                    }}
                                  />
                                </div>
                                <img
                                  src={
                                    item?.thumbnailUrl
                                      ? addCloudFrontBaseUrl(item.thumbnailUrl)
                                      : PlaceImgLarge01
                                  }
                                  alt="Place"
                                  className="blip_thumbnail"
                                />
                              </div>
                              <ul>
                                <li title="Like">
                                  <img src={LikeIcon} alt="Like" />{" "}
                                  <span>{flowersCount(item?.likeCount)}</span>
                                </li>
                                <li title="Dislike">
                                  <img src={UnlikeIcon} alt="Unlike" />{" "}
                                  <span>
                                    {flowersCount(item?.dislikeCount)}
                                  </span>
                                </li>
                                <li title="Comment">
                                  <img src={CommentIcon} alt="Comment" />{" "}
                                  <span>
                                    {flowersCount(item?.commentCount)}
                                  </span>
                                </li>
                                <li title="Report">
                                  <img src={ReportIcon} alt="Report" />{" "}
                                  <span>{flowersCount(item?.reportCount)}</span>
                                </li>
                              </ul>
                              <h5>{formatDateTime(item.filterDate)}</h5>
                              <h6>
                                <div className="selecotr-item">
                                  <input
                                    type="radio"
                                    id={"active" + item._id}
                                    className="selector-item_radio"
                                    checked={item?.status === "active" && true}
                                    onClick={(e) => {
                                      setSelectedBlip(item);
                                      setStatusModal(true);
                                    }}
                                    disabled={item?.status === "active" && true}
                                  />
                                  <label
                                    htmlFor={"active" + item._id}
                                    className={`selector-item_label ${
                                      item?.status === "active" &&
                                      "cursor-not-allowed"
                                    }`}
                                  >
                                    {t("buttons.active")}
                                  </label>
                                </div>
                                <div className="selecotr-item">
                                  <input
                                    type="radio"
                                    id={"inactive" + item._id}
                                    className="selector-item_radio"
                                    checked={item?.status !== "active" && true}
                                    disabled={item?.status !== "active" && true}
                                    onClick={(e) => {
                                      setSelectedBlip(item);
                                      setStatusModal(true);
                                    }}
                                  />
                                  <label
                                    htmlFor={"inactive" + item._id}
                                    className={`selector-item_label redbg ${
                                      item?.status !== "active" &&
                                      "cursor-not-allowed"
                                    }`}
                                  >
                                    {t("buttons.inactive")}
                                  </label>
                                </div>
                              </h6>
                              <h6 className="anchorBlipCheckBox">
                                Mark As Anchor Blip
                                <input
                                  type="checkbox"
                                  checked={item?.isAnchor}
                                  onChange={(e) => {
                                    setSelectedBlip(item);
                                    setAnchorActionModal(true);
                                  }}
                                />
                              </h6>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {statusModal && (
        <Modal className="custumCssMCebter" show={statusModal}>
          <ModalHeader>
            <h6>&nbsp;</h6>
            <button
              type="button"
              className=""
              aria-label="Close"
              onClick={() => {
                setStatusModal(false);
              }}
            >
              <img src={CloseModalIcon} alt="Close Button" />
            </button>
          </ModalHeader>
          <ModalBody>
            <div className="centerModalContent">
              <div className="proConBox">
                <img
                  src={
                    selectedBlip?.status === "active"
                      ? InactiveBlipModalIcon
                      : ActiveBlipModalIcon
                  }
                  alt=""
                  className="img-fluid"
                />
              </div>
              <p>
                {t("blipersBlip.statusConfirmationPre")}{" "}
                {selectedBlip?.status === "active"
                  ? t("buttons.inactive")
                  : t("buttons.active")}{" "}
                {t("blipersBlip.statusConfirmationAfter")}
              </p>
              <div className="textConBoxBtn">
                <button
                  className="gradientBG"
                  onClick={(e) => handleBlipStatus(selectedBlip)}
                >
                  {selectedBlip?.status === "active"
                    ? t("buttons.inactive")
                    : t("buttons.active")}
                </button>
                <button
                  className="cancelBtun"
                  onClick={() => setStatusModal(false)}
                >
                  {t("buttons.cancel")}
                </button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      )}

      {showVideo && (
        <>
          <VideoPlayer
            videoSrc={addCloudFrontBaseUrl(selectedBlip)}
            onClose={(e) => setShowVideo(false)}
          />
        </>
      )}

      {anchorActionModal && (
        <Modal className="custumCssMCebter" show={anchorActionModal}>
          <ModalHeader>
            <h6>&nbsp;</h6>
            <button
              type="button"
              className=""
              aria-label="Close"
              onClick={() => {
                setAnchorActionModal(false);
              }}
            >
              <img src={CloseModalIcon} alt="Close Button" />
            </button>
          </ModalHeader>
          <ModalBody>
            <div className="centerModalContent">
              <div className="proConBox">
                <img
                  src={
                    selectedBlip?.isAnchor
                      ? RemoveFromAnchorIcon
                      : MarkAsAnchorIcon
                  }
                  alt=""
                  className="img-fluid"
                />
              </div>
              <p>
                {selectedBlip?.isAnchor
                  ? t("blipersBlip.anchorBlipConfirmationFalse")
                  : t("blipersBlip.anchorBlipConfirmationTrue")}
              </p>
              <div className="textConBoxBtn">
                <button
                  className="gradientBG"
                  onClick={(e) => updateAsAnchorFlagAPI(selectedBlip)}
                >
                  {selectedBlip?.isAnchor
                    ? t("buttons.remove")
                    : t("buttons.yes")}
                </button>
                <button
                  className="cancelBtun"
                  onClick={() => setAnchorActionModal(false)}
                >
                  {t("buttons.cancel")}
                </button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};
