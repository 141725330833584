import React, { useEffect } from "react";
import { Header } from "../../components/common/Header";
import { SideBar } from "../../components/common/SideBar";
import {
  ActivceBlipIcon,
  ActivceSystemBlipIcon,
  AllBlipsIcon,
  AllSystemBlipIcon,
  DashActiveblips,
  DashInactiveblips,
  DeclineBlipersIcon,
  InactiveBlipIcon,
  inactiveSystemBlipIcon,
  TotalActive,
  UnverifiedBlipersIcon,
  VerificationReqIcon,
  VerifiedBlipersIcon,
} from "../../assets/image-path";
import { useDispatch, useSelector } from "react-redux";
import { generateNewToken, setLoading } from "../../store/auth/AuthSlice";
import {
  getDashboardData,
  setBlipCoinStatusKey,
  setBliperStatusKey,
  setVeri_StatusKey,
} from "../../store/dashboard/DashboardSlice";
import { useNavigate } from "react-router-dom";
export const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dashboardData = useSelector((state) => state?.dashboard?.dashboardData);
  // console.log(dashboardData);
  useEffect(() => {
    getDashboardAPI();
    // eslint-disable-next-line
  }, []);
  const getDashboardAPI = () => {
    dispatch(setLoading(true));
    dispatch(getDashboardData()).then((response) => {
      dispatch(setLoading(false));
      if (response.payload === "token_expired") {
        dispatch(generateNewToken()).then((res) => {
          if (res.payload.success) {
            getDashboardAPI();
          }
        });
      } else if (response.payload?.success) {
      }
    });
  };
  function formatNumber(number) {
    if (number < 10) {
      return "0" + number;
    } else {
      return number?.toString();
    }
  }
  return (
    <>
      <div id="body_wapper" className="mainBody">
        <div className="wapper">
          <SideBar />
          <div className="main-container">
            <Header headingName={""} />
            <div className="containerBody">
              <h2 className="mainHeadingDash">Dashboard</h2>
              <div className="ser-part">
                {/* Blipers */}
                <h5 className="mt-3">Blipers</h5>
                <div className="row">
                  <div className="col-xl-4 col-lg-6 col-md-6 ">
                    <div
                      role="button"
                      className="ser-box"
                      onClick={(e) => {
                        dispatch(setBliperStatusKey(""));
                        navigate("/blipers-management");
                      }}
                    >
                      <div className="head-sec">
                        <div className="img-bg ml-auto">
                          <img src={TotalActive} alt="" />
                        </div>
                        <h6>
                          <span>Total Blipers</span>
                          <div className="bg-section">
                            {formatNumber(
                              dashboardData?.blippers?.totalBlippers
                            )}
                          </div>
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-6 col-md-6 ">
                    <div
                      role="button"
                      className="ser-box"
                      onClick={(e) => {
                        dispatch(setBliperStatusKey("active"));
                        navigate("/blipers-management");
                      }}
                    >
                      <div className="head-sec img-active">
                        <div className="img-bg ml-auto">
                          <img src={DashActiveblips} alt="" />
                        </div>
                        <h6>
                          <span>Active Blipers</span>
                          <div className="bg-section">
                            {formatNumber(
                              dashboardData?.blippers?.activeBlippers
                            )}
                          </div>
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-6 col-md-6 ">
                    <div
                      role="button"
                      className="ser-box"
                      onClick={(e) => {
                        dispatch(setBliperStatusKey("deactive"));
                        navigate("/blipers-management");
                      }}
                    >
                      <div className="head-sec img-inactive">
                        <div className="img-bg ml-auto">
                          <img src={DashInactiveblips} alt="" />
                        </div>
                        <h6>
                          <span>Inactive Blipers</span>
                          <div className="bg-section">
                            {formatNumber(
                              dashboardData?.blippers?.deactiveBlippers
                            )}
                          </div>
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Blips */}
                {/* <h5 className="mt-3">Blips</h5>
                <div className="row">
                  <div className="col-xl-4 col-lg-6 col-md-6 ">
                    <div className="ser-box">
                      <div className="head-sec">
                        <div className="img-bg ml-auto">
                          <img src={AllBlipsIcon} alt="" />
                        </div>
                        <h6>
                          <span>Total Blips</span>
                          <div className="bg-section">
                            {formatNumber(dashboardData?.blips?.totalBlips)}
                          </div>
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-6 col-md-6 ">
                    <div className="ser-box">
                      <div className="head-sec img-active">
                        <div className="img-bg ml-auto">
                          <img src={ActivceBlipIcon} alt="" />
                        </div>
                        <h6>
                          <span>Active Blips</span>
                          <div className="bg-section">
                            {formatNumber(dashboardData?.blips?.activeBlips)}
                          </div>
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-6 col-md-6 ">
                    <div className="ser-box">
                      <div className="head-sec img-inactive">
                        <div className="img-bg ml-auto">
                          <img src={InactiveBlipIcon} alt="" />
                        </div>
                        <h6>
                          <span>Inactive Blips</span>
                          <div className="bg-section">
                            {formatNumber(dashboardData?.blips?.deactiveBlips)}
                          </div>
                        </h6>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* Verification Requests */}
                <h5 className="mt-3">Verification Requests</h5>
                <div className="row">
                  <div className="col-xl-4 col-lg-6 col-md-6 ">
                    <div
                      role="button"
                      className="ser-box"
                      onClick={(e) => {
                        dispatch(setVeri_StatusKey("requested"));
                        navigate("/verification-request");
                      }}
                    >
                      <div className="head-sec">
                        <div className="img-bg ml-auto">
                          <img src={VerificationReqIcon} alt="" />
                        </div>
                        <h6>
                          <span>New Requests</span>
                          <div className="bg-section">
                            {formatNumber(
                              dashboardData?.verificationRequestBlippers
                                ?.requestedBlippers
                            )}
                          </div>
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-6 col-md-6 ">
                    <div
                      className="ser-box"
                      role="button"
                      onClick={(e) => {
                        dispatch(setVeri_StatusKey("verified"));
                        navigate("/verification-request");
                      }}
                    >
                      <div className="head-sec img-active">
                        <div className="img-bg ml-auto">
                          <img src={VerifiedBlipersIcon} alt="" />
                        </div>
                        <h6>
                          <span>Verified Blipers</span>
                          <div className="bg-section">
                            {formatNumber(
                              dashboardData?.verificationRequestBlippers
                                ?.verifiedBlippers
                            )}
                          </div>
                        </h6>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-xl-4 col-lg-6 col-md-6 ">
                    <div className="ser-box">
                      <div className="head-sec img-inactive">
                        <div className="img-bg ml-auto">
                          <img src={UnverifiedBlipersIcon} alt="" />
                        </div>
                        <h6>
                          <span>Unverified Blipers</span>
                          <div className="bg-section">
                            {formatNumber(
                              dashboardData?.verificationRequestBlippers
                                ?.unverifiedBlippers
                            )}
                          </div>
                        </h6>
                      </div>
                    </div>
                  </div> */}

                  <div className="col-xl-4 col-lg-6 col-md-6 ">
                    <div
                      className="ser-box"
                      role="button"
                      onClick={(e) => {
                        dispatch(setVeri_StatusKey("declined"));
                        navigate("/verification-request");
                      }}
                    >
                      <div className="head-sec img-inactive">
                        <div className="img-bg ml-auto">
                          <img src={DeclineBlipersIcon} alt="" />
                        </div>
                        <h6>
                          <span>Declined Blipers</span>
                          <div className="bg-section">
                            {formatNumber(
                              dashboardData?.verificationRequestBlippers
                                ?.declinedBlippers
                            )}
                          </div>
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>

                {/* System Blips */}
                <h5 className="mt-3">System Blips</h5>
                <div className="row">
                  <div className="col-xl-4 col-lg-6 col-md-6 ">
                    <div
                      role="button"
                      className="ser-box"
                      onClick={(e) => {
                        dispatch(setBlipCoinStatusKey(""));
                        navigate("/blip-coins");
                      }}
                    >
                      <div className="head-sec">
                        <div className="img-bg ml-auto">
                          <img src={AllSystemBlipIcon} alt="" />
                        </div>
                        <h6>
                          <span>Total System Blips</span>
                          <div className="bg-section">
                            {formatNumber(
                              dashboardData?.systemBlips?.totalSystemBlips
                            )}
                          </div>
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-6 col-md-6 ">
                    <div
                      role="button"
                      className="ser-box"
                      onClick={(e) => {
                        dispatch(setBlipCoinStatusKey("active"));
                        navigate("/blip-coins");
                      }}
                    >
                      <div className="head-sec img-active">
                        <div className="img-bg ml-auto">
                          <img src={ActivceSystemBlipIcon} alt="" />
                        </div>
                        <h6>
                          <span>Active System Blips</span>
                          <div className="bg-section">
                            {formatNumber(
                              dashboardData?.systemBlips?.activeSystemBlips
                            )}
                          </div>
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-6 col-md-6 ">
                    <div
                      role="button"
                      className="ser-box"
                      onClick={(e) => {
                        dispatch(setBlipCoinStatusKey("inactive"));
                        navigate("/blip-coins");
                      }}
                    >
                      <div className="head-sec img-inactive">
                        <div className="img-bg ml-auto">
                          <img src={inactiveSystemBlipIcon} alt="" />
                        </div>
                        <h6>
                          <span>Inactive System Blips</span>
                          <div className="bg-section">
                            {formatNumber(
                              dashboardData?.systemBlips?.deactiveSystemBlips
                            )}
                          </div>
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
