import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AppStoreIcon, GooglePlayIcon } from "../../assets/image-path";

const AppDownload = () => {
  const [appStoreUrl, setAppStoreUrl] = useState("");
  const [playStoreUrl, setPlayStoreUrl] = useState("");
  useEffect(() => {
    fetch(process.env.REACT_APP_BACKEND_URL + "/admin/getAppLinks")
      .then((response) => response.json())
      .then((data) => {
        if (data?.success) {
          setAppStoreUrl(data?.data?.app_store_link);
          setPlayStoreUrl(data?.data?.play_store_link);
        }
      })
      .catch((error) => console.error(error));
  }, []);

  return (
    <>
      <div className="appMobile">
        <div className="aPPcontainerBody">
          <div className="container-fluid">
            <div className="contentP text-center">
              <p className="text-center"> Download App </p>
              <Link to={playStoreUrl}>
                <img src={GooglePlayIcon} width={400} height={120} alt="" />
              </Link>
              <Link to={appStoreUrl}>
                <img src={AppStoreIcon} width={400} alt="" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppDownload;
