export const formatDate = (timestampStr) => {
  // Create a Date object from the timestamp string
  const timestamp = new Date(timestampStr);

  // Define an array of month names
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Extract the day, month, and year from the Date object
  const day = timestamp.getDate();
  const month = monthNames[timestamp.getMonth()];
  const year = timestamp.getFullYear();

  // Format the date in the desired format
  const formattedDate = `${day}/${month}/${year}`;

  // Print the formatted date
  return formattedDate;
};

export const formatDateTime = (inputDateString) => {
  // Create a Date object from the input string
  const inputDate = new Date(inputDateString);

  // Define an array of month names for formatting
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Get the day of the week (e.g., "Sat")
  const dayOfWeek = inputDate.toLocaleDateString("en-US", { weekday: "short" });

  // Get the month name (e.g., "Jun")
  const monthName = monthNames[inputDate.getMonth()];

  // Get the day of the month (e.g., 17)
  const dayOfMonth = inputDate.getDate();

  // Get the year (e.g., 2023)
  const year = inputDate.getFullYear();

  // Get the time in HH:mm:ss format (e.g., 05:30:46)
  const time = inputDate.toLocaleTimeString("en-US");

  // Combine all the parts to create the formatted date string
  const formattedDateString = `${dayOfWeek} ${monthName} ${dayOfMonth} ${year} ${time}`;

  return formattedDateString;
};
