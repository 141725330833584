import React, { useState } from "react";

import {
  LikeIcon,
  UnlikeIcon,
  CommentIcon,
  ReportIcon,
  CloseModalIcon,
  PlaceImgLarge01,
  PlayIcon,
  InactiveBlipModalIcon,
  ActiveBlipModalIcon,
  RemoveFromAnchorIcon,
  MarkAsAnchorIcon,
  LocationSVGIcon,
} from "../../../assets/image-path";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import VideoPlayer from "../../../components/dashboard/video-player/VideoPlayer";
import {
  updateAsAnchorFlag,
  updateBlipStatusById,
} from "../../../store/dashboard/DashboardSlice";
import { generateNewToken, setLoading } from "../../../store/auth/AuthSlice";
import addCloudFrontBaseUrl from "../../../utils/addCloudFrontBaseUrl";

const BlipCard = ({ blips, reCallGetBlipsAPI }) => {
  const dispatch = useDispatch();
  const [statusModal, setStatusModal] = useState(false);
  const [showVideo, setShowVideo] = useState();
  const [selectedBlip, setSelectedBlip] = useState();
  const [anchorActionModal, setAnchorActionModal] = useState(false);
  const { t } = useTranslation(); // Use the translation hook

  const handleBlipStatus = (item) => {
    const params = {
      id: item._id,
      status: item.status === "active" ? "inactive" : "active",
    };

    dispatch(setLoading(true));
    dispatch(updateBlipStatusById(params)).then((response) => {
      dispatch(setLoading(false));
      if (response.payload === "token_expired") {
        dispatch(generateNewToken()).then((res) => {
          if (res.payload.success) {
            handleBlipStatus(item);
          }
        });
      } else if (response.payload.success) {
        setStatusModal(false);
        setSelectedBlip();
        reCallGetBlipsAPI();
      }
    });
  };

  const updateAsAnchorFlagAPI = (item) => {
    const params = {
      id: item._id,
      isAnchor: item.isAnchor ? false : true,
    };

    dispatch(setLoading(true));
    dispatch(updateAsAnchorFlag(params)).then((response) => {
      dispatch(setLoading(false));
      if (response.payload === "token_expired") {
        dispatch(generateNewToken()).then((res) => {
          if (res.payload.success) {
            updateAsAnchorFlagAPI(item);
          }
        });
      } else if (response.payload.success) {
        setAnchorActionModal(false);
        setSelectedBlip();
        reCallGetBlipsAPI();
      }
    });
  };

  function flowersCount(count) {
    if (count < 1000) {
      return count?.toString();
    } else if (count < 1000000) {
      return (count / 1000).toFixed(2) + "K";
    } else {
      return (count / 1000000).toFixed(2) + "M";
    }
  }

  const formatDateForCard = (UTCDate) => {
    const date = new Date(UTCDate);
    const options = {
      month: "short",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      // hour12: true,
    };

    const localDate = date.toLocaleString(undefined, options);
    // Convert AM/PM to uppercase
    const formattedDate = localDate.replace(/\b(?:am|pm)\b/g, (match) =>
      match.toUpperCase()
    );
    return formattedDate;
  };
  const array = ["public", "follor", "friends"];
  return (
    <>
      <div className="row blipmacViewCus">
        {blips?.map((item, index) => {
          return (
            <div key={index} className="col-xl-3 col-lg-6 col-md-12">
              <div className="boxCusblips boxCusblipsview">
                <div className="textboxblip">
                  <div className="boximgOver">
                    <div className="overfullBx">
                      <img
                        src={PlayIcon}
                        alt=""
                        onClick={(e) => {
                          setSelectedBlip(item.originalLink);
                          setShowVideo(true);
                        }}
                      />
                      <span className="blipTitle">{item?.title}</span>
                    </div>
                    <img
                      src={
                        item?.thumbnailUrl
                          ? addCloudFrontBaseUrl(item.thumbnailUrl)
                          : PlaceImgLarge01
                      }
                      alt="Place"
                      className="blip_thumbnail"
                    />
                  </div>
                  <ul>
                    <li title="Like">
                      <img src={LikeIcon} alt="Like" />{" "}
                      <span>{flowersCount(item?.likeCount)}</span>
                    </li>
                    <li title="Dislike">
                      <img src={UnlikeIcon} alt="Unlike" />{" "}
                      <span>{flowersCount(item?.dislikeCount)}</span>
                    </li>
                    <li title="Comment">
                      <img src={CommentIcon} alt="Comment" />{" "}
                      <span>{flowersCount(item?.commentCount)}</span>
                    </li>
                    <li title="Report">
                      <img src={ReportIcon} alt="Report" />{" "}
                      <span>{flowersCount(item?.reportCount)}</span>
                    </li>
                  </ul>

                  <h5>{formatDateForCard(item.filterDate)}</h5>
                  <h5>
                    {item?.visibilityTo?.map((itm, inx) => {
                      return (
                        <span key={inx} className="text-capitalize">
                          {itm}
                          {item?.visibilityTo?.length > parseInt(inx + 1) &&
                            " |"}
                        </span>
                      );
                    })}
                  </h5>

                  <h5 className="text-capitalize textLargeAdd">
                    <img src={LocationSVGIcon} alt="" /> {item.address}
                  </h5>

                  <h6>
                    <div className="selecotr-item">
                      <input
                        type="radio"
                        id={"active" + item._id}
                        className="selector-item_radio"
                        checked={item?.status === "active" && true}
                        onClick={(e) => {
                          setSelectedBlip(item);
                          setStatusModal(true);
                        }}
                        disabled={item?.status === "active" && true}
                        readOnly
                      />
                      <label
                        htmlFor={"active" + item._id}
                        className={`selector-item_label ${
                          item?.status === "active" && "cursor-not-allowed"
                        }`}
                      >
                        {t("buttons.active")}
                      </label>
                    </div>
                    <div className="selecotr-item">
                      <input
                        type="radio"
                        id={"inactive" + item._id}
                        className="selector-item_radio"
                        checked={item?.status !== "active" && true}
                        disabled={item?.status !== "active" && true}
                        onClick={(e) => {
                          setSelectedBlip(item);
                          setStatusModal(true);
                        }}
                        readOnly
                      />
                      <label
                        htmlFor={"inactive" + item._id}
                        className={`selector-item_label redbg ${
                          item?.status !== "active" && "cursor-not-allowed"
                        }`}
                      >
                        {t("buttons.inactive")}
                      </label>
                    </div>
                  </h6>
                  <h6 className="anchorBlipCheckBox">
                    Mark As Anchor Blip
                    <input
                      type="checkbox"
                      checked={item?.isAnchor}
                      onChange={(e) => {
                        setSelectedBlip(item);
                        setAnchorActionModal(true);
                      }}
                    />
                  </h6>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {statusModal && (
        <Modal className="custumCssMCebter" show={statusModal}>
          <ModalHeader>
            <h6>&nbsp;</h6>
            <button
              type="button"
              className=""
              aria-label="Close"
              onClick={() => {
                setStatusModal(false);
              }}
            >
              <img src={CloseModalIcon} alt="Close Button" />
            </button>
          </ModalHeader>
          <ModalBody>
            <div className="centerModalContent">
              <div className="proConBox">
                <img
                  src={
                    selectedBlip?.status === "active"
                      ? InactiveBlipModalIcon
                      : ActiveBlipModalIcon
                  }
                  alt=""
                  className="img-fluid"
                />
              </div>
              <p>
                {t("blipersBlip.statusConfirmationPre")}{" "}
                {selectedBlip?.status === "active"
                  ? t("buttons.inactive")
                  : t("buttons.active")}{" "}
                {t("blipersBlip.statusConfirmationAfter")}
              </p>
              <div className="textConBoxBtn">
                <button
                  className="gradientBG"
                  onClick={(e) => handleBlipStatus(selectedBlip)}
                >
                  {selectedBlip?.status === "active"
                    ? t("buttons.inactive")
                    : t("buttons.active")}
                </button>
                <button
                  className="cancelBtun"
                  onClick={() => setStatusModal(false)}
                >
                  {t("buttons.cancel")}
                </button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      )}

      {showVideo && (
        <>
          <VideoPlayer
            videoSrc={addCloudFrontBaseUrl(selectedBlip)}
            onClose={(e) => setShowVideo(false)}
          />
        </>
      )}

      {anchorActionModal && (
        <Modal className="custumCssMCebter" show={anchorActionModal}>
          <ModalHeader>
            <h6>&nbsp;</h6>
            <button
              type="button"
              className=""
              aria-label="Close"
              onClick={() => {
                setAnchorActionModal(false);
              }}
            >
              <img src={CloseModalIcon} alt="Close Button" />
            </button>
          </ModalHeader>
          <ModalBody>
            <div className="centerModalContent">
              <div className="proConBox">
                <img
                  src={
                    selectedBlip?.isAnchor
                      ? RemoveFromAnchorIcon
                      : MarkAsAnchorIcon
                  }
                  alt=""
                  className="img-fluid"
                />
              </div>
              <p>
                {selectedBlip?.isAnchor
                  ? t("blipersBlip.anchorBlipConfirmationFalse")
                  : t("blipersBlip.anchorBlipConfirmationTrue")}
              </p>
              <div className="textConBoxBtn">
                <button
                  className="gradientBG"
                  onClick={(e) => updateAsAnchorFlagAPI(selectedBlip)}
                >
                  {selectedBlip?.isAnchor
                    ? t("buttons.remove")
                    : t("buttons.yes")}
                </button>
                <button
                  className="cancelBtun"
                  onClick={() => setAnchorActionModal(false)}
                >
                  {t("buttons.cancel")}
                </button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default BlipCard;
