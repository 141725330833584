// VideoPlayer.js
import React from "react";
import { CloseModalIcon } from "../../../assets/image-path";
import styles from "./styles.module.css";

const VideoPlayer = ({ videoSrc, onClose }) => {
  return (
    <div className={styles["video-player-overlay"]}>
      <div className={styles["video-player"]}>
        <button className={styles["close-button"]} onClick={(e) => onClose()}>
          <img
            src={CloseModalIcon}
            alt="Close Button"
            onClick={(e) => onClose()}
          />
        </button>
        <video controls autoPlay>
          <source src={videoSrc} />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};

export default VideoPlayer;
