import React from "react";

import { ForgotAuth, Logo } from "../../assets/image-path";

import { ForgotPasswordForm } from "../../components/auth/ForgotPasswordForm";
import { FooterCoppyRight } from "../../components/common/FooterCoppyRight";

export const ForgotPassword = () => {
  return (
    <>
      <div className="authoMain">
        <div className="container-fluid">
          <div className="authContainer">
            <div className="logoPath">
              <img src={Logo} alt="Logo" />
            </div>
            <div className="row">
              <div className="col-xl-6 col-lg-5 d-none d-lg-block">
                <img src={ForgotAuth} alt="Auth" className="img-fluid" />
              </div>
              <div className="col-xl-6 col-lg-7">
                <div className="AuthContent">
                  <ForgotPasswordForm />
                </div>
              </div>
            </div>
          </div>
          <FooterCoppyRight />
        </div>
      </div>
    </>
  );
};
