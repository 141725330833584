import React from "react";
import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";

//  images
import { EyelIcon, KeyIcon, OffEyelIcon } from "../../assets/image-path";
import {
  changePassword,
  generateNewToken,
  setLoading,
} from "../../store/auth/AuthSlice";

export const ChangePasswordForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  //  Change Password API
  const changePasswordAPI = (values) => {
    const params = {
      oldPassword: values.old_password,
      newPassword: values.password,
    };
    dispatch(setLoading(true));
    dispatch(changePassword(params)).then((response) => {
      dispatch(setLoading(false));
      if (response.payload === "token_expired") {
        dispatch(generateNewToken()).then((res) => {
          if (res.payload.success) {
            changePasswordAPI(values);
          }
        });
      } else if (response.payload.success) {
        navigate(-1);
      }
    });
  };

  return (
    <>
      <Formik
        initialValues={{
          old_password: "",
          password: "",
          confirm_password: "",
          confirm_passwordShow: false,
          passwordShow: false,
          old_passwordShow: false,
        }}
        validationSchema={Yup.object().shape({
          old_password: Yup.string().required(
            t("formErrorMsg.oldPasswordRequired")
          ),
          password: Yup.string()
            .required(t("formErrorMsg.newPasswordRequired"))
            .matches(
              /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
              t("formErrorMsg.passwordMatchesValidation")
            ),
          confirm_password: Yup.string()
            .required(t("formErrorMsg.confirmPasswordRequired"))
            .oneOf(
              [Yup.ref("password"), null],
              t("formErrorMsg.confirmPasswordMatchValidation")
            ),
        })}
        onSubmit={(values) => {
          changePasswordAPI(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <form className="basicInformation" onSubmit={handleSubmit}>
            <div className="row col-xl-4 col-lg-6">
              <div className="col-xl-12">
                <div className="form-group">
                  <label>{t("formLabel.oldPassword")}</label>
                  <div className="inputPossion">
                    <img
                      onClick={(e) =>
                        setFieldValue(
                          "old_passwordShow",
                          !values.old_passwordShow
                        )
                      }
                      src={values.old_passwordShow ? OffEyelIcon : EyelIcon}
                      alt="Eye"
                      className="eyeHide"
                    />
                    <input
                      type={values.old_passwordShow ? "text" : "password"}
                      placeholder={t("formPlaceholder.oldPassword")}
                      className="form-control passwordfield"
                      name="old_password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.old_password}
                    />
                    <img src={KeyIcon} alt="Key" />
                  </div>

                  {errors.old_password && touched.old_password && (
                    <div className="error-msg">{errors.old_password}</div>
                  )}
                </div>
              </div>
              <div className="col-xl-12">
                <div className="form-group">
                  <label>{t("formLabel.newPassword")}</label>
                  <div className="inputPossion">
                    <img
                      onClick={(e) =>
                        setFieldValue("passwordShow", !values.passwordShow)
                      }
                      src={values.passwordShow ? OffEyelIcon : EyelIcon}
                      alt="Eye"
                      className="eyeHide"
                    />
                    <input
                      type={values.passwordShow ? "text" : "password"}
                      placeholder={t("formPlaceholder.newPassword")}
                      className="form-control passwordfield"
                      name="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                    />
                    <img src={KeyIcon} alt="Key" />
                  </div>

                  {errors.password && touched.password && (
                    <div className="error-msg">{errors.password}</div>
                  )}
                </div>
              </div>
              <div className="col-xl-12">
                <div className="form-group">
                  <label>{t("formLabel.confirmPassword")}</label>
                  <div className="inputPossion">
                    <img
                      onClick={(e) =>
                        setFieldValue(
                          "confirm_passwordShow",
                          !values.confirm_passwordShow
                        )
                      }
                      src={values.confirm_passwordShow ? OffEyelIcon : EyelIcon}
                      alt="Eye"
                      className="eyeHide"
                    />
                    <input
                      type={values.confirm_passwordShow ? "text" : "password"}
                      placeholder={t("formPlaceholder.confirmPassword")}
                      className="form-control passwordfield"
                      name="confirm_password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.confirm_password}
                    />
                    <img src={KeyIcon} alt="Key" />
                  </div>

                  {errors.confirm_password && touched.confirm_password && (
                    <div className="error-msg">{errors.confirm_password}</div>
                  )}
                </div>
              </div>
              <div className="col-xl-12">
                <div className="form-groupAdd">
                  <button className="gradientBG" type="submit">
                    {t("buttons.updatePassword")}
                  </button>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};
