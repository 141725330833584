import React, { useState } from "react";
import { useLoadScript, Autocomplete } from "@react-google-maps/api";
const libraries = ["places"];
const AddressInput = (props) => {
  const [autocomplete, setAutocomplete] = useState();

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API,
    libraries: libraries,
  });
  if (!isLoaded) return <div className="App">Loading...</div>;

  const onLoad = (data) => {
    setAutocomplete(data);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      autocomplete?.setFields(["address_component", "geometry"]);
      const address = autocomplete.getPlace();

      const matches = address?.address_components?.filter((address_component) =>
        ["locality", "colloquial_area"].some(
          (word) => ~address_component?.types?.indexOf(word)
        )
      );
      if (!matches || !matches.length) {
        console.log("Could not resolve city name.");
      } else {
        props?.setFieldValue("city", matches[0].short_name);
      }

      //
      var lat = address.geometry.location.lat();
      var lng = address.geometry.location.lng();
      const formatedAddress = address?.formatted_address;

      props?.setFieldValue("latitude", lat);
      props?.setFieldValue("longitude", lng);

      let address1 = "";
      for (const component of address?.address_components) {
        const componentType = component?.types[0];
        switch (componentType) {
          case "street_number":
          case "premise":
          case "route":
          case "sublocality_level_1":
          case "sublocality":
          case "political":
            address1 += `${component.long_name}, `;
            break;

          case "locality":
          case "administrative_area_level_1":
            address1 += `${component.long_name}, `;
            break;

          case "country":
            address1 += `${component.long_name}`;
            break;

          case "postal_code":
            address1 += `, ${component.long_name}`;
            break;
        }
      }

      // Trim any trailing commas and spaces
      address1 = address1.replace(/, $/, "");
      if (formatedAddress) {
        props?.setFieldValue("address", address?.name + ", " + formatedAddress);
      } else {
        props?.setFieldValue("address", address1);
      }
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };
  return (
    <Autocomplete
      style={{ zIndex: 1500 }}
      onLoad={onLoad}
      onPlaceChanged={onPlaceChanged}
    >
      {props.children}
    </Autocomplete>
  );
};

export default AddressInput;
