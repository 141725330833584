import React, { useEffect, useRef, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Header } from "../../../components/common/Header";
import { SideBar } from "../../../components/common/SideBar";
import { useLocation, useNavigate } from "react-router-dom";
import { Dropdown, Modal, ModalBody, ModalHeader } from "react-bootstrap";
import {
  ViewIcon,
  SearchIcon,
  ReloadIcon,
  CloseModalIcon,
  DropDownTBIcon,
  UnVerify,
  UserProfile,
  verifiedActionModalIcon,
  HistoryIcon,
} from "../../../assets/image-path";
import { useDispatch, useSelector } from "react-redux";
import { generateNewToken, setLoading } from "../../../store/auth/AuthSlice";
import {
  getBliperById,
  getBlipersVerificationHistory,
  getBlipersVerificationRequest,
  setVeri_StatusKey,
  updateBliperVerificationStatusById,
} from "../../../store/dashboard/DashboardSlice";
import Pagination from "../../../components/dashboard/pagination/Pagination";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../../utils/formatDate";
import addCloudFrontBaseUrl from "../../../utils/addCloudFrontBaseUrl";

export const VerificationRequest = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isInitialRender = useRef(true);
  const [verifyStatus, setVerifyStatus] = useState("Verified");
  const [unVerify, setUnVerify] = useState(false);
  const [historyModal, setHistoryModal] = useState(false);
  const [declineReason, setDeclineReason] = useState(false);

  const [selectedData, setSelectedData] = useState();
  const [search, setSearch] = useState("");
  const [verifyFilter, setVerifyFilter] = useState(null);
  //Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit] = useState(+process.env.REACT_APP_PER_PAGE_LIMIT);

  const veri_StatusKey = useSelector(
    (state) => state?.dashboard?.veri_StatusKey
  );
  const { data: blipersVerificationRequest, count } = useSelector(
    (state) => state?.dashboard?.blipersVerificationRequests
  );

  useEffect(() => {
    setVerifyFilter(veri_StatusKey);
  }, [veri_StatusKey]);
  const prevRoute = useLocation();

  // Fetch Sub Admins whenever the currentPage or search changes
  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    if (search === "" || search?.length > 2) {
      getBlipersVerificationRequestsAPI();
    } // eslint-disable-next-line
  }, [currentPage, search, verifyFilter]);

  const getBlipersVerificationRequestsAPI = () => {
    const params = { pageLimit, currentPage, search, verifyFilter };
    dispatch(setLoading(true));
    dispatch(getBlipersVerificationRequest(params)).then((response) => {
      dispatch(setLoading(false));
      if (response.payload === "token_expired") {
        dispatch(generateNewToken()).then((res) => {
          if (res.payload.success) {
            getBlipersVerificationRequestsAPI();
          }
        });
      }
    });
  };

  const getBlipersVerificationHistoryAPI = (id) => {
    dispatch(setLoading(true));
    dispatch(getBlipersVerificationHistory({ id })).then((response) => {
      dispatch(setLoading(false));
      if (response.payload === "token_expired") {
        dispatch(generateNewToken()).then((res) => {
          if (res.payload.success) {
            getBlipersVerificationHistoryAPI();
          }
        });
      } else if (response.payload.success) {
        setSelectedData(response.payload.data);
        setHistoryModal(true);
      }
    });
  };

  //   Get Bliper By Id API
  const getBliperByIdAPI = (id) => {
    dispatch(setLoading(true));
    dispatch(getBliperById(id)).then((response) => {
      dispatch(setLoading(false));
      if (response.payload === "token_expired") {
        dispatch(generateNewToken()).then((res) => {
          if (res.payload.success) {
            getBliperByIdAPI(id);
          }
        });
      } else if (response.payload.success) {
        navigate("details");
      }
    });
  };

  //   Update Bliper Verification Status
  const updateBliperVerificationStatusByIdAPI = (values) => {
    const params = {
      id: values._id,
      status: verifyStatus === "Verified" ? "verified" : "declined",
    };
    dispatch(setLoading(true));
    dispatch(updateBliperVerificationStatusById(params)).then((response) => {
      dispatch(setLoading(false));
      if (response.payload === "token_expired") {
        dispatch(generateNewToken()).then((res) => {
          if (res.payload.success) {
            updateBliperVerificationStatusByIdAPI(values);
          }
        });
      } else if (response.payload.success) {
        getBlipersVerificationRequestsAPI();
        setUnVerify(false);
      }
    });
  };

  //   Update Bliper Verification Status Decline With reason
  const declinedBliperVerificationStatusByIdAPI = (values) => {
    dispatch(setLoading(true));
    dispatch(updateBliperVerificationStatusById(values)).then((response) => {
      dispatch(setLoading(false));
      if (response.payload === "token_expired") {
        dispatch(generateNewToken()).then((res) => {
          if (res.payload.success) {
            declinedBliperVerificationStatusByIdAPI(values);
          }
        });
      } else if (response.payload.success) {
        getBlipersVerificationRequestsAPI();
        setDeclineReason(false);
      }
    });
  };

  return (
    <>
      <div id="body_wapper" className="mainBody">
        <div className="wapper">
          <SideBar />
          <div className="main-container">
            <Header headingName={""} />
            <div className="containerBody">
              <div className="mainSelectBox">
                <div className="headingPart cusHeadingLarge">
                  <h2>{t("verificationReqPage.pageTitle")}</h2>
                  <div className="rightMarginPT">
                    <div className="reloadBTun">
                      <button
                        onClick={(e) => {
                          setSearch("");
                          setVerifyFilter("");
                          dispatch(setVeri_StatusKey(""));
                          setCurrentPage(1);
                        }}
                      >
                        <img src={ReloadIcon} alt="reload" />
                      </button>
                    </div>
                    <Dropdown
                      drop
                      className="dropdown-filter dropdown"
                      onSelect={(e) => {
                        setVerifyFilter(e);
                        dispatch(setVeri_StatusKey(e));
                        setCurrentPage(1);
                      }}
                    >
                      <Dropdown.Toggle>
                        <span>{verifyFilter || "Filter By Verify"}</span>
                        <img src={DropDownTBIcon} alt="icon" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        variant=""
                        id="style-5"
                        className="cusDrupdown"
                      >
                        <Dropdown.Item eventKey="" key={"clear filter"}>
                          clear filter
                        </Dropdown.Item>
                        <Dropdown.Item eventKey="verified" key={"verified"}>
                          verified
                        </Dropdown.Item>
                        <Dropdown.Item eventKey="declined" key={"declined"}>
                          declined
                        </Dropdown.Item>
                        <Dropdown.Item eventKey="requested" key={"requested"}>
                          requested
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <span className="searchBgMain mx-0">
                      <input
                        type="text"
                        placeholder={t("verificationReqPage.searchPlaceholder")}
                        value={search}
                        onChange={(e) => {
                          const s = e.currentTarget?.value
                            ?.replace(/[^\w\s]|_/g, "")
                            .replace(/\s{2,}/g, " ");
                          setSearch(s);
                          s?.length > 2 && setCurrentPage(1);
                        }}
                      />
                      <img src={SearchIcon} alt="" />
                    </span>
                  </div>
                </div>
                <div className="tableCusMain">
                  <div className="table-responsive" id="style-5">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>{t("verificationReqPage.srNo")}</th>
                          <th>{t("verificationReqPage.fullName")}</th>
                          <th>{t("verificationReqPage.email")}</th>
                          <th>{t("verificationReqPage.phone")}</th>
                          <th>{t("verificationReqPage.userName")}</th>
                          <th>{t("verificationReqPage.verifyUser")}</th>
                          <th>{t("verificationReqPage.action")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {blipersVerificationRequest?.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                #
                                {parseInt(pageLimit) *
                                  parseInt(currentPage - 1) +
                                  parseInt(index + 1)}
                              </td>

                              <td className="text-capitalize proTableUser">
                                <img
                                  src={
                                    item.profileImage
                                      ? addCloudFrontBaseUrl(item.profileImage)
                                      : UserProfile
                                  }
                                  alt=""
                                  className="profimePicBox"
                                />
                                {item.fullName ? item.fullName : "N/A"}
                              </td>
                              <td>
                                {!item.email ||
                                item.email === null ||
                                item.email === ""
                                  ? "N/A"
                                  : item.email}
                              </td>
                              <td>
                                {item.phoneNumber === null ||
                                item.phoneNumber === ""
                                  ? "N/A"
                                  : item.countryCode + " " + item.phoneNumber}
                              </td>
                              <td>{item.userName}</td>
                              <td>
                                <Dropdown
                                  drop
                                  className="selectCus dropdown"
                                  onSelect={(e) => {
                                    if (
                                      e.toLowerCase() ===
                                      item?.verificationRequestStatus
                                    ) {
                                    } else {
                                      setVerifyStatus(e);

                                      setSelectedData(item);
                                      e === "Verified"
                                        ? setUnVerify(true)
                                        : setDeclineReason(true);
                                    }
                                  }}
                                >
                                  <Dropdown.Toggle>
                                    <span className="text-capitalize">
                                      {item?.verificationRequestStatus}
                                    </span>
                                    <img src={DropDownTBIcon} alt="icon" />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu
                                    variant=""
                                    id="style-5"
                                    className="cusDrupdown"
                                  >
                                    <Dropdown.Item
                                      eventKey="Verified"
                                      key={"Verified"}
                                    >
                                      Verified
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="Declined"
                                      key={"Declined"}
                                    >
                                      Declined
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                              <td className="">
                                <span className="activeAllBtn">
                                  <button>
                                    <img
                                      src={ViewIcon}
                                      alt=""
                                      title="View"
                                      onClick={(e) =>
                                        getBliperByIdAPI(item._id)
                                      }
                                    />
                                  </button>
                                  <button
                                    onClick={(e) =>
                                      getBlipersVerificationHistoryAPI(item._id)
                                    }
                                  >
                                    <img
                                      src={HistoryIcon}
                                      alt=""
                                      title="History"
                                    />
                                  </button>
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <Pagination
                    currentPage={currentPage}
                    totalCount={count}
                    currentPageCount={blipersVerificationRequest?.length}
                    pageSize={pageLimit}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {unVerify && (
        <Modal className="custumCssMCebter" show={unVerify}>
          <ModalHeader>
            <h6>&nbsp;</h6>
            <button
              type="button"
              className=""
              aria-label="Close"
              onClick={() => {
                setUnVerify(false);
              }}
            >
              <img src={CloseModalIcon} alt="Close Button" />
            </button>
          </ModalHeader>
          <ModalBody>
            <div className="centerModalContent">
              <div className="proConBox">
                <img
                  src={
                    verifyStatus === "Verified"
                      ? verifiedActionModalIcon
                      : UnVerify
                  }
                  alt=""
                  className="img-fluid"
                />
              </div>
              <p>
                {t("verificationReqPage.verifyConfirmationPre")}{" "}
                {verifyStatus === "Verified"
                  ? t("buttons.verify")
                  : t("buttons.decline")}{" "}
                {t("verificationReqPage.verifyConfirmationAfter")}
              </p>
              <div className="textConBoxBtn">
                <button
                  onClick={(e) =>
                    updateBliperVerificationStatusByIdAPI(selectedData)
                  }
                  className="gradientBG"
                >
                  {verifyStatus === "Verified"
                    ? t("buttons.verify")
                    : t("buttons.decline")}
                </button>
                <button
                  className="cancelBtun"
                  onClick={() => setUnVerify(false)}
                >
                  {t("buttons.cancel")}
                </button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      )}

      {historyModal && (
        <Modal className="custumCssMCebter historyModal" show={historyModal}>
          <ModalHeader>
            <h6> Verification History</h6>
            <button
              type="button"
              className=""
              aria-label="Close"
              onClick={() => {
                setHistoryModal(false);
              }}
            >
              <img src={CloseModalIcon} alt="Close Button" />
            </button>
          </ModalHeader>
          <ModalBody>
            <div className="centerModalContent" id="style-5">
              {selectedData?.length === 0 && (
                <h5>There Are No Previous History</h5>
              )}
              {selectedData?.map((item) => {
                return (
                  <div className="tableRound table-responsive">
                    <table className="table">
                      <tr>
                        <td>Date</td>
                        <td className="tdLargeContent">
                          {formatDate(item.createdDate)}
                        </td>
                      </tr>
                      <tr>
                        <td>Last Status</td>
                        <td className="tdLargeContent">{item.status}</td>
                      </tr>
                      <tr>
                        <td>Reason</td>
                        <td className="tdLargeContent">{item.comment}</td>
                      </tr>
                    </table>
                  </div>
                );
              })}
            </div>
          </ModalBody>
        </Modal>
      )}
      {declineReason && (
        <Modal className="custumCssMCebter historyModal" show={declineReason}>
          <ModalHeader>
            <h6>Enter Decline Reason</h6>
            <button
              type="button"
              className=""
              aria-label="Close"
              onClick={() => {
                setDeclineReason(false);
              }}
            >
              <img src={CloseModalIcon} alt="Close Button" />
            </button>
          </ModalHeader>
          <ModalBody>
            <div className="centerModalContent" id="style-5">
              <Formik
                initialValues={{
                  id: selectedData?._id,
                  status: "declined",
                  comment: "",
                }}
                validationSchema={Yup.object().shape({
                  comment: Yup.string().required("Reason is required"),
                })}
                onSubmit={async (values) => {
                  declinedBliperVerificationStatusByIdAPI(values);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <textarea
                      placeholder="Enter Reason..."
                      className="form-control"
                      rows="6"
                      name="comment"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.comment}
                    ></textarea>

                    {errors.comment && touched.comment && (
                      <div className="error-msg text-start">
                        {errors.comment}
                      </div>
                    )}
                    <div className="textConBoxBtn">
                      <button type="submit" className="gradientBG">
                        Decline
                      </button>
                      <button
                        className="cancelBtun"
                        onClick={(e) => setDeclineReason(false)}
                      >
                        cancel
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};
